import React, { useContext, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { updateData } from '../services/server';
import { Context } from '../contexts/Context';
import toast, { Toaster } from "react-hot-toast";
import emailjs from 'emailjs-com';

export default function Schedule() {
    const navigate = useNavigate();
    const location = useLocation();
    const context = useContext(Context);
    const user = location.state?.user.user ?? {};
    const agents = location.state?.user.agents ?? {};
    const teamMeeting = window.location.pathname.includes('teamMeeting');

    const [subject, setSubject] = useState('');
    const [link, setLink] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    async function agendar() {
        if (subject === '' || link === '' || date === '' || time === '')
            return toast.error('Preencha todos os campos.');
        const newMeeting = {
            from: context?.allUsers.find((u: any) => u.email === context?.user?.email)?.name || 'ERP User',
            to: user?.name,
            subject,
            link,
            date,
            time,
        }
        // Client or Agent meeting
        if (user && user !== undefined && !teamMeeting) {
            const userMetting = user?.meetings && Array.isArray(user?.meetings) ? [...user?.meetings, newMeeting] : [newMeeting];
            await updateData(toast, 'users', user?.email, { meetings: userMetting }, '', () => { });
            const message = `Olá ${user?.name},<br><br>Uma nova reunião foi agendada através do Work Space da Vision D.<br><br><b>Assunto:</b> ${subject}<br><b>Link:</b> ${link}<br><b>Data:</b> ${formateDate(date)}<br><b>Hora:</b> ${time}<br><br>Atenciosamente,<br>${context?.user?.name}`;
            const emailParams = {
                name: context?.user?.name,
                subject: subject,
                message: message.replace(/\n/g, '<br>'),
                email: user?.email,
            }
            emailjs.send('service_t3qxaik', 'template_5gefxo5', emailParams, 'jeStFGZ-400kFvT_-');
        }
        // Project meeting
        if (teamMeeting) {
            const userMetting = user?.meetings && Array.isArray(user?.meetings) ? [...user?.meetings, newMeeting] : [newMeeting];
            await updateData(toast, 'projects', `${user?.company}-${user?.name}`, { meetings: userMetting }, '', () => { });
            agents.forEach(async (agent: any) => {
                const agentMetting = agent?.meetings ? [...agent?.meetings, newMeeting] : [newMeeting];
                await updateData(toast, 'users', agent.email, { meetings: agentMetting }, '', () => { });
                context?.setAllUsers((prev: any) => prev.map((u: any) => u.email === agent.email ? { ...u, meetings: agentMetting } : u));
                if (agent.email === context?.user?.email)
                    context?.setUser((prev: any) => ({ ...prev, meetings: agentMetting }));
                const message = `Olá ${agent?.name},<br><br>Uma nova reunião foi agendada através do work space.<br><br><b>Assunto:</b> ${subject}<br><b>Link:</b> ${link}<br><b>Data:</b> ${formateDate(date)}<br><b>Hora:</b> ${time}<br><br>Atenciosamente,<br>${context?.user?.name}`;
                const emailParams = {
                    name: context?.user?.name,
                    subject: subject,
                    message: message.replace(/\n/g, '<br>'),
                    email: agent?.email,
                }
                emailjs.send('service_t3qxaik', 'template_5gefxo5', emailParams, 'jeStFGZ-400kFvT_-');
            });
        } else {
            const userMetting = context?.user?.meetings && Array.isArray(context?.user?.meetings) ? [...context?.user?.meetings, newMeeting] : [newMeeting];
            await updateData(toast, 'users', context?.user?.email, { meetings: userMetting }, '', () => { });
            context?.setUser((prev: any) => ({ ...prev, meetings: userMetting }));
        }
        toast.success('Reunião agendada com sucesso!');
        navigate(-1);
    }

    return (
        <div className='flex flex-col w-full min-h-screen p-8'>
            <Toaster />
            <p onClick={() => navigate(-1)} className='relative w-full mb-8 text-left cursor-pointer'>
                <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
            </p>
            <div className='w-full h-full flex flex-col justify-center items-center mt-[5rem]'>
                <h1 className='text-[1.5rem] mb-8'>Agendar reunião</h1>
                <div className='w-[15rem] oitocentos:w-[22rem] flex flex-col gap-4'>
                    <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className='input' placeholder='Assunto da reunião' />
                    <input type="text" value={link} onChange={(e) => setLink(e.target.value)} className='input' placeholder='Link da reunião' />
                    <input type="date" value={date} onChange={(e) => setDate(e.target.value)} className='input' placeholder='Data' />
                    <input type="time" value={time} onChange={(e) => setTime(e.target.value)} className='input' placeholder='Time' />
                    <div className='flex justify-center gap-4 mt-4'>
                        <button onClick={agendar} className='small-button bg-[var(--primary)]'>Confirmar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

