import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../services/firebase';

export default function InactivePage() {
    async function logOut() {
        try {
            await signOut(auth);
            localStorage.clear();
            window.location.href = '/';
        } catch (error) {
            alert("Log out failed.");
        }
    }
    return (
        <div className='flex flex-col items-center justify-center w-full min-h-screen gap-4 text-white bg-darkgray'>
            <h1 className='text-[3.5rem]'>Inactive account</h1>
            <p className='text-[1.2rem]'>Please ask for your account activation</p>
            <button onClick={logOut} className='px-4 py-1 text-[1rem] rounded-[10px] bg-[var(--primary)] !text-white'>Log Out</button>
        </div>
    );
}

