import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../contexts/Context';
import { create, updateData } from '../../services/server';
import toast, { Toaster } from "react-hot-toast";

export default function CreateEntry() {
    const context = useContext(Context);
    const navigate = useNavigate();
    const { id = "" } = useParams();

    const edit = window.location.pathname.includes('edit');
    const payment = window.location.pathname.includes('payment');

    const [entry, setEntry] = useState<any>(null);
    const [clients, setClients] = useState<any>([]);
    const [agents, setAgents] = useState<any>([]);

    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [finalDate, setFinalDate] = useState('');
    const [client, setClient] = useState('');
    const [agent, setAgent] = useState('');
    const [type, setType] = useState('Pontual');
    const [state, setState] = useState(true);
    const [value, setValue] = useState(0);
    const [valueWithTax, setValueWithTax] = useState(0);
    const [tax, setTax] = useState(23);
    const [taxValue, setTaxValue] = useState(0);
    const [totalDone, setTotalDone] = useState(0);
    const [totalLeft, setTotalLeft] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            if (edit) {
                const entry = (payment ? context?.payments : context?.receivements)?.find((entry: any) => entry?.id === id);
                setEntry(entry);
            }
            setClients(context?.clients);
            setAgents(context?.agents);
        };
        fetchData();
    }, [edit, context?.clients, context?.techs, context?.payments, id, context?.receivements, payment, context?.agents]);

    useEffect(() => {
        if (entry) {
            setTitle(entry?.title || "");
            setClient(entry?.client || "");
            setAgent(entry?.agent || "");
            setValue(entry?.value || "");
            setValueWithTax(entry?.valueWithTax || "");
            setTax(entry?.tax || "");
            setDate(entry?.date || "");
            setType(entry?.type || "");
            setState(entry?.state || "");
            setTaxValue(entry?.taxValue || "");
            setTotalLeft(entry?.totalLeft || "");
            setTotalDone(entry?.totalDone || "");
            setStartDate(entry?.startDate || "");
            setFinalDate(entry?.finalDate || "");
        }
    }, [entry]);

    useEffect(() => {
        setValueWithTax(value + (value * tax / 100));
        setTaxValue(value * tax / 100);
    }, [value, tax]);

    useEffect(() => {
        if (!state && type === 'Pontual') {
            if (totalDone) {
                setTotalLeft(value - totalDone);
            } else {
                setTotalLeft(value);
            }
        }
    }, [state, type, value, totalDone]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!title || !date || !type || (type === 'Mensal' && !startDate))
            return toast.error('Preencha todos os campos.');
        if (value <= 0 || tax < 0 || valueWithTax < 0 || taxValue < 0 || (!state && (totalDone < 0 || totalLeft < 0)) || isNaN(value) || isNaN(tax) || isNaN(valueWithTax) || isNaN(taxValue) || isNaN(totalDone) || isNaN(totalLeft))
            return toast.error('Os valores não podem ser menores que 0 nem vazios');
        if (type === "Pontual" && (totalDone > value || totalLeft > value) && !state)
            return toast.error(`Os valores "Total ${payment ? 'pago' : 'recebido'}" e "Total por ${payment ? 'pagar' : 'receber'}" não podem ser maiores que o valor total.`);
        const now = new Date().toISOString();
        const data: any = {
            title,
            date,
            client,
            agent,
            type,
            value,
            valueWithTax: valueWithTax || 0,
            tax: tax || 0,
            taxValue: taxValue || 0,
            createdBy: edit ? entry?.createdBy : context?.user?.email,
            creationDate: edit ? entry?.creationDate : now
        };
        if (type === 'Mensal') {
            data["startDate"] = startDate;
            data["finalDate"] = finalDate;
            const monthsSinceStart = generateMonthsSinceStart(startDate, now, finalDate);
            data["monthly"] = monthsSinceStart.map((month: any) => {
                if (edit && entry?.monthly?.find((ele: any) => ele.date === month)) {
                    return entry?.monthly?.find((ele: any) => ele.date === month);
                }
                return {
                    title: title,
                    value: value,
                    valueWithTax: valueWithTax,
                    done: false,
                    date: month,
                }
            });
        } else {
            data["state"] = state;
            data.totalLeft = state ? 0 : totalLeft;
            data.totalDone = state ? valueWithTax : totalDone;
        }
        if (edit) {
            await updateData(toast, payment ? 'payments' : 'receivements', entry?.id, data, `${payment ? 'Pagamento' : 'Recebimento'} atualizado com sucesso.`, () => {
                if (payment) {
                    const newEntrys = [...context?.payments?.filter((ele: any) => ele.id !== entry?.id), { ...data, id: entry?.id }]
                    context?.setPayments(newEntrys);
                } else {
                    const newEntrys = [...context?.receivements?.filter((ele: any) => ele.id !== entry?.id), { ...data, id: entry?.id }]
                    context?.setReceivements(newEntrys);
                }
            });
        }
        else {
            await create(toast, payment ? 'payments' : 'receivements', now, data, `Novo ${payment ? 'pagamento' : 'recebimento'} criado com sucesso.`, () => {
                if (payment) {
                    const newEntrys = [...context?.payments, { ...data, id: now }]
                    context?.setPayments(newEntrys);
                } else {
                    const newEntrys = [...context?.receivements, { ...data, id: now }]
                    context?.setReceivements(newEntrys);
                }
            });
        }
        navigate(-1);
    };

    function generateMonthsSinceStart(startDateStr: string, nowStr: string, finalDateStr: string) {
        const startDate = new Date(startDateStr);
        const now = new Date(nowStr);
        const finalDate = new Date(finalDateStr);
        let monthsSinceStart = [];
        if (finalDateStr) {
            while (startDate <= now && startDate <= finalDate) {
                monthsSinceStart.push(startDate.toISOString().split('T')[0]);
                startDate.setMonth(startDate.getMonth() + 1);
            }
        } else {
            while (startDate <= now) {
                monthsSinceStart.push(startDate.toISOString().split('T')[0]);
                startDate.setMonth(startDate.getMonth() + 1);
            }
        }

        return monthsSinceStart;
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <p onClick={() => navigate(-1)} className='relative w-full mb-8 text-left cursor-pointer'>
                <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
            </p>
            <div className='flex items-center justify-between w-full mb-8'>
                {
                    edit ?
                        <h1 className='text-[1.5rem] font-bold'>Editar</h1>
                        :
                        <h1 className='text-[1.5rem] font-bold'>{payment ? 'Novo Pagamento' : 'Novo Recebimento'}</h1>
                }
            </div>
            <div className='text-[1.2rem]'>
                <div className='grid grid-cols-1 mil:grid-cols-2 text-[1rem] gap-4'>
                    <div>
                        <p className='mandatory'>Título</p>
                        <input
                            type='text'
                            placeholder='TÍtulo'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                        />
                    </div>
                    <div>
                        <p className='mandatory'>Data de movimento</p>
                        <input
                            type='date'
                            placeholder='Data'
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                        />
                    </div>
                    <div>
                        <p className='mandatory'>Valor</p>
                        <input
                            type='number'
                            placeholder='0'
                            value={value}
                            onChange={(e) => setValue(parseInt(e.target.value))}
                            className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                        />
                    </div>
                    <div>
                        <p className='mandatory'>Valor com IVA</p>
                        <input
                            type='number'
                            placeholder='0'
                            value={valueWithTax}
                            disabled
                            className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                        />
                    </div>
                    <div>
                        <p className='mandatory'>IVA (%)</p>
                        <input
                            type='number'
                            placeholder='23%'
                            value={tax}
                            onChange={(e) => setTax(parseInt(e.target.value))}
                            className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                        />
                    </div>
                    <div>
                        <p className='mandatory'>Valor do IVA</p>
                        <input
                            type='number'
                            placeholder='0'
                            value={taxValue}
                            disabled
                            className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                        />
                    </div>
                    {
                        payment ?
                        <div>
                            <p className=''>Agente Associado</p>
                            <select value={agent} onChange={(e) => setAgent(e.target.value)} className='input'>
                                <option value="">Nenhum</option>
                                {
                                    agents && agents.map((agent: any, index: number) => (
                                        <option key={index} value={agent?.email}>{agent?.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        :
                        <div>
                            <p className=''>Cliente Associado</p>
                            <select value={client} onChange={(e) => setClient(e.target.value)} className='input'>
                                <option value="">Nenhum</option>
                                {
                                    clients && clients.map((client: any, index: number) => (
                                        <option key={index} value={client?.email}>{client?.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    }
                    <div className="flex flex-col gap-4">
                        <p className='mandatory'>Tipo de {payment ? 'pagamento' : 'recebimento'}</p>
                        <div className='flex gap-4'>
                            <p>Mensal</p>
                            <button onClick={() => setType('Mensal')} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${type === 'Mensal' ? 'bg-[var(--primary)]' : ''} cursor-pointer`}>
                            </button>
                            <p>Pontual</p>
                            <button onClick={() => setType('Pontual')} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${type === 'Pontual' ? 'bg-[var(--primary)]' : ''} cursor-pointer`}>
                            </button>
                        </div>
                    </div>
                    {
                        type === 'Pontual' ?
                            <>
                                {
                                    !state &&
                                    <>
                                        <div>
                                            <p className='mandatory'>Total {payment ? 'pago' : 'recebido'}</p>
                                            <input
                                                type='number'
                                                placeholder='0'
                                                value={totalDone}
                                                onChange={(e) => setTotalDone(parseInt(e.target.value))}
                                                className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                                            />
                                        </div>
                                        <div>
                                            <p className='mandatory'>Total {payment ? 'por pagar' : 'por receber'}</p>
                                            <input
                                                type='number'
                                                placeholder='0'
                                                disabled
                                                value={totalLeft}
                                                className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                                            />
                                        </div>
                                    </>
                                }
                                <div className="flex flex-col gap-4">
                                    <p className='mandatory'>Estado</p>
                                    <div className='flex gap-4'>
                                        <p>{payment ? 'Pago' : 'Recebido'}</p>
                                        <button onClick={() => setState(true)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${state ? 'bg-[var(--green)]' : ''} cursor-pointer`}>
                                        </button>
                                        <p>{payment ? 'Por pagar' : 'Por receber'}</p>
                                        <button onClick={() => setState(false)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${!state ? 'bg-[var(--red)]' : ''} cursor-pointer`}>
                                        </button>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div>
                                    <p className='mandatory'>Data inicial</p>
                                    <input
                                        type='date'
                                        placeholder='Data'
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                                    />
                                </div>
                                <div>
                                    <p className=''>Data final</p>
                                    <input
                                        type='date'
                                        placeholder='Data'
                                        value={finalDate}
                                        onChange={(e) => setFinalDate(e.target.value)}
                                        className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                                    />
                                </div>
                            </>
                    }
                </div>
                <div className='items-center justify-center w-full mt-8'>
                    <button onClick={handleSubmit} className={`small-button ${edit ? 'bg-[var(--primary)]' : 'bg-green'}`}>{edit ? 'Atualizar' : 'Criar'}</button>
                </div>
            </div>
        </div>
    );
}

