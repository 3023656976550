import React, { useEffect, useState } from 'react';
import Loading from '../Loading';

export default function ExcelView({ header, tableData, setTableData, data }: { header: string[], tableData: any, setTableData: any, data: string }) {

    // Variaveis
    const [filteredData, setFilteredData] = useState<any>(null);
    const [filtros, setFiltros] = useState<any>({});

    useEffect(() => {
        if (tableData) {
            setFilteredData(tableData);
        }
    }, [tableData]);

    useEffect(() => {
        const newData = tableData?.filter((row: any) => {
            return Object.keys(filtros).every((key) => {
                const filterValue = filtros[key];
                if (!filterValue) return true;
                const rowValue = row[key] ? row[key].toString().toLowerCase() : "";
                if (key === "active") { 
                    if (filterValue === "Sim") return rowValue === "true";
                    if (filterValue === "Não") return rowValue === "false";
                }
                return rowValue.includes(filterValue.toLowerCase());
            });
        });
        setFilteredData(newData);
    }, [filtros, tableData]);

    if (!tableData) {
        return (
            <Loading />
        )
    }

    return (
        <div className="relative w-full overflow-x-auto mb-[2rem] min-h-[24rem] pb-8  my-scrollbar">
            <table className="w-full text-left text-[.8rem] text-black">
                {/* Header da tabela */}
                <thead>
                    <tr className="bg-white ">
                        {header &&
                            header.map((value: any, index: number) => (
                                <th key={index} className="sticky top-0">
                                    <div className="border-[1px] border-[var(--black)] p-2 min-w-[10rem] bg-[white]">
                                        <p className="text-black capitalize whitespace-nowrap">{value === "active" ? 'Contactado | Ativo' : value}</p>
                                        {
                                            value !== "active" ?
                                            <input
                                                type="text"
                                                placeholder={`Filtrar ${value}`}
                                                value={filtros[value] || ""}
                                                onChange={(e) => {
                                                    setFiltros({ ...filtros, [value]: e.target.value });
                                                }}
                                                className='filter'
                                            />
                                            :
                                            <select
                                                value={filtros[value] || ""}
                                                onChange={(e) => {
                                                    setFiltros({ ...filtros, [value]: e.target.value });
                                                }}
                                                className='filter'
                                            >
                                                <option value={""}>Selecionar</option>
                                                <option value={"Sim"}>Sim</option>
                                                <option value={"Não"}>Não</option>
                                            </select>
                                        }
                                    </div>
                                </th>
                            ))}
                    </tr>
                </thead>
                {/* Body/informação da tabela */}
                <tbody>
                    {filteredData && filteredData.length > 0 ? (
                        filteredData.map((row: any, rowIndex: number) => (
                            <tr
                                key={rowIndex}
                                className={`hover:bg-[#d8d8d8] cursor-pointer ${rowIndex % 2 === 0 ? "bg-[#eeeeee]" : 'bg-white'}`}
                            >
                                {header.map((column: string, colIndex: number) => {
                                    const cellValue = row[column] || "";
                                    return (
                                        <td
                                            key={colIndex}
                                            className="p-1 border-[1px] border-[var(--black)] whitespace-nowrap max-w-[20rem] overflow-hidden"
                                        >
                                            {
                                                column !== "active" ?
                                                    <input
                                                        type={column === 'deadline' || column === 'date' ? 'date' : 'text'}
                                                        placeholder=''
                                                        value={cellValue === true ? 'Sim' : cellValue === false ? 'Não' : cellValue}
                                                        disabled={(column === "email" && tableData[rowIndex] && !tableData[rowIndex][colIndex]) ||
                                                            data === "projects" ||
                                                            column === "embaixador" ||
                                                            column === "createdBy"
                                                        }
                                                        onChange={(e) => {
                                                            let updatedFilteredData = [...filteredData];
                                                            let updatedRowData = { ...updatedFilteredData[rowIndex] };
                                                            if (!updatedRowData[column]) {
                                                                updatedRowData[column] = '';
                                                            }
                                                            updatedRowData[column] = e.target.value;
                                                            updatedFilteredData[rowIndex] = updatedRowData;
                                                            setFilteredData(updatedFilteredData);
                                                            let updatedTableData = [...tableData];
                                                            updatedTableData[rowIndex] = { ...updatedTableData[rowIndex], ...updatedRowData };
                                                            setTableData(updatedTableData);
                                                        }}
                                                        className='w-full h-full bg-[transparent] outline-none'
                                                    />
                                                    :
                                                    <select
                                                        className='w-full h-full bg-[transparent] outline-none'
                                                        value={cellValue ? "Sim" : "Não"}
                                                        onChange={(e) => {
                                                            let updatedFilteredData = [...filteredData];
                                                            let updatedRowData = { ...updatedFilteredData[rowIndex] };
                                                            if (!updatedRowData[column]) {
                                                                updatedRowData[column] = '';
                                                            }
                                                            updatedRowData[column] = e.target.value === "Sim";
                                                            updatedFilteredData[rowIndex] = updatedRowData;
                                                            setFilteredData(updatedFilteredData);
                                                            let updatedTableData = [...tableData];
                                                            updatedTableData[rowIndex] = { ...updatedTableData[rowIndex], ...updatedRowData };
                                                            setTableData(updatedTableData);
                                                        }}
                                                    >
                                                        <option value={"Não"}>Não</option>
                                                        <option value={"Sim"}>Sim</option>
                                                    </select>
                                            }
                                        </td>
                                    );
                                })}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td
                                colSpan={header.length}
                                className="h-20"
                            >
                                Nenhum resultado
                            </td>
                        </tr>
                    )}
                    {
                        data !== "projects" &&
                        <tr>
                            <td
                                colSpan={header.length}
                                className="flex gap-2"
                            >
                                <button
                                    onClick={() => {
                                        const updatedTableData = [...tableData];
                                        updatedTableData.push({});
                                        setTableData(updatedTableData);
                                        const updatedFilteredData = [...filteredData];
                                        updatedFilteredData.push({});
                                        setFilteredData(updatedFilteredData);
                                    }}
                                    className="bg-[var(--primary)] text-white p-2 rounded-md"
                                >
                                    + uma linha
                                </button>
                                {
                                    filteredData && filteredData.length && Object.values(filteredData[filteredData.length - 1]).every((value: unknown) => value === "") ?
                                        <button
                                            onClick={() => {
                                                const updatedTableData = [...tableData];
                                                updatedTableData.pop();
                                                setTableData(updatedTableData);
                                                const updatedFilteredData = [...filteredData];
                                                updatedFilteredData.pop();
                                                setFilteredData(updatedFilteredData);
                                            }}
                                            className="bg-[var(--red)] text-white p-2 rounded-md"
                                        >
                                            - uma linha
                                        </button>
                                        :
                                        null
                                }
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}

