import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Dataset {
  title: string;
  data: { [key: string]: number };
}

interface LineChartProps {
  datasets: Dataset[];
}

const LineChart: React.FC<LineChartProps> = ({ datasets }) => {
  const parseDate = (dateStr: string) => {
    const [month, year] = dateStr.split("/").map(Number);
    return new Date(year, month - 1);
  };

  const labelsSet = new Set<string>();
  datasets.forEach(dataset => {
    Object.keys(dataset.data).forEach(date => labelsSet.add(date));
  });

  const labels = Array.from(labelsSet).sort(
    (a, b) => parseDate(a).getTime() - parseDate(b).getTime()
  );

  const getColor = (index: number) => {
    const hue = (index * 137.508) % 360; 
    return `hsl(${hue}, 70%, 50%)`;
  };

  const chartDatasets = datasets.map((dataset, index) => {
    return {
      label: dataset.title,
      data: labels.map(label => dataset.data[label] || 0),
      borderColor: getColor(index),
      backgroundColor: getColor(index),
      fill: false,
      tension: 0.1,
    };
  });

  const chartData = {
    labels,
    datasets: chartDatasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Tasks Per Month",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
      y: {
        title: {
          display: true,
          text: "Points",
        },
        beginAtZero: true,
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;
