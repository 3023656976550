import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Context } from '../../contexts/Context';
import Loading from '../Loading';
import { LuFilter, LuFilterX } from 'react-icons/lu';
import ExcelView from './ExcelView';
import { exportExcel } from '../../components/export';
import DownloadIcon from '../../content/images/icons/download.png';
import { BsDatabaseFillAdd } from "react-icons/bs";

import { create, updateData } from '../../services/server';
import toast, { Toaster } from "react-hot-toast";
import { importExcel } from '../../components/import';

export default function Payments() {
    const navigate = useNavigate();
    const context = useContext(Context);

    const [view, setView] = useState('list');
    const [tableData, setTableData] = useState<any>(null);
    const [payments, setPayments] = useState<any>(null);

    const [filterOn, setFilterOn] = useState(false);

    // Filters
    const [search, setSearch] = useState('');
    const [paid, setPaid] = useState('');
    const [iva, setIva] = useState('');

    useEffect(() => {
        if (context?.payments) {
            let result = context?.payments;
            // Filter by search
            if (search) {
                result = result.filter((ele: any) =>
                    (ele?.title).toLowerCase().includes(search.toLowerCase()));
            }
            // Filter by other filters
            if (filterOn) {
                result = result.filter((ele: any) =>
                    (paid ? ele?.state?.toString() === paid : true) &&
                    (iva ? ((ele?.tax?.toString() === "0" && iva === "0") || (ele?.tax.toString() !== "0" && iva === "1")) : true)
                );
            }
            setPayments(result);
            setTableData(result);
        }
    }, [search, iva, paid, filterOn, context?.payments, context?.settings]);

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    const header = [
        "title",
        "type",
        "value",
        "tax",
        "valueWithTax",
        "taxValue",
        "totalLeft",
        "totalDone",
        "date",
        "createdBy",
    ];

    async function handleImport(e: any) {
        const file = e.target?.files[0];
        const newData: any = await importExcel(toast, file);
        if (newData !== '' && newData !== undefined && newData !== null) {
            const allEntriesValid = newData.every((item: any) => {
                return 'title' in item && 'type' in item && 'date' in item;
            });
            if (allEntriesValid) {
                if (tableData)
                    setTableData([...tableData, ...newData]);
                else
                    setTableData(newData);
                toast.success('Dados importados com sucesso.');
            } else {
                toast.error('Todos os dados devem ter os campos "title", "type" e "date" preenchidos.');
            }
        } else {
            console.error('Erro ao importar os dados.');
            toast.error('Erro ao importar os dados.');
        }
    }

    function handleExport() {
        const exportData = payments?.map((entry: any) => {
            const data: any = {
                Título: entry?.title,
                Data: formateDate(entry?.date),
                Cliente: entry?.client,
                Tipo: entry?.type,
                Valor: entry?.value,
                "Valor Com IVA": entry?.valueWithTax,
                IVA: entry?.tax,
                "Valor Do Imposto": entry?.taxValue,
                "Valor a pagar": entry?.totalLeft,
                "Valor pago": entry?.totalDone,
                "Criado Por": entry?.createdBy,
            };
            if (entry.type === 'Mensal') {
                data["Data de começo"] = entry?.startDate;
            } else {
                data["Pago"] = entry?.state;
            }
            return data;
        });
        exportExcel(exportData, 'pagamentos');
    }

    function saveData() {
        let error = '';
        let newData: [];
        try {
            newData = tableData.map((entry: any) => {
                if (error) return entry;
                if (!entry?.title || !entry?.type || !entry?.date) {
                    error = 'Existem campos obrigatórios que não estão preenchidos.';
                    return entry;
                }
                if (parseInt(entry?.value) <= 0 || parseInt(entry?.tax) < 0)
                    error = 'O value e taxValue não podem ser menores que 0, conter texto, nem ser vazios.';
                else if (entry?.totalDone > entry?.valueWithTax || entry?.totalLeft > entry?.valueWithTax) {
                    error = `Os valores "TotalDone" não podem ser maiores que o valor total.`;
                }
                if (parseInt(entry?.totalDone) < 0 || isNaN(parseInt(entry?.totalDone))) {
                    entry.totalDone = 0;
                }
                entry.totalLeft = parseInt(entry?.value) - parseInt(entry?.totalDone);
                entry.taxValue = parseInt(entry?.value) * (parseInt(entry?.tax) / 100);
                entry.valueWithTax = parseInt(entry?.value) + (parseInt(entry?.value) * parseInt(entry?.tax) / 100)
                entry.state = parseInt(entry?.value) === parseInt(entry?.totalDone);
                return entry;
            });
            if (error) {
                toast.error(error);
                return;
            }
            newData.forEach((entry: any) => {
                const now = new Date().toISOString();
                new Promise(resolve => setTimeout(resolve, 100));
                if (context?.payments.find((ele: any) => ele?.id === entry?.creationDate)) {
                    updateData(toast, 'payments', entry?.creationDate, entry, '', () => {
                        const newEntrys = [...context?.payments?.filter((ele: any) => ele.id !== entry?.id), { ...entry, id: entry?.creationDate }]
                        context?.setPayments(newEntrys);
                    });
                    return entry;
                }
                else {
                    create(toast, 'payments', now, { ...entry, createdBy: context?.user?.email, creationDate: now }, '', () => {
                        const newEntrys = [...context?.payments, { ...entry, id: now, createdBy: context?.user?.email, creationDate: now }]
                        context?.setPayments(newEntrys);
                    });
                    return { ...entry, id: now }
                }
            });
            toast.success('Dados guardados com sucesso');
            context?.setPayments(tableData);
        } catch (error) {
            toast.error('Erro ao guardar os dados.');
        }
    }

    function getTotal(data: any) {
        return data?.reduce((acc: any, entry: any) => acc + entry?.value, 0);
    }

    function getTotalIva(data: any) {
        return data?.reduce((acc: any, entry: any) => acc + (entry?.valueWithTax || entry?.value), 0);
    }

    const isDateAfterToday = (dateString: string): boolean => {
        if (!dateString) return true;
        const today = new Date();
        const dateToCompare = new Date(dateString);

        today.setHours(0, 0, 0, 0);

        return dateToCompare > today;
    };


    if (!payments) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full min-h-screen p-8 '>
            <Toaster />
            <div className=''>
                <button onClick={() => navigate("/finances/payment/create")} className='small-button bg-[var(--primary)]'>+ Novo Pagamento</button>
            </div>
            <div className='flex flex-col gap-4 mt-8'>
                <div className='flex items-center gap-4'>
                    <input
                        type="text"
                        placeholder='Pesquisa'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className='input'
                    />
                    <button className='p-2 border-[1px] border-[var(--black)] rounded-full cursor-pointer' onClick={() => setFilterOn(!filterOn)}>
                        {filterOn ? <LuFilterX /> : <LuFilter />}
                    </button>
                </div>
                {
                    <div className={`flex items-center gap-8 overflow-y-hidden overflow-x-auto transition-all duration-500 ease ${filterOn ? 'max-h-[5rem]' : 'max-h-0'}`}>
                        <div className="flex flex-col gap-2">
                            <p>Pago</p>
                            <select value={paid} onChange={(e) => setPaid(e.target.value)} className='filter'>
                                <option value="">Selecionar</option>
                                <option value="false">Não</option>
                                <option value="true">Sim</option>
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Com IVA</p>
                            <select value={iva} onChange={(e) => setIva(e.target.value)} className='filter'>
                                <option value="">Selecionar</option>
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </select>
                        </div>
                    </div>
                }
            </div>
            <div className='flex flex-col items-center justify-between gap-4 my-6 seis:flex-row'>
                <div className='relative flex items-center w-[16rem] h-8 border-2 border-[var(--black)] rounded-full justify-evenly'>
                    <div className={`absolute top-0 ${view === 'list' ? 'left-0' : 'left-1/2'} h-full w-1/2 bg-[var(--stats)] rounded-full z-[-1] transition-all duration-300`}></div>
                    <p onClick={() => setView('list')} className='w-1/2 text-[.8rem] text-center cursor-pointer'>Lista</p>
                    <p onClick={() => setView('excel')} className='w-1/2 text-[.8rem] text-center cursor-pointer'>Excel</p>
                </div>
                <div className='flex flex-col gap-4 quatro:flex-row'>
                    <button className='relative small-button bg-[var(--primary)] gap-4'>
                        Importar
                        <BsDatabaseFillAdd />
                        <input
                            type="file"
                            onChange={handleImport}
                            className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
                        />
                    </button>
                    <button onClick={handleExport} className='small-button bg-[var(--primary)] gap-4'>
                        Download
                        <img src={DownloadIcon} alt="icon" className='w-6' />
                    </button>
                </div>
            </div>
            {
                view === 'list' ?
                    <>
                        <div className='flex flex-col gap-10 mt-10 mil:flex-row'>
                            <div className='w-full'>
                                <h2 className=' text-[1.5rem]'>Pagamentos Pontuais</h2>
                                <p>{payments ? `Total: ${getTotal(payments?.filter((entry: any) => entry?.type === "Pontual"))}€` : ""}</p>
                                <p className='mb-8'>{payments ? `Total c/ IVA: ${getTotalIva(payments?.filter((entry: any) => entry?.type === "Pontual"))}€` : ""}</p>
                                <div className='flex flex-col gap-4 max-h-[25rem] overflow-y-auto my-scrollbar'>
                                    {
                                        (!payments || payments?.filter((entry: any) => entry?.type === "Pontual").length === 0) ? <p>Ainda não existem pagamentos ativos</p> :
                                            payments?.filter((entry: any) => entry?.type === "Pontual").map((entry: any) => (
                                                <div
                                                    key={entry.id}
                                                    className='small-button style1 bg-purple'
                                                    onClick={() => context?.user?.type === 'Admin' ? navigate(`${entry?.id}`) : {}}
                                                >
                                                    <p className='text-[1.2rem]'>{entry?.title}</p>
                                                    <p className='text-[1rem]'>{entry?.value}€ + {entry?.tax}%</p>
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                            <div className='w-full'>
                                <h2 className='  text-[1.5rem]'>Pagamentos Mensais</h2>
                                <p>{payments ? `Total: ${getTotal(payments?.filter((entry: any) => entry?.type === "Mensal" && isDateAfterToday(entry?.finalDate)))}€` : ""}</p>
                                <p className='mb-8'>{payments ? `Total c/ IVA: ${getTotalIva(payments?.filter((entry: any) => entry?.type === "Mensal" && isDateAfterToday(entry?.finalDate)))}€` : ""}</p>
                                <div className='flex flex-col gap-4 max-h-[25rem] h-full overflow-y-auto my-scrollbar'>
                                    {
                                        (!payments || payments?.filter((entry: any) => entry?.type === "Mensal" && isDateAfterToday(entry?.finalDate)).length === 0) ? <p>Não existem pagamentos inativos</p> :
                                            payments?.filter((entry: any) => entry?.type === "Mensal" && isDateAfterToday(entry?.finalDate)).map((entry: any) => (
                                                <div
                                                    key={entry.id}
                                                    className='small-button style1 bg-gray'
                                                    onClick={() => context?.user?.type === 'Admin' ? navigate(`${entry?.id}`) : {}}
                                                >
                                                    <p className='text-[1.2rem]'>{entry?.title}</p>
                                                    <p className='text-[1rem]'>{entry?.value}€ + {entry?.tax}%</p>
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-10 mt-10 mil:flex-row'>
                            <div className='w-full'>
                                <h2 className='  text-[1.5rem]'>Pagamentos Mensais Antigos</h2>
                                <p>{payments ? `Total: ${getTotal(payments?.filter((entry: any) => entry?.type === "Mensal" && !isDateAfterToday(entry?.finalDate)))}€` : ""}</p>
                                <p className='mb-8'>{payments ? `Total c/ IVA: ${getTotalIva(payments?.filter((entry: any) => entry?.type === "Mensal" && !isDateAfterToday(entry?.finalDate)))}€` : ""}</p>
                                <div className='flex flex-col gap-4 max-h-[25rem] h-full overflow-y-auto my-scrollbar'>
                                    {
                                        (!payments || payments?.filter((entry: any) => entry?.type === "Mensal" && !isDateAfterToday(entry?.finalDate)).length === 0) ? <p>Não existem pagamentos inativos</p> :
                                            payments?.filter((entry: any) => entry?.type === "Mensal" && !isDateAfterToday(entry?.finalDate)).map((entry: any) => (
                                                <div
                                                    key={entry.id}
                                                    className='small-button style1 bg-gray'
                                                    onClick={() => context?.user?.type === 'Admin' ? navigate(`${entry?.id}`) : {}}
                                                >
                                                    <p className='text-[1.2rem]'>{entry?.title}</p>
                                                    <p className='text-[1rem]'>{entry?.value}€ + {entry?.tax}%</p>
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                            <div className='w-full'>

                            </div>
                        </div>
                    </>
                    :
                    <ExcelView
                        header={header}
                        tableData={tableData}
                        setTableData={setTableData}
                        data="payments"
                    />
            }
            {view === 'excel' && <button className="small-button bg-[var(--green)]" onClick={saveData}><p className='w-full text-center'>Guardar</p></button>}
        </div>
    );
}

