import * as XLSX from 'xlsx';

export function importExcel(toast, file) {
    return new Promise((resolve, reject) => {
        if (file &&
            (file.name.endsWith(".csv") ||
                file.name.endsWith(".xls") ||
                file.name.endsWith(".xlsx") ||
                file.name.endsWith(".xlsm"))) {

            try {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const arrayBuffer = e.target?.result;
                    const data = new Uint8Array(arrayBuffer);
                    const workbook = XLSX.read(data, { type: "array" });
                    const worksheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[worksheetName];

                    // Convert worksheet to JSON and trim spaces from keys
                    let jsonData = XLSX.utils.sheet_to_json(worksheet);
                    jsonData = jsonData.map((row) => {
                        const trimmedRow = {};
                        Object.keys(row).forEach((key) => {
                            trimmedRow[key.trim()] = row[key];
                        });
                        return trimmedRow;
                    });
                    resolve(jsonData);
                };

                reader.onerror = (error) => {
                    console.error("Error reading the file:", error);
                    toast.error("Error reading the file!");
                    reject(error);
                };

                reader.readAsArrayBuffer(file);
            } catch (error) {
                console.error("File upload failed:", error);
                toast.error("File upload failed!");
                reject(error);
            }
        } else {
            toast.error("Por favor selecione um ficheiro excel.");
        }
    });
}