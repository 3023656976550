import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../contexts/Context';

export default function RHProfile({ user, setUser }: { user: any, setUser: any }) {
    const navigate = useNavigate();
    const context = useContext(Context);
    const [partners, setPartners] = useState<any>([]);
    const [leads, setLeads] = useState<any>([]);
    const [nextMeeting, setNextMeeting] = useState<any>(null);

    useEffect(() => {
        if (context?.partners && context?.leads && user?.email) {
            const allPartners = context?.partners;
            if (!allPartners) return;
            const myPartners = allPartners.filter((ele: any) => ele?.embaixador === user?.email);
            setPartners(myPartners);
            const allLeads = context?.leads;
            if (!allLeads) return;
            const myLeads = allLeads.filter((ele: any) => ele?.embaixador === user?.email);
            setLeads(myLeads);
            const today = new Date();
            const meetings = user?.meetings.filter((m: any) => new Date(m.date) >= today);
            setNextMeeting(getMeeting(meetings)[0]);
        }
    }, [context?.partners, context?.leads, user?.email, user?.meetings]);

    function getMeeting(list: any) {
        return list.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    return (
        <div className='flex flex-col w-full gap-8 pb-8 mt-8'>
            <div className="flex flex-col justify-between gap-4 cinco:items-center cinco:flex-row">
                <p className='font-bold'>Detalhes do Agente</p>
            </div>
            <div className='grid w-full grid-cols-1 gap-8 oitocentos:grid-cols-2'>
                <div className='flex flex-col w-full gap-2'>
                    <p>Posição</p>
                    <p className='input'>{user?.role || 'Não definido'}</p>
                </div>
                <div className='flex flex-col w-full gap-2'>
                    <p>Próxima reunião</p>
                    <a href={nextMeeting?.link} className='input'>{formateDate(nextMeeting?.date) || 'Nenhuma reunião agendada'}</a>
                </div>
            </div>
            <p className='font-bold text-[1.2rem]'>Parceiros</p>
            <div className='flex flex-col w-full gap-8 oitocentos:flex-row'>
                <div className='flex flex-col w-full gap-8'>
                    <h2 className='text-[1.2rem]'>Ativos</h2>
                    <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                        {
                            (!partners || partners.length === 0) ? <p>Nenhuma parceria ativa</p> :
                                partners.map((partners: any, index: number) => (
                                    <div key={index} className='big-button style1 bg-[var(--primary)]' onClick={() => navigate(`/partners/${partners?.id}`)}>
                                        <p>{partners?.name}</p>
                                    </div>
                                ))
                        }
                    </div>
                </div>
                <div className='flex flex-col w-full gap-8'>
                    <h2 className='text-[1.2rem]'>Leads</h2>
                    <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                        {
                            (!leads || leads.length === 0) ? <p>Nenhuma lead</p> :
                                leads.map((partner: any, index: number) => (
                                    <div key={index} className='big-button style1 bg-[var(--primary)]' onClick={() => navigate(`/partner/${partner?.id}/lead`)}>
                                        <p>{partner?.name}</p>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
            <p className='font-bold text-[1.2rem]'>Agentes</p>
            <div className='flex flex-col w-full gap-8 oitocentos:flex-row'>
                <div className='flex flex-col w-full gap-8'>
                    <h2 className='text-[1.2rem]'>Ativos</h2>
                    <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                        {
                            (!partners || partners.length === 0) ? <p>Nenhum agente ativo</p> :
                                partners.map((agent: any, index: number) => (
                                    <div key={index} className='big-button style1 bg-[var(--primary)]' onClick={() => navigate(`/agent/${agent?.id}`)}>
                                        <p className='text-[1.2rem]'>{agent?.name}</p>
                                    </div>
                                ))
                        }
                    </div>
                </div>
                <div className='flex flex-col w-full gap-8'>
                    <h2 className='text-[1.2rem]'>Leads</h2>
                    <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                        {
                            (!leads || leads.length === 0) ? <p>Nenhuma lead</p> :
                                leads.map((agent: any, index: number) => (
                                    <div key={index} className='big-button style1 bg-[var(--primary)]' onClick={() => navigate(`/agent/${agent?.id}/lead`)}>
                                        <p className='text-[1.2rem]'>{agent?.name}</p>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

