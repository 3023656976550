import React, { createContext, useState, useEffect } from 'react';
import { db, auth } from '../services/firebase';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  // Users
  const [allUsers, setAllUsers] = useState(null);
  const [admins, setAdmins] = useState({});
  const [inactive, setInactive] = useState({});
  const [clients, setClients] = useState([]);
  const [leads, setLeads] = useState([]);
  const [agents, setAgents] = useState([]);
  const [partners, setPartners] = useState([]);
  const [sales, setSales] = useState([]);
  const [rh, setRH] = useState([]);
  // Projects
  const [projects, setProjects] = useState([]);
  const [internProjects, setInternProjects] = useState([]);
  // Finances
  const [payments, setPayments] = useState([]);
  const [receivements, setReceivements] = useState([]);
  // Settings
  const [settings, setSettings] = useState({});
  // Templates
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        setIsLoading(false);
        return;
      }
      try {
        setIsLoading(true);
        // Users
        let allUsers = await getDocs(collection(db, 'users'));
        let allUserDocs = allUsers.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        allUsers = await getDocs(collection(db, 'websiteForms'));
        allUserDocs = [...allUserDocs, ...allUsers.docs.map((doc) => ({ id: doc.id, ...doc.data() }))];
        setAllUsers(allUserDocs);
        const admins = allUserDocs.filter(ele => ele?.type === 'Admin');
        const inactive = allUserDocs.filter(ele => ele?.type === 'inactive');
        const clients = allUserDocs.filter(ele => ele?.type === 'Client' && !ele?.lead);
        const leads = allUserDocs.filter(ele => ele?.type === 'Client' && ele?.lead);
        const agents = allUserDocs.filter(ele => ele?.type === 'Agent' || ele?.isAgent);
        const sales = allUserDocs.filter(ele => ele?.type === 'Sales');
        const rh = allUserDocs.filter(ele => ele?.type === 'RH');
        const partners = allUserDocs.filter(ele => ele?.type === 'Partner').map(ele => ({ ...ele, objetivos: [] }));
        setAdmins(admins || []);
        setInactive(inactive || []);
        setClients(clients || []);
        setLeads(leads || []);
        setAgents(agents || []);
        setSales(sales || []);
        setRH(rh || []);
        setPartners(partners || []);
        // Get partners objetivos
        for (const project of partners) {
          const objetivosCollectionRef = collection(db, 'users', project?.id, 'objetivos');
          const querySnapshot = await getDocs(objetivosCollectionRef);
          let objetivos = [];
          querySnapshot.forEach(doc => {
            const sprint = { ...doc.data(), id: doc.id };
            objetivos.push(sprint);
          });
          project.objetivos = objetivos;
        }
        // Projects
        let allProjects = await getDocs(collection(db, 'projects'));
        let allProjectDocs = allProjects.docs.map((doc) => ({ id: doc.id, ...doc.data(), tasks: [] }));
        // Get projects sprints
        for (const project of allProjectDocs) {
          const sprintsCollectionRef = collection(db, 'projects', project?.id, 'sprints');
          const querySnapshot = await getDocs(sprintsCollectionRef);
          let sprints = [];
          querySnapshot.forEach(doc => {
            const sprint = { ...doc.data(), id: doc.id };
            sprints.push(sprint);
          });
          project.sprints = sprints;
        }
        const projects = allProjectDocs.filter(ele => ele?.type === 'client');
        const internProjects = allProjectDocs.filter(ele => ele?.type === 'intern');
        setProjects(projects);
        setInternProjects(internProjects);
        // Settings
        const settings = await getDoc(doc(db, 'admin', 'geral'));
        setSettings(settings.data());
        // Templates
        const templates = await getDocs(collection(db, 'templates'));
        const allTemplates = templates.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setTemplates(allTemplates);

        if (user?.type === "Admin") {
          // Finances
          const payments = await getDocs(collection(db, 'payments'));
          const allPayments = payments.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setPayments(allPayments);
          const receivements = await getDocs(collection(db, 'receivements'));
          const allReceivements = receivements.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setReceivements(allReceivements);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    if (user && user?.type !== "inactive") {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [user]);

  return (
    <Context.Provider value={{
      user, setUser,
      allUsers, setAllUsers,
      admins, setAdmins,
      clients, setClients,
      leads, setLeads,
      agents, setAgents,
      sales, setSales,
      rh, setRH,
      inactive, setInactive,
      partners, setPartners,
      projects, setProjects,
      internProjects, setInternProjects,
      payments, setPayments,
      receivements, setReceivements,
      settings, setSettings,
      templates, setTemplates,
      isLoading
    }}>
      {children}
    </Context.Provider>
  );
};
