import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Schedule() {
    const navigate = useNavigate();
    return (
        <div className='h-screen p-8'>
            <p onClick={() => navigate(-1)} className='relative text-left cursor-pointer'>
                <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
            </p>
            <div className='flex items-center justify-center w-full h-full'>
                <p className='text-[1.5rem] font-bold'>Página em construção</p>
            </div>
        </div>
    );
}

