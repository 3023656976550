import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HiOutlineLogout } from "@react-icons/all-files/hi/HiOutlineLogout";
import { MdWbSunny } from '@react-icons/all-files/md/MdWbSunny';
import { RiMoonClearFill } from '@react-icons/all-files/ri/RiMoonClearFill';
import { BsBriefcaseFill } from "react-icons/bs";
import { FaNetworkWired } from "react-icons/fa";
import { IoIosHome, IoMdPeople, IoIosSettings } from "react-icons/io";
import { FaHandshakeAngle } from "react-icons/fa6";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { signOut } from 'firebase/auth';
import { auth } from '../services/firebase';
import Logo from '../content/images/icons/logo-visiond.png';
import { Context } from '../contexts/Context';
import './styles.css';

export default function Navbar({ type }: { type: string }) {
    const navigate = useNavigate();
    const location = useLocation();
    const context = useContext(Context);

    const [isDarkMode, setIsDarkMode] = useState(false);
    const [menuActive, setMenuActive] = useState(false);

    const lang = localStorage.getItem('lang') || 'pt';

    useEffect(() => {
        const currentTheme = localStorage.getItem('theme');
        if (currentTheme === 'dark') {
            setIsDarkMode(true);
            document.body.classList.add("dark-mode");
        }
    }, []);

    useEffect(() => {
        var darkThemeSelected = (localStorage.getItem('theme') !== null && localStorage.getItem('theme') === 'dark');
        darkThemeSelected ? document.body.classList.add('dark-mode') : document.body.classList.remove('dark-mode');
    }, []);

    useEffect(() => {
        setMenuActive(false);
        document.body.style.overflow = 'auto';
        window.scroll(0, 0);
    }, [location]);

    function changeTheme() {
        if (isDarkMode) {
            document.body.classList.remove("dark-mode");
            localStorage.removeItem('theme');
        } else {
            document.body.classList.add("dark-mode");
            localStorage.setItem('theme', 'dark');
        }
        setIsDarkMode(!isDarkMode);
    }

    interface CustomLinkProps {
        href: string;
        icon?: React.ComponentType<{ width?: number }>;
        children: React.ReactNode;
    }

    const CustomLink: React.FC<CustomLinkProps> = ({ href, icon: Icon, children }) => {
        let selected = (href === window.location.pathname
            || (href === '/client' && window.location.pathname.split('/')[1] && window.location.pathname.split('/')[1] === 'client')
            || (href === '/agent' && window.location.pathname.split('/')[1] && window.location.pathname.split('/')[1] === 'agent')
            || (href === '/blog' && window.location.pathname.split('/')[1] && window.location.pathname.split('/')[1] === 'blog'));
        return (
            <div className='group'>
                <Link to={href} className={`flex gap-4 rounded-full p-3 items-center oitocentos:px-8 oitocentos:p-3  ${selected ? ' text-[var(--white)]' : ''}`}>
                    {Icon && <span className="group-hover:text-[var(--white)]" ><Icon /></span>}
                    <p className='text-[1rem] font-bold group-hover:text-[var(--white)]'>{children}</p>
                </Link>
            </div>
        )
    }

    const CustomMultipleLink: React.FC<CustomLinkProps> = ({ href, icon: Icon, children }) => {
        let selected = (href === '/clients' && window.location.pathname.includes('client'))
            || (href === '/projects' && window.location.pathname.includes('project'))
            || (href === '/agents' && window.location.pathname.includes('agent'))
            || (href === '/finances' && window.location.pathname.includes('finances'))
            || (href === '/partners' && window.location.pathname.includes('partner'));
        const [open, setOpen] = useState(false);
        return (
            <div className='cursor-pointer group'>
                <div onClick={() => setOpen(!open)} className={`flex gap-4 rounded-full p-3 items-center oitocentos:px-8 oitocentos:p-3  ${selected ? 'text-[var(--white)]' : ''}`}>
                    {Icon && <span className="" ><Icon /></span>}
                    <p className='text-[1rem] font-bold'>{children}</p>
                </div>
                <div className={`max-h-0 flex flex-col gap-4 overflow-hidden pl-20 navbar-submenu group-hover:max-h-[4rem] transition-all duration-500`}>
                    <Link to={`${href}/${href === '/projects' ? 'intern' : href === '/finances' ? 'payments' : 'leads'}`} className='text-[1rem] hover:text-[var(--white)]'>- {href === '/projects' ? 'Internos' : href === '/finances' ? 'Pagos' : 'Leads'}</Link>
                    <Link to={`${href}`} className='text-[1rem] hover:text-[var(--white)]'>- {href === '/projects' ? 'Clientes' : href === '/finances' ? 'Recebidos' : 'Ativos'}</Link>
                </div>
            </div>
        )
    }

    async function logOut() {
        try {
            await signOut(auth);
            localStorage.clear();
            navigate('/');
        } catch (error) {
            alert("Log out failed.");
        }
    }

    const toggleMenu = () => {
        if (!menuActive)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'auto';
        setMenuActive(!menuActive);
    };

    return (
        <div id='navbar' className='relative'>
            <div className={`transition-all duration-300 fixed top-0 left-0 h-full flex flex-col items-start justify-center oitocentos:justify-between oitocentos:h-screen oitocentos:w-[17rem] w-full bg-[var(--darkgray)] px-4 oitocentos:px-8 text-gray py-8 pl-[8vw] oitocentos:py-4 z-[10] oitocentos:translate-y-0 ${menuActive ? 'translate-y-0' : 'translate-y-[-100%]'}`}>
                <div className='flex flex-col h-full gap-8'>
                    <div className='flex-col hidden gap-4 oitocentos:items-start oitocentos:flex'>
                        <img src={Logo} alt="logo" className={`w-[10rem] ${isDarkMode ? 'invert' : ''}`} />
                        <h2 className='text-[1.2rem] text-[var(--stats)] font-bold'>WORK SPACE</h2>
                    </div>
                    <div className='flex flex-col justify-between h-full gap-2 pt-12 oitocentos:pt-0'>
                        <div className='flex flex-col h-full gap-2'>
                            <CustomLink icon={IoIosHome} href="/home" >{lang === 'pt' ? 'Início' : 'Home'}</CustomLink>
                            {(type === 'Admin' || type === 'Sales') && <CustomMultipleLink icon={BsBriefcaseFill} href="/clients" >{lang === 'pt' ? 'Clientes' : 'Clients'}</CustomMultipleLink>}
                            {(type === 'Admin') && <CustomMultipleLink icon={FaNetworkWired} href="/projects" >{lang === 'pt' ? 'Projetos' : 'Projects'}</CustomMultipleLink>}
                            {(type === 'Admin' || type === 'HR') && <CustomMultipleLink icon={IoMdPeople} href="/agents">{lang === 'pt' ? 'Agentes' : 'Agents'}</CustomMultipleLink>}
                            {(type === 'Admin' || type === 'HR') && <CustomMultipleLink icon={FaHandshakeAngle} href="/partners">{lang === 'pt' ? 'Parcerias' : 'Partners'}</CustomMultipleLink>}
                            {type === 'Admin' && <CustomMultipleLink icon={FaMoneyBillTrendUp} href="/finances">{lang === 'pt' ? 'Finanças' : 'Finances'}</CustomMultipleLink>}
                            {type === 'Admin' && <CustomLink icon={IoIosSettings} href="/settings">{lang === 'pt' ? 'Definições' : 'Settings'}</CustomLink>}
                            {type !== 'Admin' && <CustomLink icon={FaNetworkWired} href={`/${type.toLowerCase()}/${context?.user?.email}`}>{lang === 'pt' ? 'Meu espaço' : 'My Space'}</CustomLink>}
                            {type !== 'Admin' && <CustomLink icon={BsBriefcaseFill} href="/contact/geral@visiond.pt/+351963687459">{lang === 'pt' ? 'Suporte' : 'Support'}</CustomLink>}
                        </div>
                        <div className='flex flex-col gap-2 '>
                            <div onClick={changeTheme} className={`flex gap-4 rounded-full p-3 items-center oitocentos:px-8 oitocentos:p-3 cursor-pointer hover:text-[var(--white)]`}>
                                <span className="icon-wrapper group-hover:text-[var(--white)]" >{isDarkMode ? <MdWbSunny /> : <RiMoonClearFill />}</span>
                                <p className='text-[1rem] font-bold group-hover:text-[var(--white)]'>{lang === 'pt' ? 'Mudar tema' : 'Theme'}</p>
                            </div>
                            <div onClick={logOut} className={`flex gap-4 rounded-full p-3 items-center oitocentos:px-8 oitocentos:p-3 cursor-pointer hover:text-[var(--white)]`}>
                                <HiOutlineLogout className='group-hover:text-[var(--white)]'></HiOutlineLogout>
                                <p className='text-[1rem] font-bold group-hover:text-[var(--white)]'>{lang === 'pt' ? 'Sair' : 'Logout'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='oitocentos:hidden fixed top-0 left-0 flex justify-between w-full bg-[var(--darkgray)] h-[5rem] items-center px-[8vw] z-[30]'>
                <img src={Logo} alt="logo" className={`w-28 z-[20] ${isDarkMode ? 'invert' : ''}`} />
                <div onClick={toggleMenu} id="menu-btn" className={`w-[20px] h-7 flex flex-col justify-evenly items-center z-[20] ${menuActive && 'active'}`}>
                    <div className="menu-btn-bar bg-[var(--white)] relative w-full h-[3px]"></div>
                    <div className="menu-btn-bar bg-[var(--white)] relative w-full h-[3px]"></div>
                    <div className="menu-btn-bar bg-[var(--white)] relative w-full h-[3px]"></div>
                </div>
            </div>
        </div>
    );
}

