import React, { useContext, useEffect, useState } from 'react';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import ClientPage from './profile/client';
import AgentPage from './profile/agent';
import SalesPage from './profile/sales';
import RHPage from './profile/RH';
import PartnerPage from './profile/partner';
import { Context } from '../contexts/Context';
import { updateData } from '../services/server';
import { getAuth, updatePassword } from 'firebase/auth';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Loading from './Loading';
import toast, { Toaster } from "react-hot-toast";

export default function Home() {
    const context = useContext(Context);

    const [user, setUser] = useState<any>(null);
    const [meetings, setMeetings] = useState<any>(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [edit, setEdit] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [newTask, setNewTask] = useState(false);
    const [newTaskDesc, setNewTaskDesc] = useState('');
    const [newTaskDeadline, setNewTaskDeadline] = useState('');
    const [lang, setLang] = useState('pt');

    useEffect(() => {
        if (context?.user) {
            setUser(context?.user);
            const today = new Date();
            setMeetings(context?.user?.meetings?.filter((m: any) => new Date(m.date) >= today));
        }
        setLang(localStorage.getItem('lang') || 'pt');
    }, [context?.user]);


    function UserInfo() {
        if (user?.type === 'Client') return <ClientPage user={user} setUser={setUser} lang={lang} />;
        else if (user?.type === 'Sales') return <SalesPage user={user} setUser={setUser} />;
        else if (user?.type === 'Agent' || user?.isAgent) return <AgentPage user={user} setUser={setUser} lang={lang} />;
        else if (user?.type === 'HR') return <RHPage user={user} setUser={setUser} />;
        else if (user?.type === 'Partner') return <PartnerPage user={user} setUser={setUser} />;
        else return null;
    }

    function changeProfileImage() {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async (e: any) => {
            const file = e.target.files[0];
            if (!file) return;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                await updateData(toast, 'users', user?.email, { ...user, profileImg: reader.result }, 'Imagem de perfil atualizada.', () => { });
                setUser({ ...user, profileImg: reader.result });
                context?.setUser({ ...user, profileImg: reader.result });
            }
        }
        input.click();
    }

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    function isValidPassword(password: string): boolean {
        return password.length >= 6 && /[a-zA-Z]/.test(password) && /\d/.test(password);
    }

    async function editProfile() {
        try {
            if (password || confirmPassword) {
                if (password !== confirmPassword)
                    return toast.error(lang === 'pt' ? 'As passwords não coincidem.' : 'Passwords do not match.');
                if (!isValidPassword(password))
                    return toast.error(lang === 'pt' ? 'A password deve ter pelo menos 6 caracteres e conter letras e números.' : 'The password must be at least 6 characters long and contain letters and numbers.');
                const auth = getAuth();
                var currentUser = auth.currentUser;
                if (!currentUser)
                    return toast.error(lang === 'pt' ? 'Erro ao atualizar password.' : 'Error updating password.');
                await updatePassword(currentUser, password);
            }
            await updateData(toast, 'users', user?.email, user, lang === 'pt' ? 'Informações do utilizador atualizadas.' : 'User information updated.', () => { });
            localStorage.setItem('lang', user?.lang || 'pt');
            context?.setUser(user);
        } catch (err) {
            toast.error(lang === 'pt' ? 'Erro ao atualizar informações.' : 'Error updating information.');
        }
        setPassword('');
        setConfirmPassword('');
    }

    function addTask() {
        if (!newTaskDesc || !newTaskDeadline) return toast.error(lang === 'pt' ? 'Preencha todos os campos.' : 'Fill in all fields.');
        if (!user?.myTasks) user.myTasks = [];
        user.myTasks.push({ description: newTaskDesc, deadline: newTaskDeadline });
        context?.setUser({ ...user, myTasks: user.myTasks });
        updateData(toast, 'users', user?.email, user, lang === 'pt' ? 'Tarefa adicionada.' : 'Task added.', () => { });
    }

    function deleteTask(index: number) {
        if (!window.confirm(lang === 'pt' ? 'Tem a certeza que deseja eliminar/completar esta tarefa?' : 'Are you sure you want to delete/complete this task?')) return;
        if (!user?.myTasks) user.myTasks = [];
        user.myTasks.splice(index, 1);
        context?.setUser({ ...user, myTasks: user.myTasks });
        updateData(toast, 'users', user?.email, user, lang === 'pt' ? 'Tarefa removida com sucesso.' : 'Task removed successfully.', () => { });
    }

    if (!user) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <div className='flex justify-between w-full gap-6 mb-8'>
                <h1 className='text-[1.5rem] font-bold'>
                    {lang === 'pt' ? `Bem-vindo de volta ${user?.name && (user?.name).split(' ')[0]} !` : `Welcome back ${user?.name && (user?.name).split(' ')[0]}!`}
                </h1>
                <div onClick={changeProfileImage} className='relative rounded-full w-[6rem] h-[6rem] overflow-hidden group cursor-pointer shrink-0' >
                    {
                        user?.profileImg ?
                            <>
                                <img src={user.profileImg} alt="profile" className='object-cover w-full h-full transition-all duration-300 group-hover:brig</h1>htness-75' />
                                <MdOutlineModeEditOutline className='absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] hidden group-hover:flex text-white text-[1.5rem]' />
                            </>
                            :
                            <>
                                <div className='w-full bg-[grey] h-full aspect-square transition-all duration-300 group-hover:brightness-75'></div>
                                <FaUser className='absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] text-white text-[1.5rem]' />
                            </>
                    }
                </div>
            </div>
            <UserInfo />
            <hr />
            <div className='flex flex-col w-full gap-8 oitocentos:flex-row'>
                <div className='flex flex-col w-full gap-8 mt-8 oitocentos:w-1/2'>
                    <h2 className='text-[1.2rem] font-bold'>{lang === 'pt' ? 'Próximas reuniões' : 'Upcoming Meetings'}</h2>
                    <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                        {
                            (!meetings || meetings.length === 0) ? <p>{lang === 'pt' ? 'Nenhuma reunião agendada' : 'No scheduled meetings'}</p> :
                                meetings && Array.isArray(meetings) && meetings.sort((a: any, b: any) => (new Date(a.date) as any) - (new Date(b.date) as any)).map((meeting: any, index: number) => (
                                    <div key={index} onClick={() => window.open(meeting?.link, '_blank')} className='small-button bg-[var(--primary)] justify-between'>
                                        <div>
                                            <p>{meeting?.subject}</p>
                                            <p>{user?.name !== meeting.from ? lang === 'pt' ? `Agendado por: ${meeting?.from}` : `Scheduled by: ${meeting?.from}` : lang === 'pt' ? `Com: ${meeting?.to}` : `With: ${meeting?.to}`}</p>
                                        </div>
                                        <p>{formateDate(meeting?.date)}, {meeting?.time}</p>
                                    </div>
                                ))
                        }
                    </div>
                </div>
                <div className='flex flex-col w-full gap-8 mt-8 oitocentos:w-1/2'>
                    <div className='flex items-center justify-between'>
                        <h2 className='text-[1.2rem] font-bold'>{lang === 'pt' ? 'As minhas tarefas' : 'My Tasks'}</h2>
                        <button onClick={() => setNewTask(true)} className='w-6 h-6 bg-[var(--green)] rounded-full text-white'>+</button>
                    </div>
                    <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                        {
                            (!user?.myTasks || user?.myTasks.length === 0) ? <p>{lang === 'pt' ? 'Nenhuma tarefa guardada' : 'No saved tasks'}</p> :
                                user?.myTasks && Array.isArray(user?.myTasks) && user?.myTasks.sort((a: any, b: any) => (new Date(a.date) as any) - (new Date(b.date) as any)).map((task: any, index: number) => (
                                    <div key={index} className='small-button bg-[var(--primary)] justify-between'>
                                        <p className='text-[1rem]'>{task?.description}</p>
                                        <div className='flex gap-8'>
                                            <p className='text-[1rem]'>{formateDate(task?.deadline)}</p>
                                            <button className={`w-5 h-5 border-[2px] border-[var(--black)] rounded-[4px] cursor-pointer bg-[var(--red)]`} onClick={() => deleteTask(index)}></button>
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
            {
                newTask && (
                    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50'>
                        <div className='w-[30rem] bg-white p-4 rounded-lg flex flex-col gap-4'>
                            <p className='text-[1.2rem] font-bold'>{lang === 'pt' ? 'Adicionar Tarefa' : 'Add Task'}</p>
                            <input
                                type="text"
                                placeholder={lang === 'pt' ? 'Descrição' : 'Description'}
                                value={newTaskDesc}
                                onChange={(e) => setNewTaskDesc(e.target.value)}
                                className='input'
                            />
                            <input
                                type="date"
                                placeholder={lang === 'pt' ? 'Data limite' : 'Deadline'}
                                value={newTaskDeadline}
                                onChange={(e) => setNewTaskDeadline(e.target.value)}
                                className='input'
                            />
                            <div className='flex justify-between'>
                                <button onClick={addTask} className='small-button bg-[var(--primary)]'>{lang === 'pt' ? 'Adicionar' : 'Add'}</button>
                                <button className='small-button bg-[var(--red)]' onClick={() => setNewTask(false)}>{lang === 'pt' ? 'Cancelar' : 'Cancel'}</button>
                            </div>
                        </div>
                    </div>
                )
            }
            <hr />
            <div className='flex flex-col w-full gap-8 py-8'>
                <p className='font-bold'>{lang === 'pt' ? 'Detalhes da conta' : 'Account Details'}</p>
                <div className='grid w-full grid-cols-1 gap-6 mil:grid-cols-2'>
                    <div className='flex flex-col w-full gap-2'>
                        <p>{lang === 'pt' ? 'Nome' : 'Name'}</p>
                        <input
                            disabled={!edit}
                            type="text"
                            placeholder={lang === 'pt' ? 'Nome' : 'Name'}
                            value={user?.name}
                            onChange={(e) => setUser((prev: any) => ({ ...prev, name: e.target.value }))}
                            className='input'
                        />
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <p>Email</p>
                        <input
                            type="email"
                            placeholder='Email'
                            value={user?.email}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <p>{lang === 'pt' ? 'Contacto' : 'Contact'}</p>
                        <input
                            disabled={!edit}
                            type="tel"
                            placeholder={lang === 'pt' ? 'Contacto' : 'Contact'}
                            value={user?.contacto}
                            onChange={(e) => setUser((prev: any) => ({ ...prev, contacto: e.target.value }))}
                            className='input'
                        />
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <p>{lang === 'pt' ? 'LinkedIn Link' : 'LinkedIn Link'}</p>
                        <input
                            disabled={!edit}
                            type="text"
                            placeholder={lang === 'pt' ? 'LinkedIn Link' : 'LinkedIn Link'}
                            value={user?.linkedin}
                            onChange={(e) => setUser((prev: any) => ({ ...prev, linkedin: e.target.value }))}
                            className='input'
                        />
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <p>{lang === 'pt' ? 'Língua' : 'Language'}</p>
                        <select
                            className='input'
                            disabled={!edit}
                            value={user?.lang || "pt"}
                            onChange={(e) => setUser((prev: any) => ({ ...prev, lang: e.target.value }))}
                        >
                            <option value="pt">Português</option>
                            <option value="en">Inglês</option>
                        </select>
                    </div>
                    {
                        edit && newPassword && (
                            <>
                                <div className='flex flex-col w-full gap-2'>
                                    <p>{lang === 'pt' ? 'Nova Password' : 'New Password'}</p>
                                    <div className='relative'>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder={lang === 'pt' ? 'Password' : 'Password'}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className='input'
                                        />
                                        <div className='absolute cursor-pointer top-3 right-3' onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col w-full gap-2'>
                                    <p>{lang === 'pt' ? 'Confirmar Password' : 'Confirm Password'}</p>
                                    <div className='relative'>
                                        <input
                                            type={showPassword2 ? 'text' : 'password'}
                                            placeholder={lang === 'pt' ? 'Password' : 'Password'}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            className='input'
                                        />
                                        <div className='absolute cursor-pointer top-3 right-3' onClick={() => setShowPassword2(!showPassword2)}>
                                            {showPassword2 ? <FaEye /> : <FaEyeSlash />}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
                <div className='flex gap-4'>
                    {edit && <button className='small-button bg-green' onClick={editProfile}>{lang === 'pt' ? 'Atualizar' : 'Update'}</button>}
                    {edit && !newPassword && <button className='small-button bg-[var(--primary)]' onClick={() => setNewPassword(true)}>{lang === 'pt' ? 'Alterar Password' : 'Change Password'}</button>}
                    <button className='small-button bg-[var(--primary)]' onClick={() => setEdit(!edit)}>
                        {edit ? lang === 'pt' ? 'Cancelar' : 'Cancel' : <><p className='flex items-center gap-4'>{lang === 'pt' ? 'Editar' : 'Edit'} <MdOutlineModeEditOutline /></p></>}
                    </button>
                </div>
            </div>
        </div>
    );
}
