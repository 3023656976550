import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsCheck } from 'react-icons/bs/';

import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../contexts/Context';
import { updateData, create } from '../../services/server';
// import { FaUserCheck } from "react-icons/fa";
import Loading from '../Loading';
import toast, { Toaster } from "react-hot-toast";

export default function EditProject() {
    const context = useContext(Context);
    const navigate = useNavigate();

    const { id = "", clientEmail = "" } = useParams();

    const [project, setProject] = useState<any>({});
    const edit = window.location.pathname.includes('edit');
    const intern = window.location.pathname.includes('intern');

    const selectRef = useRef<HTMLDivElement>(null);
    const selectRef2 = useRef<HTMLDivElement>(null);

    const [company, setCompany] = useState('');
    const [nome, setNome] = useState('');
    const [projectManager, setProjectManager] = useState('');
    const [link, setLink] = useState('');
    const [faseDoProjeto, setFaseDoProjeto] = useState(1);
    const [services, setServices] = useState<string[]>([]);
    const [orcamento, setOrcamento] = useState(0);
    const [tipoOrcamento, setTipoOrcamento] = useState("");
    const [projectOverview, setProjectOverview] = useState('');
    const [deadline, setDeadline] = useState('');
    const [consulting, setConsulting] = useState(false);
    const [projectLink, setProjectLink] = useState('');
    const [requesitosLevantados, setRequesitosLevantados] = useState(false);
    const [chanceOfFunds, setChanceOfFunds] = useState('');
    const [funds, setFunds] = useState('');
    const [meetingLink, setMeetingLink] = useState('');
    const [meetingDate, setMeetingDate] = useState('');
    const [meetingTime, setMeetingTime] = useState('');

    const [clients, setClients] = useState<any>([]);
    const [agents, setAgents] = useState<any>([]);

    const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [isDropdownOpen2, setIsDropdownOpen2] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            if (edit) {
                const project = (intern ? context?.internProjects : context?.projects)?.find((project: any) => project?.id === id);
                setProject(project);
            }
            if (clientEmail) {
                setCompany(context?.clients.find((client: any) => client?.email === clientEmail)?.name || '');
            }
            setServices(context?.settings.services);
            setClients(context?.clients.filter((client: any) => !client?.concluido));
            setAgents(context?.agents.filter((agent: any) => !agent?.lead));
        };
        fetchData();
    }, [edit, context?.settings, context?.clients, context?.agents, clientEmail, context?.projects, context?.internProjects, id, intern]);

    useEffect(() => {
        if (project) {
            setCompany(project?.company || '');
            setNome(project?.name || '');
            setProjectManager(project?.projectManager || '');
            setLink(project?.link || '');
            setOrcamento(project?.orcamento || "");
            setFaseDoProjeto(project?.faseDoProjeto || 0);
            setSelectedAgents(project?.agents || []);
            setSelectedServices(project?.services || []);
            setOrcamento(project?.orcamento || '');
            setTipoOrcamento(project?.tipoOrcamento || '');
            setProjectOverview(project?.overview || '');
            setConsulting(project?.consulting || false);
            setProjectLink(project?.projectLink || '');
            setDeadline(project?.deadline || '');
            setRequesitosLevantados(project?.requesitosLevantados || '');
            setChanceOfFunds(project?.chanceOfFunds || '');
            setFunds(project?.funds || '');
            setMeetingLink(project?.meetingLink || '');
            setMeetingDate(project?.meetingDate || '');
            setMeetingTime(project?.meetingTime || '');
        }
    }, [project]);

    // async function completeProject() {
    //     if (!window.confirm('Tem a certeza que pretende concluir este projeto?')) return;
    //     const now = new Date().toISOString();
    //     await updateData(toast, 'projects', `${project?.company}-${project?.name}`, { concluido: true, endDate: now }, 'Projeto concluído!', () => {
    //         context?.setProjects((prev: any) => {
    //             return prev.map((ele: any) => ele.id === `${project?.company}-${project?.name}` ? { ...ele, concluido: true, endDate: now } : ele);
    //         });
    //     });
    // }

    const handleSubmit = async (e: any) => {
        if (!nome || (!intern && selectedServices.length === 0) || (!intern && (!tipoOrcamento || orcamento < 0)) || !projectOverview || !projectManager || (!intern && !company) || !chanceOfFunds)
            return toast.error('Preencha todos os campos.');
        const data = {
            type: intern ? 'intern' : 'client',
            lead: false,
            company: intern ? 'VisionD' : company.trim(),
            projectManager: projectManager.trim(),
            name: nome.trim(),
            link: link.trim(),
            orcamento: orcamento,
            toPay: orcamento - (edit ? project?.paid : 0),
            paid: edit ? project?.paid : 0,
            faseDoProjeto: faseDoProjeto,
            services: selectedServices,
            agents: selectedAgents,
            tipoOrcamento: tipoOrcamento.trim(),
            overview: projectOverview.trim(),
            deadline: deadline,
            active: true,
            consulting,
            projectLink,
            requesitosLevantados,
            creationDate: edit ? new Date().toISOString() : new Date().toISOString(),
            chanceOfFunds,
            funds,
            meetingLink,
            meetingDate,
            meetingTime,
        };
        if (edit)
            await updateData(toast, 'projects', `${intern ? 'VisionD' : company}-${nome}`, data, 'Projeto atualizado com sucesso.', () => {
                const newProjects = [...(intern ? context?.internProjects : context?.projects)?.filter((project: any) => project.id !== `${intern ? 'VisionD' : company}-${nome}`), { ...data, id: `${intern ? 'VisionD' : company}-${nome}` }]
                if (!intern) context?.setProjects(newProjects);
                else context?.setInternProjects(newProjects);
                navigate(-1);
            });
        else
            await create(toast, 'projects', `${intern ? 'VisionD' : company}-${nome}`, data, 'Projeto criado com sucesso.', () => {
                const newProjects = [...(intern ? context?.internProjects : context?.projects)?.filter((project: any) => project.id !== `${intern ? 'VisionD' : company}-${nome}`), { ...data, id: `${intern ? 'VisionD' : company}-${nome}` }]
                if (!intern) context?.setProjects(newProjects);
                else context?.setInternProjects(newProjects);
                navigate(-1);
            });
    };

    const handleAgentSelection = (agentName: string) => {
        setSelectedAgents((prevSelectedAgents: any) => {
            if (prevSelectedAgents.includes(agentName)) {
                return prevSelectedAgents.filter((name: string) => name !== agentName);
            } else {
                return [...prevSelectedAgents, agentName];
            }
        });
    };

    const handleServiceSelection = (agentName: string) => {
        setSelectedServices((prevSelectedAgents: any) => {
            if (prevSelectedAgents.includes(agentName)) {
                return prevSelectedAgents.filter((name: string) => name !== agentName);
            } else {
                return [...prevSelectedAgents, agentName];
            }
        });
    };

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectRef]);

    useEffect(() => {
        function handleClickOutside2(event: any) {
            if (selectRef2.current && !selectRef2.current.contains(event.target)) {
                setIsDropdownOpen2(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside2);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside2);
        };
    }, [selectRef2]);

    if (!services) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <div className='flex flex-col items-end justify-between w-full gap-4 cinco:items-center cinco:flex-row'>
                <p onClick={() => navigate(-1)} className='relative mb-8 text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
                </p>
                <button onClick={() => navigate("/client/create")} className='small-button bg-[var(--primary)]'>+ Novo Cliente</button>
            </div>
            <div className='flex items-center justify-between w-full mb-8'>
                <h1 className='text-[1.5rem] font-bold'>{edit ? 'Editar' : 'Novo Projeto'}</h1>
            </div>
            <div className='grid grid-cols-1 mil:grid-cols-2 text-[1rem] gap-4'>
                {
                    !intern &&
                    <div className="flex flex-col gap-2">
                        <p className='mandatory'>Cliente</p>
                        <select
                            name="cliente"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            className='cursor-pointer input'>
                            <option value="">Selecionar Cliente</option>
                            {
                                clients.length !== 0 ? clients.map((ele: any, index: number) => {
                                    return (
                                        <option key={index} value={ele?.name} className=''>{ele?.name}</option>
                                    )
                                }) :
                                    <p className='w-full text-center'>Nenhum resultado</p>
                            }
                        </select>
                    </div>
                }
                <div className="flex flex-col gap-2">
                    <p className='mandatory'>Nome do projeto</p>
                    <input
                        type='text'
                        placeholder='Nome do projeto'
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        disabled={edit}
                        className='input'
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <p className='mandatory'>Fase do Projeto</p>
                    <select
                        name="faseDoProjeto"
                        value={faseDoProjeto}
                        onChange={(e) => setFaseDoProjeto(parseInt(e.target.value, 10))}
                        className='cursor-pointer input'>
                        <option value="" disabled selected hidden>Selecionar Fase do Projeto</option>
                        <option value="1">Adjudicação/Estrutura</option>
                        <option value="2">Desenvolvimento</option>
                        <option value="3">Testes/Lançamento</option>
                        <option value="4">Completo</option>
                    </select>
                </div>
                <div className="flex flex-col gap-2">
                    <p className=''>Deadline</p>
                    <input
                        type='date'
                        placeholder='Deadline'
                        value={deadline}
                        onChange={(e) => setDeadline(e.target.value)}
                        className='input'
                    />
                </div>
                {
                    !intern &&
                    <>
                        <div className="flex flex-col gap-2">
                            <p className='mandatory'>Serviços</p>
                            <div
                                className="relative cursor-pointer input"
                                onClick={() => setIsDropdownOpen2(!isDropdownOpen2)}
                                ref={selectRef2}
                            >
                                {selectedServices.length === 0
                                    ? 'Selecione serviços'
                                    : `${selectedServices.length} selecionados`}
                                {isDropdownOpen2 && (
                                    <div className="absolute w-full left-0 top-10 bg-white border-2 border-[var(--black)] rounded-md z-10 h-[15rem] overflow-y-auto my-scrollbar">
                                        {services.map((service, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleServiceSelection(service);
                                                }}
                                            >
                                                <div className={`w-4 h-4 border-[1px] border-[var(--black)] rounded-[4px] ${selectedServices.includes(service) ? 'bg-[var(--primary)]' : ''}`}>
                                                    <span className={`${selectedServices.includes(service) ? 'flex' : 'hidden'} text-white`}><BsCheck /></span>
                                                </div>
                                                <p className="ml-2 text-sm">{service}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                }
                {
                    !intern &&
                    <>
                        <div className="flex flex-col gap-2">
                            <p className='mandatory'>Orçamento</p>
                            <input
                                type='number'
                                placeholder='Orçamento'
                                value={orcamento}
                                onChange={(e) => setOrcamento(parseInt(e.target.value, 10))}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className='mandatory'>Tipo de orçamento</p>
                            <select
                                value={tipoOrcamento}
                                onChange={(e) => setTipoOrcamento(e.target.value)}
                                className='input'
                            >
                                <option value="">Selecione um tipo</option>
                                <option value="Pontual/Total">Pontual/Total</option>
                                <option value="Semanal">Semanal</option>
                                <option value="Mensal">Mensal</option>
                                <option value="Semestral">Semestral</option>
                                <option value="Anual">Anual</option>
                            </select>
                        </div>
                    </>
                }
                <div className="flex flex-col gap-2">
                    <p className='mandatory'>Probabilidade de financiamento</p>
                    <select value={chanceOfFunds} onChange={(e) => setChanceOfFunds(e.target.value)} className='input'>
                        <option value="">Selecionar</option>
                        <option value="Nula">Nula</option>
                        <option value="Baixa">Baixa</option>
                        <option value="Média">Média</option>
                        <option value="Alta">Alta</option>
                    </select>
                </div>
                <div className="flex flex-col gap-2">
                    <p className=''>Financiamento</p>
                    <input
                        type='text'
                        placeholder='Financiamento'
                        value={funds}
                        onChange={(e) => setFunds(e.target.value)}
                        className='input'
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <p className='mandatory'>Project Manager</p>
                    <select
                        value={projectManager}
                        onChange={(e) => setProjectManager(e.target.value)}
                        className='input'
                    >
                        <option value="">Selecione um agente</option>
                        {
                            agents && agents.length !== 0 ? agents.map((ele: any, index: number) => {
                                return (
                                    <option key={index} value={ele?.email} className=''>{ele?.name}</option>
                                )
                            }) :
                                <p className='w-full text-center'>Nenhum resultado</p>
                        }
                    </select>
                </div>
                <div className="flex flex-col gap-2">
                    <p className=''>Agentes</p>
                    <div
                        className="relative cursor-pointer input"
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        ref={selectRef}
                    >
                        {selectedAgents.length === 0
                            ? 'Selecione agents'
                            : `${selectedAgents.length} selecionados`}
                        {isDropdownOpen && (
                            <div className="absolute w-full left-0 top-10 bg-white border-2 border-[var(--black)] rounded-md z-10 h-[15rem] overflow-y-auto my-scrollbar">
                                {agents.map((agent: any, index: number) => (
                                    <div
                                        key={index}
                                        className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleAgentSelection(agent?.email);
                                        }}
                                    >
                                        <div className={`w-4 h-4 border-[1px] border-[var(--black)] rounded-[4px] ${selectedAgents.includes(agent?.email) ? 'bg-[var(--primary)]' : ''}`}>
                                            <span className={`${selectedAgents.includes(agent?.email) ? 'flex' : 'hidden'} text-white`}><BsCheck /></span>
                                        </div>
                                        <p className="ml-2 text-sm">{agent?.name}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <p className=''>Link do projeto</p>
                    <input
                        type='text'
                        placeholder='Link do projeto'
                        value={projectLink}
                        onChange={(e) => setProjectLink(e.target.value)}
                        className='input'
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <p className=''>Link da pasta partilhada</p>
                    <input
                        type='text'
                        placeholder='Link'
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        className='input'
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <p className=''>Link da reunião</p>
                    <input
                        type='text'
                        placeholder='Link'
                        value={meetingLink}
                        onChange={(e) => setMeetingLink(e.target.value)}
                        className='input'
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <p className=''>Dia semanal da reunião</p>
                    <select 
                        value={meetingDate}
                        onChange={(e) => setMeetingDate(e.target.value)}
                        className='input'
                    >
                        <option value="">Selecionar</option>
                        <option value="Segunda-feira">Segunda-feira</option>
                        <option value="Terça-feira">Terça-feira</option>
                        <option value="Quarta-feira">Quarta-feira</option>
                        <option value="Quinta-feira">Quinta-feira</option>
                        <option value="Sexta-feira">Sexta-feira</option>
                    </select>
                </div>
                <div className="flex flex-col gap-2">
                    <p className=''>Hora da reunião</p>
                    <input
                        type='time'
                        placeholder='Time'
                        value={meetingTime}
                        onChange={(e) => setMeetingTime(e.target.value)}
                        className='input'
                    />
                </div>
                {
                    !intern &&
                    <div className='flex gap-10'>
                        <div className="flex flex-col gap-4">
                            <p className='mandatory'>Com consultoria</p>
                            <div className='flex gap-4'>
                                <p>Sim</p>
                                <button onClick={() => setConsulting(true)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${consulting ? 'bg-green' : ''} cursor-pointer`}>
                                </button>
                                <p>Não</p>
                                <button onClick={() => setConsulting(false)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${!consulting ? 'bg-[red]' : ''} cursor-pointer`}>
                                </button>
                            </div>
                        </div>
                    </div>
                }
                <div className="flex flex-col gap-4">
                    <p className='mandatory'>Requisitos levantados</p>
                    <div className='flex gap-4'>
                        <p>Sim</p>
                        <button onClick={() => setRequesitosLevantados(true)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${requesitosLevantados ? 'bg-green' : ''} cursor-pointer`}>
                        </button>
                        <p>Não</p>
                        <button onClick={() => setRequesitosLevantados(false)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${!requesitosLevantados ? 'bg-[red]' : ''} cursor-pointer`}>
                        </button>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <p className='mandatory'>Descrição</p>
                    <textarea
                        placeholder='Descrição do projeto'
                        value={projectOverview}
                        onChange={(e) => setProjectOverview(e.target.value)}
                        className='resize-none my-scrollbar input h-[13.5rem]'
                    ></textarea>
                </div>
            </div>
            <div className='flex w-full gap-4 mt-8'>
                <button onClick={handleSubmit} className={`small-button ${edit ? 'bg-[var(--primary)]' : 'bg-green'}`}>{edit ? 'Atualizar' : 'Criar Projeto'}</button>
                {/* {
                    edit &&
                    <div onClick={completeProject} className='gap-4 small-button bg-[var(--green)]'>
                        Finalizar Projeto
                        <FaUserCheck />
                    </div>
                } */}
            </div>
        </div>
    );
}

