import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Context } from '../../../contexts/Context';
import { LuFilter, LuFilterX } from "react-icons/lu";
import Loading from '../../Loading';
import ExcelView from '../ExcelView';
import { exportExcel } from '../../../components/export';
import DownloadIcon from '../../../content/images/icons/download.png';
import { BsDatabaseFillAdd } from "react-icons/bs";

import { create, updateData } from '../../../services/server';
import toast, { Toaster } from "react-hot-toast";
import { ImCheckboxChecked } from 'react-icons/im';
import { importExcel } from '../../../components/import';

export default function Home() {
    const navigate = useNavigate();
    const context = useContext(Context);

    const [view, setView] = useState('list');
    const [tableData, setTableData] = useState<any>(null);
    const [leads, setLeads] = useState<any>(null);
    const [filterOn, setFilterOn] = useState(false);

    // Filters
    const [search, setSearch] = useState('');
    const [state, setState] = useState('');
    const [levelOfNeed, setLevelOfNeed] = useState('');
    const [timesSpoken, setTimesSpoken] = useState('');
    const [viewAll, setViewAll] = useState('1');

    useEffect(() => {
        if (context?.leads) {
            let result = context?.leads;
            // Filter by search
            if (search) {
                result = result.filter((ele: any) =>
                    (ele?.name).toLowerCase().includes(search.toLowerCase()) ||
                    (ele?.email).toLowerCase().includes(search.toLowerCase()));
            }
            // Filter by other filters
            result = result.filter((ele: any) =>
                (state ? ele?.state === state : true) &&
                (levelOfNeed ? ele?.levelOfNeed === levelOfNeed : true) &&
                (viewAll === "0" ? ele?.embaixador === context?.user?.email : true) &&
                (timesSpoken ? ele?.timesSpoken === timesSpoken : true)
            );
            setLeads(result);
            setTableData(result);
        }
    }, [search, levelOfNeed, filterOn, state, timesSpoken, context?.leads, viewAll, context?.user?.email]);

    const header = [
        "name",
        "responsavel",
        "role",
        "email",
        "contacto",
        "embaixador",
        "active",
        "state",
        "remarks",
    ];

    async function handleImport(e: any) {
        const file = e.target?.files[0];
        const newData: any = await importExcel(toast, file);
        if (newData !== '' && newData !== undefined && newData !== null) {
            const allEntriesValid = newData.every((item: any) => {
                return 'name' in item && 'email' in item && isValidEmail(item?.email);
            });
            if (allEntriesValid) {
                if (tableData)
                    setTableData([...tableData, ...newData]);
                else
                    setTableData(newData);
                toast.success('Dados importados com sucesso.');
            } else {
                toast.error('Todos os dados devem ter o campo "name" preenchido e "email" válido.');
            }
        } else {
            console.error('Erro ao importar os dados.');
            toast.error('Erro ao importar os dados.');
        }
    }

    function handleExport() {
        const exportData = leads?.map((client: any) => {
            return {
                nome: client?.name,
                "responsável": client?.responsavel,
                "função do responsável": client?.role,
                email: client?.email,
                contacto: client?.contacto,
                embaixador: client?.embaixador,
                ativo: client?.active,
                estado: client?.state,
                necessidade: client?.levelOfNeed,
                "tempo de resposta": client?.answerTime,
                "nº vezes contactado": client?.timesSpoken,
                "obs": client?.remarks,
            };
        });
        exportExcel(exportData, 'leads-clientes');
    }

    function isValidEmail(email: string): boolean {
        const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailPattern.test(email);
    }

    function saveData() {
        let error = '';
        try {
            tableData.forEach((client: any) => {
                if (error) return client;
                if (!client?.name || !client?.email) {
                    error = 'Existem campos obrigatórios que não estão preenchidos.';
                    return client;
                }
                if (!isValidEmail(client?.email)) {
                    error = 'Um dos emails é inválido.';
                    return client;
                }
            });
            if (error) {
                toast.error(error);
                return;
            }
            tableData.forEach((client: any) => {
                if (context?.leads.find((ele: any) => ele.email === client?.email))
                    updateData(toast, 'users', client?.email, client, '', () => { });
                else
                    create(toast, 'users', client?.email, { ...client, type: 'Client', lead: true, active: true, creationDate: new Date().toISOString() }, '', () => { });
                return client;
            });
            context?.setLeads(tableData);
            toast.success('Dados guardados com sucesso');
        } catch (error) {
            toast.error('Erro ao guardar os dados.');
        }
    }

    if (!leads) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full min-h-screen p-8'>
            <Toaster />
            <div className='flex flex-col items-end justify-between w-full gap-4 cinco:items-center cinco:flex-row'>
                <button onClick={() => navigate("/client/lead/create")} className='small-button bg-[var(--primary)]'>+ Nova Lead</button>
                <button onClick={() => navigate('checklist')} className='small-button bg-[var(--blue)]'>Ver Check List <ImCheckboxChecked /></button>
            </div>
            <div className='flex flex-col gap-4 mt-8'>
                <div className='flex items-center gap-4'>
                    <input
                        type="text"
                        placeholder='Pesquisa'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className='input'
                    />
                    <button className='p-2 border-[1px] border-[var(--black)] rounded-full cursor-pointer' onClick={() => setFilterOn(!filterOn)}>
                        {filterOn ? <LuFilterX /> : <LuFilter />}
                    </button>
                </div>
                {
                    <div className={`flex items-center gap-8 overflow-y-hidden overflow-x-auto transition-all duration-500 ease ${filterOn ? 'max-h-[5rem]' : 'max-h-0'}`}>
                        <div className="flex flex-col gap-2">
                            <p>Estado</p>
                            <select value={state} onChange={(e) => setState(e.target.value)} className='filter'>
                                <option value="">Selecionar</option>
                                <option value="Não responde">Não responde</option>
                                <option value="À espera de resposta">À espera de resposta</option>
                                <option value="Adjudicação/Orçamentos">Adjudicação/Orçamentos</option>
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Nível de necessidade</p>
                            <select value={levelOfNeed} onChange={(e) => setLevelOfNeed(e.target.value)} className='filter'>
                                <option value="">Selecionar</option>
                                <option value="Pouco">Pouco</option>
                                <option value="Médio">Médio</option>
                                <option value="Alto">Alto</option>
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Vezes que foi contactado</p>
                            <select value={timesSpoken} onChange={(e) => setTimesSpoken(e.target.value)} className='filter'>
                                <option value="">Selecionar</option>
                                <option value="1">1</option>
                                <option value="2-5">2-5</option>
                                <option value="5-10">5-10</option>
                                <option value="10+">10+</option>
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Ver Leads</p>
                            <select value={viewAll} onChange={(e) => setViewAll(e.target.value)} className='filter'>
                                <option value="0">Apenas as minhas</option>
                                <option value="1">Todas</option>
                            </select>
                        </div>
                    </div>
                }
            </div>
            <div className='flex flex-col items-center justify-between gap-4 my-6 seis:flex-row'>
                <div className='relative flex items-center w-[16rem] h-8 border-2 border-[var(--black)] rounded-full justify-evenly'>
                    <div className={`absolute top-0 ${view === 'list' ? 'left-0' : 'left-1/2'} h-full w-1/2 bg-[var(--stats)] rounded-full z-[-1] transition-all duration-300`}></div>
                    <p onClick={() => setView('list')} className='w-1/2 text-[.8rem] text-center cursor-pointer'>Lista</p>
                    <p onClick={() => setView('excel')} className='w-1/2 text-[.8rem] text-center cursor-pointer'>Excel</p>
                </div>
                <div className='flex flex-col gap-4 quatro:flex-row'>
                    <button className='relative small-button bg-[var(--primary)] gap-4'>
                        Importar
                        <BsDatabaseFillAdd />
                        <input
                            type="file"
                            onChange={handleImport}
                            className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
                        />
                    </button>
                    <button onClick={handleExport} className='small-button bg-[var(--primary)] gap-4'>
                        Download
                        <img src={DownloadIcon} alt="icon" className='w-6' />
                    </button>
                </div>
            </div>
            {
                view === 'list' ?
                    <div className='flex flex-col w-full gap-10 mt-6 mil:flex-row'>
                        <div className='w-full mil:w-1/2'>
                            <h2 className='text-[1.5rem]'>Leads Ativas</h2>
                            <p className='mb-8'>{leads ? `Total: ${leads?.filter((client: any) => client?.active).length}` : ""}</p>
                            <div className='flex flex-col gap-4'>
                                {
                                    (!leads || leads?.filter((client: any) => client?.active)?.length === 0) ? <p>Ainda não existem leads ativas, clique em nova lead</p> :
                                        leads?.filter((client: any) => client?.active)?.map((client: any) => (
                                            <div
                                                key={client?.id}
                                                className='big-button style2 bg-purple'
                                                onClick={() => (client?.embaixador === context?.user?.email || context?.user?.type === 'Admin') ? navigate(`/client/lead/${client?.id}`) : {}}
                                            >
                                                <div>
                                                    <p className='text-[1.2rem]'>{client?.name}</p>
                                                    <p className='text-[1rem]'>{client?.email}</p>
                                                </div>
                                                <p className='text-[.8rem] bg-[var(--stats)] text-black flex justify-center items-center rounded-full px-4 whitespace-nowrap'>{client?.createdBy === 'website' ? 'do website' : ''}</p>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                        <div className='w-full mil:w-1/2'>
                            <h2 className='text-[1.5rem]'>Leads Inativas</h2>
                            <p className='mb-8'>{leads ? `Total: ${leads?.filter((client: any) => !client?.active).length}` : ""}</p>
                            <div className='flex flex-col gap-4'>
                                {
                                    (!leads || leads?.filter((client: any) => !client?.active)?.length === 0) ? <p>Nenhuma lead inativa</p> :
                                        leads?.filter((client: any) => !client?.active)?.map((client: any) => (
                                            <div
                                                key={client?.id}
                                                className='big-button style2 bg-gray'
                                                onClick={() => (client?.embaixador === context?.user?.email || context?.user?.type === 'Admin') ? navigate(`/client/lead/${client?.id}`) : {}}
                                            >
                                                <div>
                                                    <p className='text-[1.2rem]'>{client?.name}</p>
                                                    <p className='text-[1rem]'>{client?.email}</p>
                                                </div>
                                                <p className='text-[.8rem] bg-[var(--stats)] text-black flex justify-center items-center rounded-full px-4 whitespace-nowrap'>{client?.createdBy === 'website' ? 'do website' : ''}</p>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <ExcelView
                        header={header}
                        tableData={tableData}
                        setTableData={setTableData}
                        data="clients"
                    />
            }
            {view === 'excel' && <button className="small-button bg-[var(--green)]" onClick={saveData}><p className='w-full text-center'>Guardar</p></button>}
        </div>
    );
}

