import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsCheck } from 'react-icons/bs/'
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../contexts/Context';
import { create, updateData } from '../../services/server';
import toast, { Toaster } from "react-hot-toast";

export default function CreateAgent() {
    const context = useContext(Context);
    const navigate = useNavigate();

    const { id = "" } = useParams();

    const [agent, setAgent] = useState<any>({});
    const edit = window.location.pathname.includes('edit');
    const lead = window.location.pathname.includes('lead');

    const selectRef2 = useRef<HTMLDivElement>(null);
    const selectRef3 = useRef<HTMLDivElement>(null);

    const [areas, setAreas] = useState<any>([]);
    const [techs, setTechs] = useState<any>([]);

    const [nome, setNome] = useState('');
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [github, setGithub] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [contacto, setContacto] = useState('');
    const [remarks, setRemarks] = useState('');
    const [contactado, setContactado] = useState(false);
    const [levelOfInteress, setLevelOfInteress] = useState('');
    const [answerTime, setAnswerTime] = useState('');
    const [timesSpoken, setTimesSpoken] = useState('');

    const [selectedTechs, setSelectedTechs] = useState<string[]>([]);
    const [selectedAreas, setSelectedAreas] = useState<string[]>([]);

    const [isDropdownOpen2, setIsDropdownOpen2] = useState<boolean>(false);
    const [isDropdownOpen3, setIsDropdownOpen3] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            if (edit) {
                const agent = context?.agents?.find((agent: any) => agent?.id === id);
                setAgent(agent);
            }
            setAreas(context?.settings.areas);
            setTechs(context?.settings.techs);
        };
        fetchData();
    }, [edit, context?.settings, context?.techs, context?.agents, id]);

    useEffect(() => {
        if (agent) {
            setNome(agent?.name || '');
            setEmail(agent?.email || '');
            setGithub(agent?.github || '');
            setLinkedin(agent?.linkedin || '');
            setSelectedTechs(agent?.techs || []);
            setSelectedAreas(agent?.areas || []);
            setContacto(agent?.contacto || '');
            setRemarks(agent?.remarks || '');
            setContactado(agent?.contactado || '');
            setLevelOfInteress(agent?.levelOfInteress || '');
            setAnswerTime(agent?.answerTime || '');
            setTimesSpoken(agent?.timesSpoken || '');
            setRole(agent?.role || '');
        }
    }, [agent]);

    function isValidEmail(email: string): boolean {
        const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailPattern.test(email);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!nome || !email || !role || (contactado && (!timesSpoken || !levelOfInteress || !answerTime)))
            return toast.error('Preencha todos os campos.');
        if (!isValidEmail(email)) {
            return toast.error('Email inválido.');
        }
        let active;
        if (lead)
            active = contactado;
        else {
            active = edit ? agent?.active : true;
        }
        const data = {
            type: 'Agent',
            active: active,
            lead: lead,
            name: nome.trim(),
            email: email.trim(),
            github: github.trim(),
            linkedin: linkedin.trim(),
            techs: selectedTechs,
            areas: selectedAreas,
            contacto: contacto.trim(),
            remarks: remarks.trim(),
            contactado: contactado,
            timesSpoken: timesSpoken.trim(),
            levelOfInteress: levelOfInteress.trim(),
            answerTime: answerTime.trim(),
            creationDate: edit ? agent?.creationDate : new Date().toISOString(),
            role,
            createdBy: edit ? agent?.createdBy : context?.user?.email,
        };
        if (edit)
            await updateData(toast, 'users', email, data, 'Agente atualizado com sucesso.', () => {
                const newAgents = [...context?.agents?.filter((ele: any) => ele.id !== agent?.id), { ...data, id: email }]
                context?.setAgents(newAgents);
                navigate(-1);
            });
        else
            await create(toast, 'users', email, data, 'Novo agente criado com sucesso.', () => {
                const newAgents = [...context?.agents, { ...data, id: email }]
                context?.setAgents(newAgents);
                navigate(-1);
            });

    };

    const handleTechsSelection = (newName: string) => {
        setSelectedTechs((prev: any) => {
            if (prev.includes(newName)) {
                return prev.filter((name: string) => name !== newName);
            } else {
                return [...prev, newName];
            }
        });
    };

    const handleAreasSelection = (newName: string) => {
        setSelectedAreas((prev: any) => {
            if (prev.includes(newName)) {
                return prev.filter((name: string) => name !== newName);
            } else {
                return [...prev, newName];
            }
        });
    };

    useEffect(() => {
        function handleClickOutside2(event: any) {
            if (selectRef2.current && !selectRef2.current.contains(event.target)) {
                setIsDropdownOpen2(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside2);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside2);
        };
    }, [selectRef2]);

    useEffect(() => {
        function handleClickOutside3(event: any) {
            if (selectRef3.current && !selectRef3.current.contains(event.target)) {
                setIsDropdownOpen3(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside3);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside3);
        };
    }, [selectRef3]);

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <p onClick={() => navigate(-1)} className='relative w-full mb-8 text-left cursor-pointer'>
                <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
            </p>
            <div className='flex items-center justify-between w-full mb-8'>
                {
                    edit ?
                        <h1 className='text-[1.5rem] font-bold'>Editar</h1>
                        :
                        <h1 className='text-[1.5rem] font-bold'>{lead ? 'Nova Lead' : 'Novo Agente'}</h1>
                }
            </div>
            <div className='text-[1.2rem]'>
                <div className='grid grid-cols-1 mil:grid-cols-2 text-[1rem] gap-4'>
                    <div>
                        <p className='mandatory'>Nome</p>
                        <input
                            type='text'
                            placeholder='Nome'
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                        />
                    </div>
                    <div>
                        <p className='mandatory'>Email</p>
                        <input
                            type='email'
                            placeholder='Email'
                            value={email}
                            disabled={edit}
                            onChange={(e) => setEmail(e.target.value)}
                            className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                        />
                    </div>
                    <div>
                        <p className=''>Contacto</p>
                        <input
                            type='tel'
                            placeholder='Contacto'
                            value={contacto}
                            onChange={(e) => setContacto(e.target.value)}
                            className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                        />
                    </div>
                    <div>
                        <p className=''>Áreas</p>
                        <div
                            className="relative cursor-pointer mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]"
                            onClick={() => setIsDropdownOpen2(!isDropdownOpen2)}
                            ref={selectRef2}
                        >
                            {selectedAreas.length === 0
                                ? 'Selecione áreas'
                                : `${selectedAreas.length} selecionados`}
                            {isDropdownOpen2 && (
                                <div className="absolute w-full left-0 top-10 bg-white border-2 border-[var(--black)] rounded-md z-10 h-[15rem] overflow-y-auto my-scrollbar">
                                    {areas.map((area: any, index: number) => (
                                        <div
                                            key={index}
                                            className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleAreasSelection(area);
                                            }}
                                        >
                                            <div className={`w-4 h-4 border-[1px] border-[var(--black)] rounded-[4px] ${selectedAreas.includes(area) ? 'bg-[var(--primary)]' : ''}`}>
                                                <span className={`${selectedAreas.includes(area) ? 'flex' : 'hidden'} text-white`}><BsCheck /></span>
                                            </div>
                                            <p className="ml-2 text-sm">{area}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <p className=''>Tecnologias</p>
                        <div
                            className="relative cursor-pointer mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]"
                            onClick={() => setIsDropdownOpen3(!isDropdownOpen3)}
                            ref={selectRef3}
                        >
                            {selectedTechs.length === 0
                                ? 'Selecione tecnologias'
                                : `${selectedTechs.length} selecionados`}
                            {isDropdownOpen3 && (
                                <div className="absolute w-full left-0 top-10 bg-white border-2 border-[var(--black)] rounded-md z-10 h-[15rem] overflow-y-auto my-scrollbar">
                                    {techs && techs.map((tech: string, index: number) => (
                                        <div
                                            key={index}
                                            className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleTechsSelection(tech);
                                            }}
                                        >
                                            <div className={`w-4 h-4 border-[1px] border-[var(--black)] rounded-[4px] ${selectedTechs.includes(tech) ? 'bg-[var(--primary)]' : ''}`}>
                                                <span className={`${selectedTechs.includes(tech) ? 'flex' : 'hidden'} text-white`}><BsCheck /></span>
                                            </div>
                                            <p className="ml-2 text-sm">{tech}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <p className=''>Linkedin</p>
                        <input
                            type='text'
                            placeholder='Linkedin'
                            value={linkedin}
                            onChange={(e) => setLinkedin(e.target.value)}
                            className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                        />
                    </div>
                    <div>
                        <p className='mandatory'>Função</p>
                        <input
                            type='text'
                            placeholder='Função'
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                        />
                    </div>
                    <div>
                        <p className=''>Github | Portfólio</p>
                        <input
                            type='text'
                            placeholder='Link'
                            value={github}
                            onChange={(e) => setGithub(e.target.value)}
                            className='mt-1 w-full px-4 py-2 border-none bg-lightgray text-purple rounded-[25px]'
                        />
                    </div>
                    {
                        lead &&
                        <div className="flex flex-col gap-4">
                            <p className='mandatory'>Foi Contactado</p>
                            <div className='flex gap-4'>
                                <button onClick={() => setContactado(true)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${contactado ? 'bg-green' : ''} cursor-pointer`}>
                                </button>
                                <p>Sim</p>
                                <button onClick={() => setContactado(false)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${!contactado ? 'bg-[red]' : ''} cursor-pointer`}>
                                </button>
                                <p>Não</p>
                            </div>
                        </div>
                    }
                    {
                        lead && contactado &&
                        <>
                            <div className="flex flex-col gap-2">
                                <p className='mandatory'>Vezes que foi contactado</p>
                                <select value={timesSpoken} onChange={(e) => setTimesSpoken(e.target.value)} className='input'>
                                    <option value="">Selecionar</option>
                                    <option value="1">1</option>
                                    <option value="2-5">2-5</option>
                                    <option value="5-10">5-10</option>
                                    <option value="10+">10+</option>
                                </select>
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className='mandatory'>Nível de interesse</p>
                                <select value={levelOfInteress} onChange={(e) => setLevelOfInteress(e.target.value)} className='input'>
                                    <option value="">Selecionar</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className='mandatory'>Tempo de resposta</p>
                                <select value={answerTime} onChange={(e) => setAnswerTime(e.target.value)} className='input'>
                                    <option value="">Selecionar</option>
                                    <option value="Lento">Lento</option>
                                    <option value="Médio">Médio</option>
                                    <option value="Rápido">Rápido</option>
                                </select>
                            </div>
                        </>
                    }
                    <div>
                        <p className=''>Observações</p>
                        <textarea
                            placeholder='Observações'
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                            className='resize-none my-scrollbar input h-[13rem]'
                        />
                    </div>
                </div>
                <div className='items-center justify-center w-full mt-8'>
                    <button onClick={handleSubmit} className={`small-button ${edit ? 'bg-[var(--primary)]' : 'bg-green'}`}>{edit ? 'Atualizar' : 'Criar Agente'}</button>
                </div>
            </div>
        </div>
    );
}

