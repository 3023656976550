import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { FaCalendarAlt } from "react-icons/fa";
import { ImBin } from "react-icons/im";
import AddTask from './addTask';
import AddObjetivo from './addObjetivo';
import { doc, writeBatch } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { updateData } from '../../../services/server';
import { Context } from '../../../contexts/Context';
import Loading from '../../Loading';
import toast, { Toaster } from "react-hot-toast";

export default function PartnerRoadmap() {
    const navigate = useNavigate();
    const location = useLocation();
    const context = useContext(Context);
    const { partner } = location.state?.partner ?? {};

    const [objetivos, setObjetivos] = useState<any>(null);

    const [taskSelected, setTaskSelected] = useState<any>(null);
    const [objetivoSelected, setObjetivoSelected] = useState<any>(null);

    const [objetivoAtual, setObjetivoAtual] = useState('');

    const [taskForm, setTaskForm] = useState(false);
    const [objetivoForm, setObjetivoForm] = useState(false);
    const [isPartnerManager, setIsPartnerManager] = useState(false);

    useEffect(() => {
        setIsPartnerManager(true);
    }, [partner?.partnerManager, context?.user.email]);

    useEffect(() => {
        if (partner?.objetivos) {
            const newObjetivos = partner.objetivos.sort((a: any, b: any) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime());
            setObjetivos(newObjetivos);
            setObjetivoAtual(partner?.objetivoAtual)
        } else {
            setObjetivos([]);
        }
    }, [partner?.objetivos, partner?.objetivoAtual]);

    useEffect(() => {
        if (!objetivoForm)
            setObjetivoSelected(null);
    }, [objetivoForm]);

    useEffect(() => {
        if (!taskForm) {
            setObjetivoSelected(null);
            setTaskSelected(null);
        }
    }, [taskForm]);

    async function save() {
        try {
            const batch = writeBatch(db);
            objetivos.forEach((objetivo: any, index: number) => {
                const objetivoRef = doc(db, `users/${partner.id}/objetivos`, objetivo?.id || `objetivo${index + 1}`);
                batch.set(objetivoRef, objetivo);
            });
            await batch.commit();
            await updateData(toast, 'users', partner?.email, { objetivoAtual: objetivoAtual }, 'Planeamento atualizado com sucesso!', () => {
                const newPartners = [...context?.partners.filter((c: any) => c.id !== partner?.id), { ...partner, objetivoAtual: objetivoAtual }];
                context?.setPartners(newPartners);
            });
        } catch (err) {
            console.log(err);
            toast.error('Erro ao salvar alterações.');
        }
    }

    function deleteObjetivo(index: number) {
        if (!window.confirm('Tem a certeza que deseja excluir este objetivo?')) return;
        objetivos.splice(index, 1);
    }

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    if (!partner) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full h-full p-4 quatrocentos:p-8'>
            <Toaster />
            <div className='flex flex-col justify-between w-full gap-6 mb-8 seis:items-center seis:flex-row '>
                <p onClick={() => navigate(-1)} className='relative text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
                </p>
                <div className='flex flex-col gap-4 cinco:items-center cinco:flex-row'>
                    <button onClick={() => navigate('schedule')} className='gap-4 small-button bg-[var(--stats)] text-black'>
                        <p className='text-[var(--black)]'>Ver calendário</p>
                        <FaCalendarAlt className='text-[var(--black)]' />
                    </button>
                    {
                        isPartnerManager &&
                        <div className='flex gap-4'>
                            <button onClick={() => { setObjetivoSelected(null); setObjetivoForm(true) }} className='small-button bg-[var(--primary)]'>+ Novo Objetivo</button>
                        </div>
                    }
                </div>
            </div>
            <div className='flex flex-col gap-4 oitocentos:justify-between oitocentos:items-center oitocentos:flex-row'>
                <h1 className='text-[1.5rem] font-bold'>{partner.name} - Plano de Parceria</h1>
            </div>
            <div className='flex flex-col justify-between gap-8 oitocentos:flex-row'>
                <div className='flex h-full gap-4 pb-8 mt-8 overflow-x-auto my-scrollbar'>
                    {
                        objetivos && objetivos.length !== 0 ? objetivos.map((objetivo: any, objetivoIndex: number) => {
                            return (
                                <div key={objetivoIndex} className='h-[28rem] text-white bg-[var(--sprintBg)] p-6 rounded-[15px] flex flex-col gap-4 justify-between'>
                                    <div className='flex flex-col gap-4 overflow-x-hidden overflow-y-auto my-scrollbar'>
                                        <div className='flex justify-center'>
                                            <p className='font-bold text-center uppercase whitespace-nowrap'>Objetivo {objetivoIndex + 1}</p>
                                        </div>
                                        <div className='flex justify-between gap-8 pb-4 border-b-2 border-white shrink-0'>
                                            <p className='max-w-[18rem] overflow-hidden whitespace-nowrap text-ellipsis'>{objetivo?.description}</p>
                                            <p className='font-bold text-center uppercase'>{formateDate(objetivo?.deadline)}</p>
                                        </div>
                                        <div className='flex flex-col gap-4 overflow-x-hidden overflow-y-auto my-scrollbar'>
                                            {
                                                objetivo?.tasks.length !== 0 ? objetivo?.tasks.map((task: any, taskIndex: number) => {
                                                    return (
                                                        <div onClick={() => { setObjetivoSelected({ ...objetivo, objetivoIndex }); setTaskSelected({ ...task, taskIndex }); setTaskForm(true) }} key={taskIndex} className='flex justify-between p-2 gap-8 cursor-pointer border-[transparent] border-2 hover:border-gray rounded-[10px]'>
                                                            <p className='whitespace-nowrap'>{task?.task}</p>
                                                            <p className='text-[.8rem] bg-[var(--stats)] text-black flex justify-center items-center rounded-full px-4 whitespace-nowrap'>{task?.state}</p>
                                                        </div>
                                                    )
                                                }) :
                                                    <p className='w-full text-center'>Nenhuma tarefa</p>
                                            }
                                        </div>
                                    </div>
                                    {
                                        isPartnerManager &&
                                        <div className='flex justify-end w-full gap-2'>
                                            <button onClick={() => { deleteObjetivo(objetivoIndex) }} className='p-1 rounded-full bg-[var(--red)]'><ImBin /></button>
                                            <button onClick={() => { setObjetivoSelected({ ...objetivo, objetivoIndex }); setObjetivoForm(true); }} className='p-1 rounded-full bg-[var(--primary)]'><MdOutlineModeEditOutline className='text-[var(--stats)]' /></button>
                                            <button onClick={() => { setTaskForm(false); setTaskSelected(null); setObjetivoSelected({ ...objetivo, objetivoIndex }); setTaskForm(true) }} className='p-1 rounded-full bg-green'><IoMdAdd /></button>
                                        </div>
                                    }
                                </div>
                            )
                        }) :
                            <p className='w-full text-center'>Nenhum Objetivo criado</p>
                    }
                </div>
                {
                    objetivoSelected && (taskSelected || taskForm) &&
                    <AddTask partner={partner} objetivos={objetivos} setObjetivos={setObjetivos} edit={!isPartnerManager} setTaskForm={setTaskForm} task={taskSelected} objetivo={objetivoSelected} />
                }
            </div>
            {
                isPartnerManager &&
                <div className='flex flex-col-reverse gap-4 mt-4 cinco:flex-row'>
                    <button className='small-button bg-green' onClick={save}>Salvar alterações</button>
                    <select value={objetivoAtual} onChange={(e) => setObjetivoAtual(e.target.value)} className='input !w-[10rem]'>
                        <option className='' value="">Objetivo Atual</option>
                        {
                            objetivos && objetivos.length !== 0 ? objetivos.map((_: any, index: number) => {
                                return (
                                    <option key={index} className='' value={index + 1}>Objetivo {index + 1}</option>
                                )
                            }) :
                                <p className='w-full text-center'>Nenhum resultado</p>
                        }
                    </select>
                </div>
            }
            {objetivoForm && <AddObjetivo objetivos={objetivos} setObjetivos={setObjetivos} setObjetivoForm={setObjetivoForm} objetivo={objetivoSelected} />}
        </div>
    );
}

