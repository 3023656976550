import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../../contexts/Context';
import { create, updateData } from '../../../services/server';
import { BsCheck } from 'react-icons/bs/';
import toast, { Toaster } from "react-hot-toast";

export default function EditLead() {
    const context = useContext(Context);
    const navigate = useNavigate();
    const {id = ""} = useParams();

    const edit = window.location.pathname.includes('edit');
    const [client, setClient] = useState<any>(null);
    const selectRef2 = useRef<HTMLDivElement>(null);

    const [nome, setNome] = useState('');
    const [role, setRole] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [contacto, setContacto] = useState('');
    const [email, setEmail] = useState('');
    const [contactado, setContactado] = useState(false);
    const [remarks, setRemarks] = useState('');
    const [levelOfNeed, setLevelOfNeed] = useState('');
    const [answerTime, setAnswerTime] = useState('');
    const [timesSpoken, setTimesSpoken] = useState('');
    const [state, setState] = useState('');
    const [selectedChannels, setSelectedChannels] = useState<string[]>([]);

    const [channels, setChannels] = useState<any>([]);
    const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (edit && context?.leads) {
                const client = context?.leads?.find((client: any) => client?.id === id);
                setClient(client);
            }
            setChannels(context?.settings?.channels);
        };
        fetchData();
    }, [edit, context?.settings, context?.sales, context?.user?.email, context?.leads, id]);

    useEffect(() => {
        if (client) {
            setNome(client?.name || '');
            setRole(client?.role || '');
            setResponsavel(client?.responsavel || '');
            setContacto(client?.contacto || '');
            setEmail(client?.email || '');
            setContactado(client?.contactado || '');
            setRemarks(client?.remarks || '');
            setLevelOfNeed(client?.levelOfNeed || '');
            setAnswerTime(client?.answerTime || '');
            setTimesSpoken(client?.timesSpoken || '');
            setState(client?.state || '');
            setSelectedChannels(client?.channels || []);
        }
    }, [client]);

    useEffect(() => {
        function handleClickOutside2(event: any) {
            if (selectRef2.current && !selectRef2.current.contains(event.target)) {
                setIsDropdownOpen2(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside2);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside2);
        };
    }, [selectRef2]);

    const handleChannelselection = (channel: string) => {
        setSelectedChannels((prevSelectedAgents: any) => {
            if (prevSelectedAgents.includes(channel)) {
                return prevSelectedAgents.filter((name: string) => name !== channel);
            } else {
                return [...prevSelectedAgents, channel];
            }
        });
    };

    function isValidEmail(email: string): boolean {
        const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailPattern.test(email);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!nome || !email || !levelOfNeed || selectedChannels.length === 0 || (contactado && (!timesSpoken || !state || !answerTime)))
            return toast.error('Preencha todos os campos.');
        if (!isValidEmail(email)) {
            return toast.error('Email inválido.');
        }
        const data = {
            type: 'Client',
            lead: true,
            name: nome.trim(),
            responsavel: responsavel.trim(),
            contacto: contacto.trim(),
            email: email.trim(),
            remarks: remarks.trim(),
            levelOfNeed,
            state,
            answerTime,
            contactado,
            active: contactado,
            timesSpoken,
            creationDate: edit ? client?.creationDate : new Date().toISOString(),
            role,
            channels: selectedChannels,
            embaixador: edit ? client?.embaixador : context?.user?.email,
        };
        if (edit)
            await updateData(toast, 'users', email, data, 'Lead atualizada com sucesso.', () => {
                const newLeads = [...context?.leads?.filter((lead: any) => lead.id !== client?.id), { ...data, id: email }]
                context?.setLeads(newLeads);
                navigate(-1);
            });
        else
            await create(toast, 'users', email, data, 'Lead criada com sucesso.', () => {
                const newLeads = [...context?.leads, { ...data, id: email }]
                context?.setLeads(newLeads);
                navigate(-1);
            });
    };

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <p onClick={() => navigate(-1)} className='relative w-full mb-8 text-left cursor-pointer'>
                <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
            </p>
            <div className='flex items-center justify-between w-full mb-8'>
                <h1 className='text-[1.5rem] font-bold'>{edit ? 'Editar' : 'Nova Lead'}</h1>
            </div>
            <div className='text-[1.2rem]'>
                <div className='grid grid-cols-1 mil:grid-cols-2 text-[1rem] gap-4'>
                    <div className="flex flex-col gap-2">
                        <p className='mandatory'>Nome da empresa</p>
                        <input
                            type='text'
                            placeholder='Nome da empresa'
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            className='input'
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className=''>Nome do Responsável</p>
                        <input
                            type='text'
                            placeholder='Nome do Responsável'
                            value={responsavel}
                            onChange={(e) => setResponsavel(e.target.value)}
                            className='input'
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className=''>Posição do responsável</p>
                        <input
                            type='text'
                            placeholder='Posição do responsável'
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className='input'
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className=''>Contacto</p>
                        <input
                            type='tel'
                            placeholder='Contacto'
                            value={contacto}
                            onChange={(e) => setContacto(e.target.value)}
                            className='input'
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className='mandatory'>Email</p>
                        <input
                            type='email'
                            placeholder='Email'
                            value={email}
                            disabled={edit}
                            onChange={(e) => setEmail(e.target.value)}
                            className='input'
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className='mandatory'>Nível de necessidade</p>
                        <select value={levelOfNeed} onChange={(e) => setLevelOfNeed(e.target.value)} className='input'>
                            <option value="">Selecionar</option>
                            <option value="Pouco">Pouco</option>
                            <option value="Médio">Médio</option>
                            <option value="Alto">Alto</option>
                        </select>
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className='mandatory'>Canais de contacto</p>
                        <div
                            className="relative cursor-pointer input"
                            onClick={() => setIsDropdownOpen2(!isDropdownOpen2)}
                            ref={selectRef2}
                        >
                            {selectedChannels.length === 0
                                ? 'Selecionar'
                                : `${selectedChannels.length} selecionado${selectedChannels.length > 1 ? 's' : ''}`}
                            {isDropdownOpen2 && (
                                <div className="absolute w-full left-0 top-10 bg-white border-2 border-[var(--black)] rounded-md z-10 h-[15rem] overflow-y-auto my-scrollbar">
                                    {channels.map((channel: string, index: number) => (
                                        <div
                                            key={index}
                                            className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleChannelselection(channel);
                                            }}
                                        >
                                            <div className={`w-4 h-4 border-[1px] border-[var(--black)] rounded-[4px] ${selectedChannels.includes(channel) ? 'bg-[var(--primary)]' : ''}`}>
                                                <span className={`${selectedChannels.includes(channel) ? 'flex' : 'hidden'} text-white`}><BsCheck /></span>
                                            </div>
                                            <p className="ml-2 text-sm">{channel}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className='ext-gray-700 '>Embaixador</p>
                        <p className='input'>{context?.user?.name}</p>
                    </div>
                    <div className="flex flex-col gap-4">
                        <p className='mandatory'>Foi Contactado</p>
                        <div className='flex gap-4'>
                            <button onClick={() => setContactado(true)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${contactado ? 'bg-green' : ''} cursor-pointer`}>
                            </button>
                            <p>Sim</p>
                            <button onClick={() => setContactado(false)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${!contactado ? 'bg-[red]' : ''} cursor-pointer`}>
                            </button>
                            <p>Não</p>
                        </div>
                    </div>
                    {
                        contactado &&
                        <>
                            <div className="flex flex-col gap-2">
                                <p className='mandatory'>Vezes que foi contactado</p>
                                <select value={timesSpoken} onChange={(e) => setTimesSpoken(e.target.value)} className='input'>
                                    <option value="">Selecionar</option>
                                    <option value="1">1</option>
                                    <option value="2-5">2-5</option>
                                    <option value="5-10">5-10</option>
                                    <option value="10+">10+</option>
                                </select>
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className='mandatory'>Estado</p>
                                <select value={state} onChange={(e) => setState(e.target.value)} className='input'>
                                    <option value="">Selecionar</option>
                                    <option value="Não responde">Não responde</option>
                                    <option value="À espera de resposta">À espera de resposta</option>
                                    <option value="Adjudicação/Orçamentos">Adjudicação/Orçamentos</option>
                                </select>
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className='mandatory'>Tempo de resposta</p>
                                <select value={answerTime} onChange={(e) => setAnswerTime(e.target.value)} className='input'>
                                    <option value="">Selecionar</option>
                                    <option value="Lento">Lento</option>
                                    <option value="Médio">Médio</option>
                                    <option value="Rápido">Rápido</option>
                                </select>
                            </div>
                        </>
                    }
                    <div className="flex flex-col gap-2">
                        <p className=''>Observações</p>
                        <textarea
                            placeholder='Observações'
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                            className='resize-none my-scrollbar input h-[13.5rem]'
                        ></textarea>
                    </div>
                </div>
                <div className='items-center justify-center w-full mt-8'>
                    <button onClick={handleSubmit} className='small-button bg-green'>{edit ? 'Atualizar' : 'Criar Lead'}</button>
                </div>
            </div>
        </div>
    );
}

