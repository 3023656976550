import React, { useEffect, useState } from 'react';
import { ImBin } from 'react-icons/im';
import { IoMdClose } from 'react-icons/io';
import toast, { Toaster } from "react-hot-toast";

export default function AddTask({ ...props }: any) {

    const [taskName, setTaskName] = useState('');
    const [state, setState] = useState('não começou');
    const [startDate, setStartDate] = useState('');
    const [deadline, setDeadline] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (props?.task) {
            setTaskName(props?.task.task);
            setStartDate(props?.task.startDate);
            setDeadline(props?.task.deadline);
            setState(props?.task.state);
            setDescription(props?.task.description);
        }
    }, [props?.task]);

    async function addTask() {
        if (!taskName || !deadline || !state)
            return toast.error('Preencha todos os campos.');
        const task = {
            task: taskName,
            state: state,
            startDate: startDate || new Date().toISOString(),
            deadline: deadline,
            description: description,
        };
        props?.setObjetivos((prevObjetivos: any) => {
            let newObjetivos = [...prevObjetivos];
            newObjetivos[props?.objetivo?.objetivoIndex].tasks.push(task);
            return newObjetivos;
        });
        props?.setTaskForm(false);
    }

    function editTask() {
        if (!taskName || !deadline || !state)
            return toast.error('Preencha todos os campos.');
        const task = {
            task: taskName,
            state: state,
            startDate: startDate || new Date().toISOString(),
            deadline: deadline,
            description: description,
        };
        props.objetivos[props?.objetivo.objetivoIndex].tasks[props?.task.taskIndex] = task;
        props?.setTaskForm(false);
    }

    function deleteTask() {
        if (!window.confirm('Tem a certeza que deseja excluir esta tarefa?')) return;
        const newTasks = props.objetivos[props?.objetivo.objetivoIndex].tasks;
        newTasks.splice(props?.task.taskIndex, 1);
        const newObjetivos = props?.objetivos;
        newObjetivos[props?.objetivo.objetivoIndex].tasks = newTasks;
        props?.setObjetivos(newObjetivos);
        props?.setTaskForm(false);
    }

    return (
        <div className='w-full oitocentos:w-[30rem] oitocentos:pl-8 mt-4 oitocentos:border-l-2 border-[var(--black)] my-scrollbar overflow-y-auto my-scrollbar'>
            <Toaster />
            <div className='flex flex-col gap-4 oitocentos:pr-4 h-full oitocentos:h-[30rem]'>
                <div className='flex justify-end w-full'>
                    <button onClick={() => { props?.setTaskForm(false); }} className='p-1 text-white rounded-full bg-[var(--primary)]'><IoMdClose /></button>
                </div>
                {
                    props?.task ?
                        <h1 className='text-[1.2rem] font-bold'>Tarefa</h1>
                        :
                        <h1 className='text-[1.2rem] font-bold'>Nova Tarefa</h1>
                }
                <div className='flex flex-col gap-2'>
                    <p className='mandatory'>Tarefa:</p>
                    <input className='input' disabled={props?.edit} type='text' value={taskName} onChange={(e) => setTaskName(e.target.value)} placeholder='Tarefa' />
                    <p className='mandatory'>Estado:</p>
                    <select disabled={props?.edit} value={state} onChange={(e) => setState(e.target.value)} className='input'>
                        <option value="">Selecionar</option>
                        <option value="não começou">Não começou</option>
                        <option value="em progresso">Em Progresso</option>
                        <option value="revisão">Revisão</option>
                        <option value="terminada">Terminada</option>
                    </select>
                    <p className=''>Start Date:</p>
                    <input className='input' disabled={props?.edit} type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)} placeholder='Start date' />
                    <p className='mandatory'>Deadline:</p>
                    <input className='input' disabled={props?.edit} type='date' value={deadline} onChange={(e) => setDeadline(e.target.value)} placeholder='Deadline' />
                    <p className=''>Descrição:</p>
                    <textarea className='input h-[14rem] resize-none my-scrollbar' disabled={props?.edit} value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Descrição' />
                </div>
                {
                    !props?.edit &&
                    <div className='flex justify-end w-full gap-2'>
                        {
                            props?.task ?
                                <>
                                    <button onClick={() => { editTask() }} className='px-4 rounded-full bg-[var(--primary)] text-white'>atualizar</button>
                                    <button onClick={() => { deleteTask() }} className='p-1 rounded-full bg-[var(--red)]'><ImBin className='text-white' /></button>
                                </>
                                :
                                <button onClick={() => { addTask() }} className='px-4 text-white rounded-full bg-green'>Adicionar</button>
                        }
                    </div>
                }
            </div>
        </div>
    );
}

