import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import clientsList from './checklists/clients.json';
import agentsList from './checklists/agents.json';
import partnersList from './checklists/partners.json';
import clientsleadsList from './checklists/clientsleads.json';
import agentsleadsList from './checklists/agentsleads.json';
import partnersleadsList from './checklists/partnersleads.json';
import projectsList from './checklists/projects.json';

export default function ChecklistAll() {
    const navigate = useNavigate();
    const { type = "" } = useParams();
    const lead = window.location.href.includes('leads') ? 'leads' : '';
    const [checklist, setChecklist] = useState<any>(null);
    
    useEffect(() => {
        const listsDict:any = {
            'clients': clientsList,
            'clientsleads': clientsleadsList,
            'agents': agentsList,
            'agentsleads': agentsleadsList,
            'partners': partnersList,
            'partnersleads': partnersleadsList,
            'projects': projectsList,
        }
        
        if (type) {
            setChecklist(listsDict[`${type}${lead}`]);
        }
    }, [type, lead]);

    return (
        <div className='flex flex-col w-full min-h-screen gap-8 p-8'>
            <Toaster />
            <div className="flex items-center justify-between">
                <p onClick={() => navigate(-1)} className='relative w-full text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
                </p>
            </div>
            <div>
                <h1 className='text-[1.5rem] mb-8 border-b-2 border-black pb-2 uppercase'>TO DO<span className='lowercase'>s</span>: {type} {lead}</h1>
            </div>
            <ul className='flex flex-col gap-4 pl-8 list-disc'>
                {
                    checklist && checklist.length !== 0 ? checklist.map((ele: any, index: number) => {
                        return (
                            <li key={index} className=''>
                                <p className='mb-2 font-bold'>{ele.title}</p>
                                <ul className={`flex flex-col gap-2 pl-2 ${ele.tasks.length === 1 ? '' : 'list-decimal'} quatro:pl-8`}>
                                    {
                                        ele.tasks.length !== 0 ? ele.tasks.map((ele: string, index: number) => {
                                            return (
                                                <li key={index} className=''>
                                                    {ele}
                                                </li>
                                            )
                                        }) :
                                            <p className='w-full text-center'>Nenhum resultado</p>
                                    }
                                </ul>
                            </li>
                        )
                    }) :
                        <p className='w-full text-center'>Nenhum resultado</p>
                }
            </ul>
        </div>
    );
}

