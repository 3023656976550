import React, { useState } from 'react';

import Logo from '../content/images/icons/logo-visiond.png';
import { auth } from '../services/firebase';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";
import { create } from '../services/server';

export default function SignUp() {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [lang, setLang] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const signup = async () => {
        if (email === '' || password === '' || name === '' || phoneNumber === '' || lang === '') {
            toast.error('Please fill in all the inputs.');
            return;
        }
        await createUserWithEmailAndPassword(auth, email, password)
            .then(async () => {
                await create(toast, 'users', email, { email, contacto: phoneNumber, name, type: "inactive", lang }, '', () => {
                    navigate('/home');
                });
            })
            .catch(() => {
                toast.error('Invalid Email or Password.');
                return;
            });
    }


    return (
        <div className='flex flex-col items-center justify-center w-full min-h-screen text-white bg-darkgray'>
            <Toaster />
            <img src={Logo} alt="logo" className='w-[10rem] mb-4' />
            <h2 className='text-[1.2rem] text-[var(--stats)] font-bold mb-8'>WELCOME</h2>
            <div className='flex flex-col gap-6'>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} className='input' placeholder='| Name' />
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className='input' placeholder='| Email' />
                <input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className='input' placeholder='| Phone Number' />
                <select
                    className='input'
                    value={lang}
                    onChange={(e) => setLang(e.target.value)}
                >
                    <option value="">Language</option>
                    <option value="pt">Português</option>
                    <option value="en">English</option>
                </select>
                <div className='relative'>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder='| Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='appearance-none input'
                    />
                    <div className='absolute cursor-pointer top-3 right-3 invert' onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                </div>
                <button onClick={signup} className='small-button bg-[var(--primary)]'><p className='w-full text-center'>Sign Up</p></button>
                <p className='w-full text-center'>Or&ensp; <button onClick={() => navigate('/')} className='text-[var(--blue)] hover:underline'>Login</button></p>
            </div>
        </div>
    );
}

