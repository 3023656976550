// src/components/ProjectCalendar.tsx
import React, { useEffect, useState } from "react";
import {
  fullMonthWidth,
  widthFromBeginningOfMonthToDeadline,
  widthFromStartToDeadline,
  widthFromStartToEndOfMonth,
} from "./handleDates";

interface ProjectCalendarProps {
  sprints: any;
}

const ProjectCalendar: React.FC<ProjectCalendarProps> = ({ sprints }) => {
  const [uniqueMonths, setUniqueMonths] = useState<any>();

  useEffect(() => {
    setUniqueMonths(
      Array.from(
        new Set(
          sprints.flatMap((sprint: any) => {
            const deadlineDate = new Date(sprint.deadline);
            const startDateDate = new Date(sprint.startDate);
            return [
              `${(startDateDate.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${startDateDate.getFullYear()}`,
              `${(deadlineDate.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${deadlineDate.getFullYear()}`,
            ];
          })
        )
      ).sort((a: any, b: any) => {
        const [am, ay] = a.split("/").map(Number);
        const [bm, by] = b.split("/").map(Number);
        return ay - by || am - bm;
      })
    );
  }, [sprints]);

  const validSprint = (sprint: any, month: string) => {
    const startDate = new Date(sprint.startDate);
    const endDate = new Date(sprint.deadline);
    const [monthNumber, year] = month.split("/").map(Number);

    const firstDayOfMonth = new Date(year, monthNumber - 1, 1);
    const lastDayOfMonth = new Date(year, monthNumber, 0);

    return startDate <= lastDayOfMonth && endDate >= firstDayOfMonth;
  };

  const checkMonthGap = (sprint: any, month: string) => {
    const startDate = new Date(sprint.startDate);
    const endDate = new Date(sprint.deadline);
    const [monthNumber, year] = month.split("/").map(Number);

    const monthStart = new Date(year, monthNumber - 1, 1);
    const monthEnd = new Date(year, monthNumber, 0);

    // Case: Deadline and Start Date not in this month
    if (startDate < monthStart && endDate > monthEnd) {
      return 1;
    }

    // Case: Start Date in this month but not Deadline
    if (
      startDate >= monthStart &&
      startDate <= monthEnd &&
      endDate > monthEnd
    ) {
      return 2;
    }

    // Case: Deadline is in this month but not Start Date
    if (
      endDate >= monthStart &&
      endDate <= monthEnd &&
      startDate < monthStart
    ) {
      return 3;
    }

    // Case: Both Deadline and StartDate are in this month
    if (startDate >= monthStart && endDate <= monthEnd) {
      return 4;
    }

    return "0%"; // Default case
  };

  const getSprintWidth = (sprint: any, month: string) => {
    const startDate = new Date(sprint.startDate);
    const endDate = new Date(sprint.deadline);

    // Case: Deadline and Start Date not in this month
    if (checkMonthGap(sprint, month) === 1) {
      return fullMonthWidth();
    }

    // Case: Start Date in this month but not Deadline
    if (checkMonthGap(sprint, month) === 2) {
      return widthFromStartToEndOfMonth(startDate, month);
    }

    // Case: Deadline is in this month but not Start Date
    if (checkMonthGap(sprint, month) === 3) {
      return widthFromBeginningOfMonthToDeadline(endDate, month);
    }

    // Case: Both Deadline and StartDate are in this month
    if (checkMonthGap(sprint, month) === 4) {
      return widthFromStartToDeadline(startDate, endDate, month);
    }

    return "0%"; // Default case
  };

  function formateDate(date: string) {
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, "0")}/${(d.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${d.getFullYear().toString().padStart(2, "0")}`;
  }

  function getDealine(date: string) {
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, "0")}/${(d.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${d.getFullYear().toString().padStart(2, "0")}`;
  }

  function getStartPosition(sprint: any, month: string) {
    const startDate:any = new Date(sprint.startDate);
    const [monthNumber, year] = month.split("/").map(Number);

    const monthStart:any = new Date(year, monthNumber - 1, 1);
    const monthEnd = new Date(year, monthNumber, 0);

    const diff = Math.floor((startDate - monthStart) / (1000 * 60 * 60 * 24));
    const percentage = (diff / (monthEnd.getDate() - 1)) * 100;
    return `${percentage}%`;
  }

  if (!uniqueMonths) {
    return <p>Carregando...</p>;
  }

  return (
    <div className="p-4 pb-20 mt-10 overflow-x-auto my-scrollbar">
      <div className="flex mb-4">
        <div className="w-[7rem]">
          <p className="font-bold text-[1.2rem] text-center pb-10 border-b-2 border-black">
            &ensp;
          </p>
          {sprints && sprints.length !== 0 ? (
            sprints.map((ele: any, index: number) => {
              return (
                <div
                  key={index}
                  className="min-w-[5rem] h-[5rem] flex items-center justify-center"
                >
                  <p className="font-bold text-[1.2rem]">
                    {ele?.index || index + 1}
                  </p>
                </div>
              );
            })
          ) : (
            <p className="w-full text-center">Nenhum resultado</p>
          )}
        </div>
        {uniqueMonths.map((month: any, index: number) => (
          <div
            key={index}
            className="min-w-[25rem] border-x-[2px] border-black flex flex-col"
          >
            <p className="font-bold text-[1.2rem] text-center pb-10 border-b-2 border-black">
              {month}
            </p>
            {sprints && sprints.length !== 0 ? (
              sprints.map((ele: any, index: number) => {
                if (validSprint(ele, month)) {
                  return (
                    <div
                      key={index}
                      className="relative h-[5rem] py-4 flex"
                      style={{
                        left: checkMonthGap(ele, month) === 4 ? getStartPosition(ele, month) : 'auto',
                        justifyContent:
                          checkMonthGap(ele, month) === 2
                            ? "end"
                            : "start"
                      }}
                    >
                      <div
                        className="relative flex items-center justify-center h-full hover:bg-red-500 bg-[var(--primary)] group"
                        style={{ width: getSprintWidth(ele, month) }}
                      >
                        <div className="absolute w-[18rem] px-8 py-2 border-2 border-black bg-[#eee] rounded-[15px] text-center group-hover:flex hidden left-1/2 translate-x-[-50%] bottom-[-5.5rem] z-[10] flex-col">
                            <div className="w-2 h-2 bg-black absolute top-[-5px] rotate-45 left-1/2 translate-x-[-50%]"></div>
                            <p className="font-bold">{ele.description}</p>
                            <p className="">
                            Start Date : {formateDate(ele?.startDate)}
                            </p>
                            <p className="">
                            Deadline : {getDealine(ele?.deadline)}
                            </p>
                        </div>
                      </div>
                    </div>
                  );
                }
                return (
                  <div
                    key={index}
                    className="h-[5rem] flex items-center justify-center"
                  >
                    &ensp;
                  </div>
                );
              })
            ) : (
              <p className="w-full text-center">Nenhum resultado</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectCalendar;
