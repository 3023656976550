import React, { useContext, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import clientsList from './checklists/clients.json';
import agentsList from './checklists/agents.json';
import partnersList from './checklists/partners.json';
import clientsleadsList from './checklists/clientsleads.json';
import agentsleadsList from './checklists/agentsleads.json';
import partnersleadsList from './checklists/partnersleads.json';
import projectsList from './checklists/projects.json';

import { Context } from '../../contexts/Context';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { updateData } from '../../services/server';

export default function Checklist() {
    const navigate = useNavigate();
    const context = useContext(Context);
    const { type = "", id = "" } = useParams();
    const lead = window.location.pathname.includes('lead') ? 'lead' : '';

    const [checklist, setChecklist] = useState<any>(null);
    const [checked, setChecked] = useState<number[]>([]);

    useEffect(() => {
        const listsDict: any = {
            'client': clientsList,
            'clientlead': clientsleadsList,
            'agent': agentsList,
            'agentlead': agentsleadsList,
            'partner': partnersList,
            'partnerlead': partnersleadsList,
            'project': projectsList,
        }

        if (type) {
            setChecklist(listsDict[`${type}${lead}`]);
        }
        if (context?.allUsers && context?.allUsers.length !== 0) {
            const user = context?.allUsers.find((ele: any) => ele.id === id);
            if (user && user.checklist && user.checklist.length !== 0) {
                setChecked(user.checklist);
            }
        }
    }, [type, lead, id, context?.allUsers]);

    const checkItem = (index: number) => {
        if (checked.includes(index)) {
            const newChecked = checked.filter((ele: number) => ele !== index);
            setChecked(newChecked);
        } else {
            const newChecked = [...checked, index];
            setChecked(newChecked);
        }
    }

    const save = async () => {
        if (context?.allUsers && context?.allUsers.length !== 0) {
            const user = context?.allUsers.find((ele: any) => ele.id === id);
            await updateData(toast, 'users', id, { checklist: checked }, 'Checklist atualizada com sucesso.', () => {
                const newUsers = [...context?.allUsers?.filter((ele: any) => ele.id !== id), { ...user, checklist: checked }]
                context?.setAllUsers(newUsers);
                navigate(-1);
            });
        }
    }

    return (
        <div className='flex flex-col w-full h-full min-h-screen gap-8 p-8'>
            <Toaster />
            <div className="flex items-center justify-between">
                <p onClick={() => navigate(-1)} className='relative w-full text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
                </p>
            </div>
            <div>
                <h1 className='text-[1.5rem] mb-8 border-b-2 border-black pb-2 uppercase'>TO DO<span className='lowercase'>s</span></h1>
            </div>
            <div className='flex flex-col justify-between h-full'>
                <ul className='flex flex-col gap-4 pl-8'>
                    {
                        checklist && checklist.length !== 0 ? checklist.map((ele: any, index: number) => {
                            return (
                                <li key={index} className=''>
                                    <div className='flex items-center gap-4 mb-2'>
                                        <button onClick={() => checkItem(index)} className={`w-4 h-4 rounded-[4px] ${checked.includes(index) ? 'text-green' : ''} cursor-pointer`}>
                                            {checked.includes(index) ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                                        </button>
                                        <p className='font-bold'>{ele.title}</p>
                                    </div>
                                    <ul className={`flex flex-col gap-2 pl-2 ${ele.tasks.length === 1 ? '' : 'list-decimal'} quatro:pl-8`}>
                                        {
                                            ele.tasks.length !== 0 ? ele.tasks.map((ele: string, index: number) => {
                                                return (
                                                    <li key={index} className=''>
                                                        {ele}
                                                    </li>
                                                )
                                            }) :
                                                <p className='w-full text-center'>Nenhum resultado</p>
                                        }
                                    </ul>
                                </li>
                            )
                        }) :
                            <p className='w-full text-center'>Nenhum resultado</p>
                    }
                </ul>
                <div>
                    <button onClick={save} className={`small-button bg-green`}>Salvar</button>
                </div>
            </div>
        </div>
    );
}

