import React, { useEffect, useState } from 'react';
import toast, { Toaster } from "react-hot-toast";

export default function AddSprint({ ...props }: any) {
    const [id, setId] = useState<any>(null);
    const [newSprintDescription, setNewSprintDescription] = useState<any>(null);
    const [newSprintDeadline, setNewSprintDeadline] = useState<any>(null);
    const [newSprintStartDate, setNewSprintStartDate] = useState<any>(null);
    const [tasks, setTasks] = useState<any>(null);

    useEffect(() => {
        if (props?.sprint && props?.sprints) {
            setId(props?.sprint?.id);
            setNewSprintDescription(props?.sprint?.description);
            setNewSprintDeadline(props?.sprint?.deadline);
            setTasks(props?.sprint?.tasks);
        } else {
            setId(props?.sprints.length + 1);
            setTasks([]);
        }
    }, [props?.sprint, props?.sprints]);

    function addSprint() {
        if (!newSprintDeadline || !newSprintDescription)
            return toast.error('Preencha todos os campos.');
        const sprint = {
            id: `sprint${id}`,
            deadline: newSprintDeadline,
            description: newSprintDescription,
            tasks: tasks,
            startDate: newSprintStartDate || new Date().toISOString(),
        };
        props?.sprints.push(sprint);
        props.sprint = null;
        props?.setSprintForm(false);
    }

    function editSprint() {
        if (!newSprintDeadline || !newSprintDescription)
            return toast.error('Preencha todos os campos.');
        const sprint = {
            id: id,
            deadline: newSprintDeadline,
            description: newSprintDescription,
            tasks: tasks,
            startDate: newSprintStartDate || new Date().toISOString(),
        };
        props.sprints[props?.sprint.sprintIndex] = sprint;
        props?.setSprintForm(false);
    }


    return (
        <div className='fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50'>
            <Toaster />
            <div className='bg-white p-8 rounded-[15px] gap-4 flex flex-col'>
                <h1 className='font-bold text-center'>{props?.sprint ? props?.sprint.id : 'Novo Sprint'}</h1>
                <div className='flex flex-col gap-4'>
                    <p className='mandatory'>Descrição:</p>
                    <input className='input' type='text' value={newSprintDescription} onChange={(e) => setNewSprintDescription(e.target.value)} placeholder='Descrição' />
                    <p className='mandatory'>Deadline:</p>
                    <input className='input' type='date' value={newSprintDeadline} onChange={(e) => setNewSprintDeadline(e.target.value)} placeholder='Deadline' />
                    <p>Start Date:</p>
                    <input className='input' type='date' value={newSprintStartDate} onChange={(e) => setNewSprintStartDate(e.target.value)} placeholder='Deadline' />
                    {
                        props?.sprint ?
                            <button onClick={editSprint} className='bg-[var(--primary)] small-button'><p className='w-full text-center'>Atualizar</p></button>
                            :
                            <button onClick={addSprint} className='bg-[var(--primary)] small-button'><p className='w-full text-center'>Adicionar</p></button>
                    }
                </div>
                <button onClick={() => { props.sprint = null; props?.setSprintForm(false); }} className='bg-[var(--red)] small-button'><p className='w-full text-center'>Cancelar</p></button>
            </div>
        </div>
    );
}

