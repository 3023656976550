import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../contexts/Context';

export default function SalesProfile({ user, setUser }: { user: any, setUser: any }) {
    const navigate = useNavigate();
    const context = useContext(Context);
    const [clients, setClients] = useState<any>([]);
    const [leads, setLeads] = useState<any>([]);

    useEffect(() => {
        if (context?.clients && context?.leads && user?.email) {
            const allClients = context?.clients;
            if (!allClients) return;
            const myClients = allClients.filter((ele: any) => ele?.embaixador === user?.email);
            setClients(myClients);
            const allLeads = context?.leads;
            if (!allLeads) return;
            const myLeads = allLeads.filter((ele: any) => ele?.embaixador === user?.email);
            setLeads(myLeads);
        }
    }, [context?.clients, context?.leads, user?.email]);

    return (
        <div className='flex flex-col w-full gap-8 pb-8 mt-8'>
            <p className='font-bold'>Clientes</p>
            <div className='flex flex-col w-full gap-8 oitocentos:flex-row'>
                <div className='flex flex-col w-full gap-8'>
                    <h2 className='text-[1.2rem]'>Ativos</h2>
                    <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                        {
                            (!clients || clients.length === 0) ? <p>Nenhum cliente ativo</p> :
                                clients.map((client: any, index: number) => (
                                    <div key={index} className='big-button style1 bg-[var(--primary)]' onClick={() => navigate(`/client/${client?.id}`)}>
                                        <p>{client?.name}</p>
                                    </div>
                                ))
                        }
                    </div>
                </div>
                <div className='flex flex-col w-full gap-8'>
                    <h2 className='text-[1.2rem]'>Leads</h2>
                    <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                        {
                            (!leads || leads.length === 0) ? <p>Nenhuma lead</p> :
                                leads.map((client: any, index: number) => (
                                    <div key={index} className='big-button style1 bg-[var(--primary)]' onClick={() => navigate(`/client/${client?.id}/lead`)}>
                                        <p>{client?.name}</p>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

