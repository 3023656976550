import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsCheck } from 'react-icons/bs/';

import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../contexts/Context';
import { updateData, create } from '../../services/server';
import Loading from '../Loading';
import toast, { Toaster } from "react-hot-toast";

export default function EditPartner() {
    const context = useContext(Context);
    const navigate = useNavigate();

    const edit = window.location.pathname.includes('edit');
    const { id = "" } = useParams();

    const [partner, setPartner] = useState<any>({});

    const selectRef2 = useRef<HTMLDivElement>(null);

    const [nome, setNome] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [contacto, setContacto] = useState('');
    const [email, setEmail] = useState('');
    const [remarks, setRemarks] = useState('');
    const [websiteLink, setWebsiteLink] = useState('');
    const [partnerType, setPartnerType] = useState('');
    const [contactado, setContactado] = useState(false);
    const [levelOfInteress, setLevelOfInteress] = useState('');
    const [answerTime, setAnswerTime] = useState('');
    const [timesSpoken, setTimesSpoken] = useState('');

    const [rhAgents, setRhAgents] = useState<any>([]);
    const [channels, setChannels] = useState<any>([]);
    const [partnerTypes, setPartnerTypes] = useState<any>([]);

    const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
    const [isDropdownOpen2, setIsDropdownOpen2] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            if (edit) {
                const partner = context?.partners?.find((partner: any) => partner?.id === id);
                setPartner(partner);
            }
            setRhAgents(context?.rh);
            setChannels(context?.settings?.channels);
            setPartnerTypes(context?.settings?.partnerTypes);
        };
        fetchData();
    }, [edit, context?.settings, context?.sales, context?.rh, context?.partners, id]);

    useEffect(() => {
        if (partner) {
            setNome(partner?.name || '');
            setResponsavel(partner?.responsavel || '');
            setContacto(partner?.contacto || '');
            setEmail(partner?.email || '');
            setSelectedChannels(partner?.channels || []);
            setRemarks(partner?.remarks || '');
            setWebsiteLink(partner?.websiteLink || '');
            setContactado(partner?.contactado || '');
            setLevelOfInteress(partner?.levelOfInteress || '');
            setAnswerTime(partner?.answerTime || '');
            setTimesSpoken(partner?.timesSpoken || '');
            setPartnerType(partner?.partnerType || '');
        }
    }, [partner]);

    function isValidEmail(email: string): boolean {
        const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailPattern.test(email);
    }

    const handleSubmit = async (e: any) => {
        if (!nome || !email || selectedChannels.length === 0 || !partnerType || (contactado && (!levelOfInteress || !answerTime || !timesSpoken)))
            return toast.error('Preencha todos os campos.');
        if (!isValidEmail(email)) {
            return toast.error('Email inválido.');
        }
        const data = {
            type: 'Partner',
            lead: true,
            name: nome.trim(),
            responsavel: responsavel.trim(),
            contacto: contacto.trim(),
            email: email.trim(),
            channels: selectedChannels,
            remarks: remarks.trim(),
            active: true,
            websiteLink,
            partnerType,
            contactado,
            levelOfInteress,
            answerTime,
            timesSpoken,
            creationDate: edit ? partner?.creationDate : new Date().toISOString(),
            createdBy: edit ? partner?.createdBy : context?.user?.email,
        };
        if (edit)
            await updateData(toast, 'users', email, data, 'Lead atualizada com sucesso.', () => {
                const newPartners = [...context?.partners?.filter((ele: any) => ele.id !== partner?.id), { ...data, id: email }]
                context?.setPartners(newPartners);
                navigate(-1);
            });
        else
            await create(toast, 'users', email, data, 'Lead criada com sucesso.', () => {
                const newPartners = [...context?.partners, { ...data, id: email }]
                context?.setPartners(newPartners);
                navigate(-1);
            });
    };

    useEffect(() => {
        function handleClickOutside2(event: any) {
            if (selectRef2.current && !selectRef2.current.contains(event.target)) {
                setIsDropdownOpen2(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside2);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside2);
        };
    }, [selectRef2]);

    const handleChannelselection = (agentName: string) => {
        setSelectedChannels((prevSelectedAgents: any) => {
            if (prevSelectedAgents.includes(agentName)) {
                return prevSelectedAgents.filter((name: string) => name !== agentName);
            } else {
                return [...prevSelectedAgents, agentName];
            }
        });
    };

    if (!rhAgents) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <p onClick={() => navigate(-1)} className='relative w-full mb-8 text-left cursor-pointer'>
                <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
            </p>
            <div className='flex items-center justify-between w-full mb-8'>
                <h1 className='text-[1.5rem] font-bold'>{edit ? 'Editar' : 'Nova Lead'}</h1>
            </div>
            <div className='text-[1.2rem]'>
                <div className='flex flex-col mil:flex-row text-[1rem] gap-4'>
                    <div className='w-full space-y-4'>
                        <div className="flex flex-col gap-2">
                            <p className='mandatory'>Nome da empresa</p>
                            <input
                                type='text'
                                placeholder='Nome da empresa'
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className=''>Nome do responsável</p>
                            <input
                                type='text'
                                placeholder='Nome do responsável'
                                value={responsavel}
                                onChange={(e) => setResponsavel(e.target.value)}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className=''>Contacto</p>
                            <input
                                type='tel'
                                placeholder='Contacto'
                                value={contacto}
                                onChange={(e) => setContacto(e.target.value)}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className='mandatory'>Email</p>
                            <input
                                type='email'
                                placeholder='Email'
                                value={email}
                                disabled={edit}
                                onChange={(e) => setEmail(e.target.value)}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className=''>Observações</p>
                            <textarea
                                placeholder='Observações da parceria'
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                                className='resize-none my-scrollbar input h-[13.5rem]'
                            ></textarea>
                        </div>
                    </div>
                    <div className='w-full space-y-4'>
                        <div className="flex flex-col gap-2">
                            <p className='mandatory'>Tipo de Parceiro</p>
                            <select
                                value={partnerType}
                                onChange={(e) => setPartnerType(e.target.value)}
                                className='input'
                            >
                                <option value="">Selecionar</option>
                                {
                                    partnerTypes && partnerTypes.map((type: string) => {
                                        return (
                                            <option key={type} value={type}>{type}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className='mandatory'>Canais de contacto</p>
                            <div
                                className="relative cursor-pointer input"
                                onClick={() => setIsDropdownOpen2(!isDropdownOpen2)}
                                ref={selectRef2}
                            >
                                {selectedChannels.length === 0
                                    ? 'Selecione serviços'
                                    : `${selectedChannels.length} selecionados`}
                                {isDropdownOpen2 && (
                                    <div className="absolute w-full left-0 top-10 bg-white border-2 border-[var(--black)] rounded-md z-10 h-[15rem] overflow-y-auto my-scrollbar">
                                        {channels.map((agent: string, index: number) => (
                                            <div
                                                key={index}
                                                className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleChannelselection(agent);
                                                }}
                                            >
                                                <div className={`w-4 h-4 border-[1px] border-[var(--black)] rounded-[4px] ${selectedChannels.includes(agent) ? 'bg-[var(--primary)]' : ''}`}>
                                                    <span className={`${selectedChannels.includes(agent) ? 'flex' : 'hidden'} text-white`}><BsCheck /></span>
                                                </div>
                                                <p className="ml-2 text-sm">{agent}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className='ext-gray-700 '>Website Link</p>
                            <input
                                type='text'
                                placeholder='Website Link'
                                value={websiteLink}
                                onChange={(e) => setWebsiteLink(e.target.value)}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-4">
                            <p className='mandatory'>Foi Contactado</p>
                            <div className='flex gap-4'>
                                <button onClick={() => setContactado(true)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${contactado ? 'bg-green' : ''} cursor-pointer`}>
                                </button>
                                <p>Sim</p>
                                <button onClick={() => setContactado(false)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${!contactado ? 'bg-[red]' : ''} cursor-pointer`}>
                                </button>
                                <p>Não</p>
                            </div>
                        </div>
                        {
                            contactado &&
                            <>
                                <div className="flex flex-col gap-2">
                                    <p className='mandatory'>Vezes que foi contactado</p>
                                    <select value={timesSpoken} onChange={(e) => setTimesSpoken(e.target.value)} className='input'>
                                        <option value="">Selecionar</option>
                                        <option value="1">1</option>
                                        <option value="2-5">2-5</option>
                                        <option value="5-10">5-10</option>
                                        <option value="10+">10+</option>
                                    </select>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <p className='mandatory'>Nível de interesse</p>
                                    <select value={levelOfInteress} onChange={(e) => setLevelOfInteress(e.target.value)} className='input'>
                                        <option value="">Selecionar</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <p className='mandatory'>Tempo de resposta</p>
                                    <select value={answerTime} onChange={(e) => setAnswerTime(e.target.value)} className='input'>
                                        <option value="">Selecionar</option>
                                        <option value="Lento">Lento</option>
                                        <option value="Médio">Médio</option>
                                        <option value="Rápido">Rápido</option>
                                    </select>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className='flex w-full gap-4 mt-8'>
                    <button onClick={handleSubmit} className={`small-button ${edit ? 'bg-[var(--primary)]' : 'bg-green'}`}>{edit ? 'Atualizar' : 'Criar Lead'}</button>
                </div>
            </div>
        </div>
    );
}

