import React, { useContext, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { updateData } from '../../services/server';
import { Context } from '../../contexts/Context';
import { MdOutlineModeEditOutline } from 'react-icons/md';

export default function EntryPage() {
    const navigate = useNavigate();
    const context = useContext(Context);
    const { id = "" } = useParams();

    const [entry, setEntry] = useState<any>({});
    const payment = window.location.pathname.includes('payment');

    const [newAmountLeft, setNewAmountLeft] = useState(0);
    const [amountLeft, setAmountLeft] = useState(0);
    const [amountDone, setAmountDone] = useState(0);

    useEffect(() => {
        const entry = (payment ? context?.payments : context?.receivements)?.find((entry: any) => entry?.id === id);
        setEntry(entry);
    }, [context?.payments, id, context?.receivements, payment]);


    useEffect(() => {
        if (entry) {
            setAmountDone(entry?.totalDone);
            setAmountLeft(entry?.totalLeft);
        }
    }, [entry]);

    async function pay() {
        if (newAmountLeft > amountLeft) return toast.error('O montante não pode ser superior ao montante por liquidar.');
        if (newAmountLeft <= 0) return toast.error('O montante tem de ser superior a 0.');
        const data = {
            totalLeft: amountLeft - newAmountLeft,
            totalDone: amountDone + newAmountLeft
        }
        await updateData(toast, payment ? 'payments' : 'receivements', `${entry?.id}`, data, 'Movimento atualizado com sucesso.', () => {
            if (payment) {
                const newpayments = [...context?.payments?.filter((c: any) => c.id !== entry?.id), { ...entry, ...data }];
                context?.setPayments(newpayments);
            } else {
                const newreceivements = [...context?.receivements?.filter((c: any) => c.id !== entry?.id), { ...entry, ...data }];
                context?.setReceivements(newreceivements);
            }
        });
        setAmountLeft((prev: number) => prev - newAmountLeft);
        setAmountDone((prev: number) => prev + newAmountLeft);
        setNewAmountLeft(0);
    }

    async function checkMonth(index:number) {
        const newMonthly = entry?.monthly;
        newMonthly[index].done = !newMonthly[index].done;
        const newEntry = { ...entry, monthly: newMonthly };
        await updateData(toast, payment ? 'payments' : 'receivements', `${entry?.id}`, newEntry, 'Movimento atualizado com sucesso.', () => {
            if (payment) {
                const newpayments = [...context?.payments?.filter((c: any) => c.id !== entry?.id), newEntry];
                context?.setPayments(newpayments);
                setEntry(newEntry);
            } else {
                const newreceivements = [...context?.receivements?.filter((c: any) => c.id !== entry?.id), newEntry];
                context?.setReceivements(newreceivements);
                setEntry(newEntry);
            }
        });

    }

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    function getMonthAndYear(date: string) {
        const d = new Date(date);
        return `${d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}/${d.getFullYear()}`;
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <div className='flex flex-col justify-between w-full gap-6 mb-8 cinco:items-center cinco:flex-row'>
                <p onClick={() => navigate(-1)} className='relative text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
                </p>
                <div className='flex gap-4'>

                </div>
            </div>
            <div className='flex flex-col gap-4 mb-8 oitocentos:justify-between oitocentos:items-center oitocentos:flex-row'>
                <h1 className='text-[1.5rem] font-bold'>{entry?.title}</h1>
            </div>
            <div className='grid grid-cols-1 gap-4 oitocentos:grid-cols-2'>
                <div>
                    <p>Data:</p>
                    <p className='input'>{formateDate(entry?.date) || 'Campo vazio'}</p>
                </div>
                <div>
                    <p>Cliente associado:</p>
                    <p className='input'>{context?.clients.find((ele: any) => ele.email === entry?.client)?.name || 'Campo vazio'}</p>
                </div>
                <div>
                    <p>Valor:</p>
                    <p className='input'>{entry?.value || 'Campo vazio'}</p>
                </div>
                <div>
                    <p>Valor com IVA:</p>
                    <p className='input'>{entry?.valueWithTax || 'Campo vazio'}</p>
                </div>
                <div>
                    <p>IVA (%):</p>
                    <p className='input'>{entry?.tax || 'Campo vazio'}</p>
                </div>
                <div>
                    <p>Valor do IVA:</p>
                    <p className='input'>{entry?.taxValue || 'Campo vazio'}</p>
                </div>
                {
                    entry?.type === "Pontual" ?
                        <div className='flex w-full gap-4'>
                            <div className="flex flex-col gap-4">
                                <p>Valor:</p>
                                <div className={`w-[10rem] h-[10rem] flex justify-center items-center ${amountLeft > 0 ? 'bg-[var(--red)]' : 'bg-green'} text-white rounded-[25px] text-[1.5rem]`}>{isNaN(entry?.valueWithTax) ? 0 : entry?.valueWithTax}€</div>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="flex gap-4">
                                    <div className="flex flex-col gap-4">
                                        <p>Por liquidar:</p>
                                        <p className='input'>{isNaN(amountLeft) ? 0 : amountLeft}€</p>
                                    </div>
                                    <div className="flex flex-col gap-4">
                                        <p>Liquidado:</p>
                                        <p className='input'>{isNaN(amountDone) ? 0 : amountDone}€</p>
                                    </div>
                                </div>
                                {
                                    entry?.valueWithTax !== amountDone &&
                                    <div className="flex flex-col gap-4">
                                        <p>{payment ? 'Pagar' : 'Receber'} parte:</p>
                                        <div className='flex gap-4'>
                                            <input
                                                type="number"
                                                placeholder='Montante'
                                                value={newAmountLeft}
                                                onChange={(e) => setNewAmountLeft(parseInt(e.target.value))}
                                                className='input'
                                            />
                                            <button onClick={pay} className='small-button bg-green'>{payment ? 'Pagar' : 'Receber'}</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <>
                            <div className='w-full mt-4'>
                                <h2 className='mb-8 text-[1.2rem]'>Por {payment ? 'pagar' : 'receber'}</h2>
                                <div className='flex flex-col gap-4'>
                                    {
                                        entry?.monthly && entry?.monthly?.length !== 0 ? entry?.monthly?.map((ele: any, index: number) => {
                                            if (ele.done) return null
                                            return (
                                                <div key={index} className='big-button style1 bg-[var(--primary)] !flex-row !items-center !justify-between !cursor-default'>
                                                    <p>{getMonthAndYear(ele?.date)}</p>
                                                    <button className={`w-5 h-5 border-[2px] border-[var(--black)] rounded-[4px] cursor-pointer bg-[var(--red)]`} onClick={() => checkMonth(index)}></button>
                                                </div>
                                            )
                                        }) :
                                            <p className='w-full text-center'>Nenhum resultado</p>
                                    }
                                </div>
                            </div>
                            <div className='w-full mt-4'>
                                <h2 className='mb-8 text-[1.2rem]'>Histórico</h2>
                                <div className='flex flex-col gap-4 '>
                                    {
                                        entry?.monthly && entry?.monthly?.length !== 0 ? entry?.monthly?.map((ele: any, index: number) => {
                                            if (!ele.done) return null
                                            return (
                                                <div key={index} className='big-button style1 bg-[var(--primary)] !flex-row !items-center !justify-between !cursor-default'>
                                                    <p>{getMonthAndYear(ele?.date)}</p>
                                                    <button className={`w-5 h-5 border-[2px] border-[var(--black)] rounded-[4px] cursor-pointer bg-[var(--green)]`} onClick={() => checkMonth(index)}></button>
                                                </div>
                                            )
                                        }) :
                                            <p className='w-full text-center'>Nenhum resultado</p>
                                    }
                                </div>
                            </div>
                        </>
                }
            </div>
            <div className='flex flex-col gap-4 mt-8 seis:flex-row'>
                <button onClick={() => navigate(`/finances/${payment ? 'payments/' : ''}${entry?.id}/edit`)} className='small-button bg-[var(--primary)] gap-4'>Editar <MdOutlineModeEditOutline /></button>
            </div>
        </div>
    );
}

