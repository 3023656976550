import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import { Context } from '../../contexts/Context';
import { create, removeDoc, updateData } from '../../services/server';

export default function Contact() {
    const context = useContext(Context);
    const {id = ""} = useParams();
    const navigate = useNavigate();
    const edit = window.location.pathname.includes('edit');

    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [type, setType] = useState('');
    const [createdBy, setCreatedBy] = useState('');

    useEffect(() => {
        if (edit) {
            const template = context?.templates?.find((ele: any) => ele.id === id);
            if (template) {
                setName(template.name);
                setSubject(template.subject);
                setMessage(template.message);
                setType(template.type);
                setCreatedBy(template.createdBy);
            }
        }
    }, [id, context?.templates, edit]);

    async function deleteTemplate() {
        if (!window.confirm('Tem a certeza que deseja eliminar este template?')) return;
        try {
            await removeDoc('templates', id);
            // Update local state
            const newTemplates = context?.templates?.filter((ele: any) => ele.id !== id);
            context?.setTemplates(newTemplates);
            toast.success('Template eliminado com sucesso!');
            navigate(-1);
        } catch (err) {
            console.error(err);
            toast.error('Erro ao realizar operação. Tente novamente.');
        }
    }

    async function save() {
        if (!name || subject === '' || message === '' || !type)
            return toast.error('Preencha todos os campos.');
        const data = { 
            name, 
            subject, 
            message, 
            createdBy: edit ? createdBy : context?.user.email, 
            type 
        };
        try {
            let newId:any = id;
            if (edit) {
                await updateData(toast, 'templates', id, data, '', () => { });
            } else {
                newId = new Date().toISOString();
                await create(toast, 'templates', newId, data, '', () => { });
            }
            // Update local state
            const newTemplates = [...(context?.templates?.filter((ele:any) => ele.id !== id) || []), { ...data, id: newId }];
            context?.setTemplates(newTemplates);
            toast.success('Template guardado com sucesso!');
            navigate(-1);
        } catch (err) {
            console.error(err);
            toast.error('Erro ao realizar operação. Tente novamente.');
        }
    }

    return (
        <div className='flex flex-col w-full min-h-screen gap-8 p-8'>
            <Toaster />
            <div className="flex items-center justify-between">
                <p onClick={() => navigate(-1)} className='relative w-full text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
                </p>
            </div>
            <div className='flex flex-col w-full h-full '>
                <h1 className='text-[1.5rem] font-bold mb-8'>{edit ? 'Editar template' : 'Novo template'}</h1>
                <div className='flex flex-col w-full gap-4'>
                    <p>Nome do template:</p>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} className='input' placeholder='| Nome' />
                    <p>Tipo:</p>
                    <select value={type} onChange={(e) => setType(e.target.value)} className='input'>
                        <option value="">Selecionar</option>
                        <option value="RH">Recursos Humanos</option>
                        <option value="Comercial">Comercial</option>
                        <option value="Partnership">Parceria</option>
                    </select>
                    <p>Assunto:</p>
                    <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className='input' placeholder='| Assunto' />
                    <p>Mensagem:</p>
                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} className='input h-[13rem] resize-none my-scrollbar' placeholder='| Mensagem' />
                    <div className='flex gap-4'>
                        <button onClick={save} className='flex justify-center small-button bg-[var(--green)]'><p className='w-full text-center'>Guardar</p></button>
                        {edit && <button onClick={deleteTemplate} className='flex justify-center small-button bg-[var(--red)]'><p className='w-full text-center'>Eliminar</p></button>}
                    </div>
                </div>
            </div>
        </div>
    );
}

