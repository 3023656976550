import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsCheck } from 'react-icons/bs/';

import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../contexts/Context';
import { updateData, create } from '../../services/server';
import Loading from '../Loading';
import toast, { Toaster } from "react-hot-toast";

export default function EditPartner() {
    const context = useContext(Context);
    const navigate = useNavigate();

    const edit = window.location.pathname.includes('edit');
    const { id = "" } = useParams();

    const [partner, setPartner] = useState<any>({});

    const selectRef2 = useRef<HTMLDivElement>(null);

    const [nome, setNome] = useState('');
    const [role, setRole] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [contacto, setContacto] = useState('');
    const [email, setEmail] = useState('');
    const [link, setLink] = useState('');
    const [remarks, setRemarks] = useState('');
    const [websiteLink, setWebsiteLink] = useState('');
    const [partnerType, setPartnerType] = useState('');

    const [rhAgents, setRhAgents] = useState<any>([]);
    const [channels, setChannels] = useState<any>([]);
    const [partnerTypes, setPartnerTypes] = useState<any>([]);

    const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
    const [isDropdownOpen2, setIsDropdownOpen2] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            if (edit) {
                const partner = context?.partners?.find((partner: any) => partner?.id === id);
                setPartner(partner);
            }
            setRhAgents(context?.rh);
            setChannels(context?.settings?.channels);
            setPartnerTypes(context?.settings?.partnerTypes);
        };
        fetchData();
    }, [edit, context?.settings, context?.sales, context?.rh, context?.partners, id]);

    useEffect(() => {
        if (partner) {
            setNome(partner?.name || '');
            setResponsavel(partner?.responsavel || '');
            setContacto(partner?.contacto || '');
            setEmail(partner?.email || '');
            setLink(partner?.link || '');
            setSelectedChannels(partner?.channels || []);
            setRemarks(partner?.remarks || '');
            setRole(partner?.role || '');
            setWebsiteLink(partner?.websiteLink || '');
            setPartnerType(partner?.type || '');
        }
    }, [partner]);

    function isValidEmail(email: string): boolean {
        const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailPattern.test(email);
    }

    const handleSubmit = async (e: any) => {
        if (!nome || !email || selectedChannels.length === 0 || !partnerType)
            return toast.error('Preencha todos os campos.');
        if (!isValidEmail(email)) {
            return toast.error('Email inválido.');
        }
        const data = {
            type: 'Partner',
            lead: false,
            name: nome.trim(),
            responsavel: responsavel.trim(),
            contacto: contacto.trim(),
            email: email.trim(),
            link: link.trim(),
            channels: selectedChannels,
            remarks: remarks.trim(),
            active: true,
            websiteLink,
            creationDate: edit ? partner?.creationDate : new Date().toISOString(),
            role,
            partnerType,
            createdBy: edit ? partner?.createdBy : context?.user?.email,
        };
        if (edit)
            await updateData(toast, 'users', email, data, 'Parceria atualizada com sucesso.', () => {
                const newPartners = [...context?.partners?.filter((ele: any) => ele.id !== partner?.id), { ...data, id: email }]
                context?.setPartners(newPartners);
                navigate(-1);
            });
        else
            await create(toast, 'users', email, data, 'Parceria criada com sucesso.', () => {
                const newPartners = [...context?.partners?.filter((ele: any) => ele.id !== partner?.id), { ...data, id: email }]
                context?.setPartners(newPartners);
                navigate(-1);
            });
    };

    useEffect(() => {
        function handleClickOutside2(event: any) {
            if (selectRef2.current && !selectRef2.current.contains(event.target)) {
                setIsDropdownOpen2(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside2);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside2);
        };
    }, [selectRef2]);

    const handleChannelselection = (channel: string) => {
        setSelectedChannels((prevSelectedAgents: any) => {
            if (prevSelectedAgents.includes(channel)) {
                return prevSelectedAgents.filter((name: string) => name !== channel);
            } else {
                return [...prevSelectedAgents, channel];
            }
        });
    };

    if (!rhAgents) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <p onClick={() => navigate(-1)} className='relative w-full mb-8 text-left cursor-pointer'>
                <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
            </p>
            <div className='flex items-center justify-between w-full mb-8'>
                <h1 className='text-[1.5rem] font-bold'>{edit ? 'Editar' : 'Nova Parceria'}</h1>
            </div>
            <div className='text-[1.2rem]'>
                <div className='grid grid-cols-1 mil:grid-cols-2 text-[1rem] gap-4'>
                    <div className="flex flex-col gap-2">
                        <p className='mandatory'>Nome da empresa</p>
                        <input
                            type='text'
                            placeholder='Nome da empresa'
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            className='input'
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className=''>Nome do responsável</p>
                        <input
                            type='text'
                            placeholder='Nome do responsável'
                            value={responsavel}
                            onChange={(e) => setResponsavel(e.target.value)}
                            className='input'
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className=''>Posição do responsável</p>
                        <input
                            type='text'
                            placeholder='Posição do responsável'
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className='input'
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className=''>Contacto</p>
                        <input
                            type='tel'
                            placeholder='Contacto'
                            value={contacto}
                            onChange={(e) => setContacto(e.target.value)}
                            className='input'
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className='mandatory'>Email</p>
                        <input
                            type='email'
                            placeholder='Email'
                            value={email}
                            disabled={edit}
                            onChange={(e) => setEmail(e.target.value)}
                            className='input'
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className='mandatory'>Tipo de Parceiro</p>
                        <select
                            value={partnerType}
                            onChange={(e) => setPartnerType(e.target.value)}
                            className='input'
                        >
                            <option value="">Selecionar</option>
                            {
                                partnerTypes && partnerTypes.map((type: string) => {
                                    return (
                                        <option key={type} value={type}>{type}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className='mandatory'>Canais de contacto</p>
                        <div
                            className="relative cursor-pointer input"
                            onClick={() => setIsDropdownOpen2(!isDropdownOpen2)}
                            ref={selectRef2}
                        >
                            {selectedChannels.length === 0
                                ? 'Selecionar'
                                : `${selectedChannels.length} selecionado${selectedChannels.length > 1 ? 's' : ''}`}
                            {isDropdownOpen2 && (
                                <div className="absolute w-full left-0 top-10 bg-white border-2 border-[var(--black)] rounded-md z-10 h-[15rem] overflow-y-auto my-scrollbar">
                                    {channels.map((channel: string, index: number) => (
                                        <div
                                            key={index}
                                            className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleChannelselection(channel);
                                            }}
                                        >
                                            <div className={`w-4 h-4 border-[1px] border-[var(--black)] rounded-[4px] ${selectedChannels.includes(channel) ? 'bg-[var(--primary)]' : ''}`}>
                                                <span className={`${selectedChannels.includes(channel) ? 'flex' : 'hidden'} text-white`}><BsCheck /></span>
                                            </div>
                                            <p className="ml-2 text-sm">{channel}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className='ext-gray-700 '>Link da pasta partilhada</p>
                        <input
                            type='link'
                            placeholder='Link'
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                            className='input'
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className='ext-gray-700 '>Website Link</p>
                        <input
                            type='text'
                            placeholder='Website Link'
                            value={websiteLink}
                            onChange={(e) => setWebsiteLink(e.target.value)}
                            className='input'
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className=''>Observações</p>
                        <textarea
                            placeholder='Observações da parceria'
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                            className='resize-none my-scrollbar input h-[13.5rem]'
                        ></textarea>
                    </div>
                </div>
                <div className='flex w-full gap-4 mt-8'>
                    <button onClick={handleSubmit} className={`small-button ${edit ? 'bg-[var(--primary)]' : 'bg-green'}`}>{edit ? 'Atualizar' : 'Criar Parceria'}</button>
                </div>
            </div>
        </div>
    );
}

