import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface LineChartProps {
  data1: { title: string; data: { [key: string]: number } };
  data2: { title: string; data: { [key: string]: number } };
}

const LineChart: React.FC<LineChartProps> = ({ data1, data2 }) => {
  const parseDate = (dateStr: string) => {
    const [month, year] = dateStr.split("/").map(Number);
    return new Date(year, month - 1);
  };

  const labels = Object.keys(data1?.data).sort(
    (a, b) => parseDate(a).getTime() - parseDate(b).getTime()
  );
  const points1 = labels.map((label) => data1.data[label]);
  const points2 = labels.map((label) => data2.data[label]);

  const chartData = {
    labels,
    datasets: [
      {
        label: data1.title,
        data: points1,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: false,
        tension: 0.1,
      },
      {
        label: data2.title,
        data: points2,
        borderColor: "rgba(153, 102, 255, 1)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        fill: false,
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Points & Tasks Per Month",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
      y: {
        title: {
          display: true,
          text: "Points",
        },
        beginAtZero: true,
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;
