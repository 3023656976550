import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdEmail } from "@react-icons/all-files/md/MdEmail";

import { MdOutlineModeEditOutline } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { FaUserCheck } from "react-icons/fa";
import { FaPersonCircleMinus } from "react-icons/fa6";
import { Context } from '../../contexts/Context';
import { updateData } from '../../services/server';
import Loading from '../Loading';
import toast, { Toaster } from "react-hot-toast";
import { ImCheckboxChecked } from 'react-icons/im';
import LineChart from '../../components/LineChart';

export default function AgentPage() {
    const navigate = useNavigate();
    const context = useContext(Context);
    const { id = "" } = useParams();

    const [agent, setAgent] = useState<any>({});
    const lead = window.location.pathname.includes('lead');

    const [projects, setProjects] = useState<any>([]);
    const [tasks, setTasks] = useState<any>([]);
    const [nextMeeting, setNextMeeting] = useState('');
    const [lang, setLang] = useState('pt');
    const [newArea, setNewArea] = useState(false);
    const [newTech, setNewTech] = useState(false);
    const [newAreaValue, setNewAreaValue] = useState('');
    const [newTechValue, setNewTechValue] = useState('');
    const [totalPoints, setTotalPoints] = useState<any>();
    const [payments, setPayments] = useState<any>();
    const [data1, setData1] = useState<any>(null);
    const [data2, setData2] = useState<any>(null);

    useEffect(() => {
        const agent = context?.agents?.find((agent: any) => agent?.email === id && agent?.lead === lead);
        setAgent(agent);
        setLang(localStorage.getItem('lang') || 'pt');
        const payments = context?.payments?.filter((payment: any) => payment?.agent === agent?.email && payment?.monthly);
        if (payments && payments.length) {
            setPayments(payments);
        }
    }, [context?.agents, id, lead, context?.payments, context?.settings, context?.user]);

    useEffect(() => {
        if (!lead && context?.projects && context?.internProjects && agent?.email) {
            const allProjects = [...context?.projects, ...context?.internProjects];
            let allMeetings: any = [];
            let filteredProjects: any = [];
            let filteredTasks: any = [];
            let finishedTasks: any = [];
            let totalPoints: any = {};
            if (!allProjects) return;
            const fetchTasksAndFilterProjects = async () => {
                for (const project of allProjects) {
                    if (project?.agents.includes(agent?.email)) {
                        filteredProjects.push(project);
                        const meetings = project?.meetings || [];
                        allMeetings = [...allMeetings, ...meetings];
                        if (!project?.sprints) continue;
                        for (const sprint of project?.sprints) {
                            for (const task of sprint?.tasks) {
                                if (task?.agent !== agent?.email) continue ;
                                if (task?.state === 'em progresso')
                                    filteredTasks.push(task);
                                else if (task?.state === 'terminada') {
                                    finishedTasks.push(task);
                                    const deadlineMonthYear = getMonthAndYear(task?.deadline);
                                    totalPoints[deadlineMonthYear] = (totalPoints[deadlineMonthYear] || 0) + (task?.points || 0);
                                }
                            }
                        }
                    }
                }
                setProjects(filteredProjects || []);
                setTasks(filteredTasks || []);
                setTotalPoints(totalPoints);
                const data1 = {
                    title: "Points",
                    data: totalPoints
                };
            
                const tasksPerMonth: { [key: string]: number } = {};

                finishedTasks.forEach((task: any) => {
                    const deadlineMonthYear = getMonthAndYear(task?.deadline);
                    tasksPerMonth[deadlineMonthYear] = (tasksPerMonth[deadlineMonthYear] || 0) + 1;
                });

                const data2 = {
                    title: "Tasks",
                    data: tasksPerMonth
                };

            
                setData1(data1);
                setData2(data2);
                
                const today = new Date();
                allMeetings = getMeeting(allMeetings.filter((m: any) => new Date(m.date) >= today));
                if (allMeetings && allMeetings.length) {
                    setNextMeeting(allMeetings[0].date);
                }
            };
            fetchTasksAndFilterProjects();
        }
    }, [context?.projects, context?.internProjects, agent?.email, lead]);

    function getMonthAndYear(date: string) {
        const d = new Date(date);
        return `${d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}/${d.getFullYear()}`;
    }

    function getMeeting(list: any) {
        return list.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }

    async function changeToAgent() {
        if (!window.confirm('Tem a certeza que pretende passar esta lead para agente?')) return;
        await updateData(toast, 'users', agent?.email, { active: true, lead: false }, 'Novo agente criado!', () => {
            context?.setAgents((prev: any) => [...prev, { ...agent, lead: false, active: true }]);
        });
    }

    async function deactivate() {
        await updateData(toast, 'users', agent?.email, { active: false }, 'Agente desativado com sucesso.', () => {
            context?.setAgents((prev: any) => [...prev, { ...agent, active: false }]);
        });
    }

    async function activate() {
        await updateData(toast, 'users', agent?.email, { active: true }, 'Agente ativado com sucesso.', () => {
            context?.setAgents((prev: any) => [...prev, { ...agent, active: true }]);
        });
    }

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    async function addArea() {
        await updateData(toast, 'users', agent?.email, { ...agent, areas: agent?.areas ? [...agent?.areas, newAreaValue] : [newAreaValue] }, 'Agente atualizado com sucesso.', () => {
            const newAgents = [...context?.agents?.filter((ele: any) => ele.id !== agent?.id), { ...agent, areas: agent?.areas ? [...agent?.areas, newAreaValue] : [newAreaValue] }]
            context?.setAgents(newAgents);
            context?.setUser((prev: any) => ({ ...prev, areas: agent?.areas ? [...agent?.areas, newAreaValue] : [newAreaValue] }));
        });
    }

    async function addTech() {
        await updateData(toast, 'users', agent?.email, { ...agent, techs: agent?.techs ? [...agent?.techs, newTechValue] : [newTechValue] }, 'Agente atualizado com sucesso.', () => {
            const newAgents = [...context?.agents?.filter((ele: any) => ele.id !== agent?.id), { ...agent, techs: agent?.techs ? [...agent?.techs, newTechValue] : [newTechValue] }]
            context?.setAgents(newAgents);
            context?.setUser((prev: any) => ({ ...prev, techs: agent?.techs ? [...agent?.techs, newTechValue] : [newTechValue] }));
        });
    }

    if (!agent || !tasks || !projects || !context?.settings || !data1 || !data2) {
        return (
            <Loading />
        )
    }

    return (
        <div className='flex flex-col w-full gap-8 p-4 quatrocentos:p-8'>
            <Toaster />
            {
                newArea && (
                    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50'>
                        <div className='w-[30rem] bg-white p-4 rounded-lg flex flex-col gap-4'>
                            <p className='text-[1.2rem] font-bold'>{lang === 'pt' ? 'Adicionar Area' : 'Add Area'}</p>
                            <select className='input'
                                value={newAreaValue}
                                onChange={(e) => setNewAreaValue(e.target.value)}
                            >
                                <option value="">{lang === 'pt' ? 'Selecionar' : 'Select'}</option>
                                {
                                    context?.settings.areas && context?.settings.areas.length !== 0 ? context?.settings.areas.map((ele: string, index: number) => {
                                        return (
                                            <option value={ele} key={index} className=''>
                                                {ele}
                                            </option>
                                        )
                                    }) :
                                        <p className='w-full text-center'>Nenhum resultado</p>
                                }
                            </select>
                            <div className='flex justify-between'>
                                <button onClick={addArea} className='small-button bg-[var(--primary)]'>{lang === 'pt' ? 'Adicionar' : 'Add'}</button>
                                <button className='small-button bg-[var(--red)]' onClick={() => setNewArea(false)}>{lang === 'pt' ? 'Cancelar' : 'Cancel'}</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                newTech && (
                    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50'>
                        <div className='w-[30rem] bg-white p-4 rounded-lg flex flex-col gap-4'>
                            <p className='text-[1.2rem] font-bold'>{lang === 'pt' ? 'Adicionar Tech' : 'Add Tech'}</p>
                            <select className='input'
                                value={newTechValue}
                                onChange={(e) => setNewTechValue(e.target.value)}
                            >
                                <option value="">{lang === 'pt' ? 'Selecionar' : 'Select'}</option>
                                {
                                    context?.settings.techs && context?.settings.techs.length !== 0 ? context?.settings.techs.map((ele: string, index: number) => {
                                        return (
                                            <option value={ele} key={index} className=''>
                                                {ele}
                                            </option>
                                        )
                                    }) :
                                        <p className='w-full text-center'>Nenhum resultado</p>
                                }
                            </select>
                            <div className='flex justify-between'>
                                <button onClick={addTech} className='small-button bg-[var(--primary)]'>{lang === 'pt' ? 'Adicionar' : 'Add'}</button>
                                <button className='small-button bg-[var(--red)]' onClick={() => setNewTech(false)}>{lang === 'pt' ? 'Cancelar' : 'Cancel'}</button>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className='flex flex-col justify-between w-full gap-6 mb-8 cinco:items-center cinco:flex-row'>
                <p onClick={() => navigate(-1)} className='relative text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>{lang === 'pt' ? 'Voltar' : 'Back'}</span>
                </p>
                <div className='flex gap-4'>
                    {
                        !nextMeeting ?
                            <button className='gap-4 small-button bg-[var(--stats)] text-black' onClick={() => navigate('/schedule', { state: { user: { user: agent } } })}>
                                <p className='text-[var(--black)]'>{lang === 'pt' ? 'Agendar reunião' : 'Schedule meeting'}</p>
                                <FaCalendarAlt className='text-[var(--black)]' />
                            </button>
                            :
                            <button className='gap-4 small-button bg-[var(--stats)] text-black' onClick={() => nextMeeting ? toast(`${lang === 'pt' ? 'Reunião de equipa agendada para o dia' : 'Team meeting scheduled for'} ${formateDate(nextMeeting)}`) : {}}>
                                <p className='text-[var(--black)]'>{nextMeeting ? formateDate(nextMeeting) : lang === 'pt' ? 'Nenhum meeting' : 'No meeting'}</p>
                                <FaCalendarAlt className='text-[var(--black)]' />
                            </button>
                    }
                    <button onClick={() => navigate(`/contact/${agent?.email}/${agent?.contacto}`)} className='gap-4 small-button bg-purple'>
                        {lang === 'pt' ? 'Contactar' : 'Contact'}
                        <MdEmail />
                    </button>
                </div>
            </div>
            <div className='flex flex-col gap-4 mb-8 oitocentos:justify-between oitocentos:items-center oitocentos:flex-row'>
                <h1 className='text-[1.5rem] font-bold'>{agent.name}</h1>
            </div>
            <div className='flex flex-col gap-4'>
                <div className='flex flex-col oitocentos:flex-row oitocentos:gap-8'>
                    <div className='flex flex-col w-full gap-4'>
                        <p>{lang === 'pt' ? 'Função' : 'Role'}:</p>
                        <p className='cursor-pointer input'>{agent?.role || 'Campo vazio'}</p>
                        <p>Email:</p>
                        <p onClick={() => navigate(`/contact/${agent?.email}`)} className='cursor-pointer input'>{agent?.email || 'Campo vazio'}</p>
                        <p>{lang === 'pt' ? 'Contacto' : 'Phone Number'}:</p>
                        <a href={`tel:${agent?.contacto}`} className='input'>{agent?.contacto || 'Campo vazio'}</a>
                        <p>Github:</p>
                        <p onClick={() => window.open(agent?.github, '_blank')} className='cursor-pointer input'>{agent?.github || 'Campo vazio'}</p>
                        <p>LinkedIn:</p>
                        <p onClick={() => window.open(agent?.linkedin, '_blank')} className='cursor-pointer input'>{agent?.linkedin || 'Campo vazio'}</p>
                        <p>Obs:</p>
                        <textarea disabled value={agent?.remarks} className='resize-none my-scrollbar input h-[14rem]' ></textarea>
                    </div>
                    <div className='flex flex-col w-full gap-4'>
                        <div className='flex flex-col w-full gap-8'>
                            <div className='flex items-center justify-between'>
                                <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Áreas' : 'Areas'}</h2>
                                {agent?.email === context?.user.email && <button onClick={() => setNewArea(true)} className='w-6 h-6 bg-[var(--green)] rounded-full text-white'>+</button>}
                            </div>
                            <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                                {
                                    (!agent?.areas || agent?.areas.length === 0) ? <p>{lang === 'pt' ? 'Nenhuma área' : 'No area'}</p> :
                                        agent?.areas.map((area: any, index: number) => (
                                            <div key={index} className='small-button bg-[var(--primary)]'>
                                                <p className='text-[1.2rem]'>{area}</p>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                        <div className='flex flex-col w-full gap-8'>
                            <div className='flex items-center justify-between'>
                                <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Experiência em' : 'Experience in'}</h2>
                                {agent?.email === context?.user.email && <button onClick={() => setNewTech(true)} className='w-6 h-6 bg-[var(--green)] rounded-full text-white'>+</button>}
                            </div>
                            <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                                {
                                    (!agent?.techs || agent?.techs.length === 0) ? <p>{lang === 'pt' ? 'Nenhuma tecnologia' : 'No technology'}</p> :
                                        agent?.techs.map((tech: string, index: number) => (
                                            <div key={index} className='small-button bg-[var(--primary)]'>
                                                <p className='text-[1.2rem]'>{tech}</p>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    lead ?
                        agent?.contactado &&
                        <div className='flex flex-col w-full gap-4 oitocentos:pr-4 oitocentos:w-1/2'>
                            <div className="flex flex-col gap-2">
                                <p className=''>{lang === 'pt' ? 'Vezes que foi contactado' : 'Times spoken'}</p>
                                <p className='input'>{agent?.timesSpoken || 'Campo vazio'}</p>
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className=''>{lang === 'pt' ? 'Nível de interesse' : 'Level of interest'}</p>
                                <p className='input'>{agent?.levelOfInteress || 'Campo vazio'}</p>
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className=''>{lang === 'pt' ? 'Tempo de resposta' : 'Answer time'}</p>
                                <p className='input'>{agent?.answerTime || 'Campo vazio'}</p>
                            </div>
                        </div>
                        :
                        <div className='flex flex-col w-full gap-8 oitocentos:flex-row'>
                            <div className='flex flex-col w-full gap-8'>
                                <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Projetos' : 'Projects'}</h2>
                                <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                                    {
                                        (!projects || projects.length === 0) ? <p>{lang === 'pt' ? 'Nenhum projeto atribuido' : 'No assigned projects'}</p> :
                                            projects.map((project: any, index: number) => (
                                                <div onClick={() => navigate(`/project/${project?.company === 'VisionD' ? 'intern/' : ''}${project?.company}-${project?.name}`)} key={index} className='small-button bg-[var(--primary)] justify-between'>
                                                    <p className='text-[1.2rem]'>{project?.company}-{project?.name}</p>
                                                    {project?.meetings && project?.meetings.length && <p className='text-[1.2rem]'>{formateDate(getMeeting(project?.meetings)[0].date)}</p>}
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                            <div className='flex flex-col w-full gap-8'>
                                <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Tarefas' : 'Tasks'}</h2>
                                <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                                    {
                                        (!tasks || tasks.length === 0) ? <p>{lang === 'pt' ? 'Nenhuma tarefa atribuida' : 'No assigned tasks'}</p> :
                                            tasks.map((task: any, index: number) => (
                                                <div key={index} className='small-button bg-[var(--primary)] justify-between'>
                                                    <p className='max-w-[14rem] overflow-hidden whitespace-nowrap text-ellipsis'>{task?.task}</p>
                                                    <p className=''>{formateDate(task?.deadline)}</p>
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>
            {
                !lead &&
                <div className='flex flex-col w-full gap-8 oitocentos:flex-row'>
                    <div className='flex flex-col w-full gap-8'>
                        <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Pontos por mês' : 'Points per month'}</h2>
                        <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                            {
                                (!totalPoints || Object.keys(totalPoints).length === 0) ? <p>{lang === 'pt' ? 'Nenhum ponto' : 'No points'}</p> :
                                    Object.keys(totalPoints).sort().map((key: string, index: number) => (
                                        <div key={index} className='small-button bg-[var(--primary)] justify-between'>
                                            <p className='text-[1.2rem]'>{key}</p>
                                            <p className='text-[1.2rem]'>{totalPoints[key]}</p>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                    {
                        context?.user?.type === 'Admin' &&
                        <div className='flex flex-col w-full gap-8'>
                            <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Pagamentos por mês' : 'Payments per month'}</h2>
                            <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                                {
                                    (!payments || payments.length === 0) ? <p>{lang === 'pt' ? 'Nenhum pagamento' : 'No payments'}</p> :
                                        payments[0]?.monthly?.map((ele: any, index: number) => {
                                            return (
                                                <div key={index} className='big-button style1 bg-[var(--primary)] !flex-row !items-center !justify-between !cursor-default'>
                                                    <p>{getMonthAndYear(ele?.date)}</p>
                                                    <div className={`w-5 h-5 border-[2px] border-[var(--black)] rounded-[4px] ${ele?.done ? 'bg-[var(--green)]' : 'bg-[var(--red)]'}`} ></div>
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>
                    }
                </div>
            }
            <div className='flex flex-col w-full gap-8 oitocentos:flex-row'>
                <div className='flex flex-col w-full gap-8'>
                    <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Pontos e Tasks' : 'Points and Tasks'}</h2>
                    <LineChart data1={data1} data2={data2} />
                </div>
                <div className='flex flex-col w-full gap-8'>

                </div>
            </div>
            {
                agent?.email !== context?.user?.email &&
                <div className='flex flex-col gap-4 mt-8 seis:flex-row'>
                    <button onClick={() => navigate(`/agent${lead ? '/lead' : ''}/${agent?.id}/edit`)} className='small-button bg-[var(--primary)] gap-4'>{lang === 'pt' ? 'Editar' : 'Edit'} <MdOutlineModeEditOutline /></button>
                    {
                        agent?.lead &&
                        <div onClick={changeToAgent} className='gap-4 small-button bg-[var(--green)]'>
                            {lang === 'pt' ? 'Passar a Agente' : 'Change to Agent'}
                            <FaUserCheck />
                        </div>
                    }
                    {
                        agent?.active ?
                            <button onClick={deactivate} className='small-button bg-[var(--red)] gap-4'>{lang === 'pt' ? 'Desativar' : 'Deactivate'} {lead ? 'lead' : 'agente'} <FaPersonCircleMinus /></button> :
                            <>
                                {
                                    (!agent?.lead || agent?.contactado) &&
                                    <button onClick={activate} className='small-button bg-[var(--green)] gap-4'>{lang === 'pt' ? 'Ativar agente' : 'Activate agent'} <FaUserCheck /></button>
                                }
                            </>
                    }
                    {(context?.user?.type === 'Admin' || context?.user?.type === 'RH') && <button onClick={() => navigate(`checklist`)} className='small-button bg-[var(--blue)] gap-4'>{lang === 'pt' ? 'Ver Check List' : 'View Check List'} <ImCheckboxChecked /></button>}
                </div>
            }
        </div>
    );
}
