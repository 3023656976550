import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Context } from '../../../contexts/Context';
import { LuFilter, LuFilterX } from "react-icons/lu";
import Loading from '../../Loading';
import ExcelView from '../ExcelView';
import { exportExcel } from '../../../components/export';
import { importExcel } from '../../../components/import';
import DownloadIcon from '../../../content/images/icons/download.png';
import { create, updateData } from '../../../services/server';
import toast, { Toaster } from "react-hot-toast";
import { ImCheckboxChecked } from 'react-icons/im';
import { BsDatabaseFillAdd } from "react-icons/bs";

export default function Home() {
    const navigate = useNavigate();
    const context = useContext(Context);

    const [view, setView] = useState('list');
    const [tableData, setTableData] = useState<any>(null);
    const [clients, setClients] = useState<any>(null);
    const [services, setServices] = useState<any>(null);
    const [filterOn, setFilterOn] = useState(false);

    // Filters
    const [search, setSearch] = useState('');
    const [service, setService] = useState('');
    const [consulting, setConsulting] = useState('');
    const [viewAll, setViewAll] = useState('1');

    useEffect(() => {
        if (context?.clients) {
            let result = context?.clients;
            // Filter by search
            if (search) {
                result = result.filter((ele: any) =>
                    (ele?.name).toLowerCase().includes(search.toLowerCase()) ||
                    (ele?.responsavel).toLowerCase().includes(search.toLowerCase()) ||
                    (ele?.email).toLowerCase().includes(search.toLowerCase()));
            }
            // Filter by other filters
            result = result.filter((ele: any) =>
                (consulting ? ele?.consulting === (consulting === '1' ? true : false) : true) &&
                (viewAll === "0" ? ele?.embaixador === context?.user?.email : true) &&
                (service ? ele?.services.includes(service) : true)
            );
            setClients(result);
            setTableData(result);
        }
        if (context?.settings) {
            setServices(context?.settings?.services);
        }
    }, [search, consulting, filterOn, service, context?.clients, context?.settings, viewAll, context?.user?.email]);

    const header = [
        "name",
        "responsavel",
        "role",
        "email",
        "contacto",
        "embaixador",
        "remarks",
        "websiteLink",
        "active",
        "link",
    ];

    async function handleImport(e:any) {
        const file = e.target?.files[0];
        const newData:any = await importExcel(toast, file);
        if (newData !== '' && newData !== undefined && newData !== null) {
            const allEntriesValid = newData.every((item: any) => {
                return 'name' in item && 'email' in item && isValidEmail(item?.email);
            });
            if (allEntriesValid) {
                if (tableData) 
                    setTableData([...tableData, ...newData]);
                else
                    setTableData(newData);
                toast.success('Dados importados com sucesso.');
            } else {
                toast.error('Todos os dados devem ter o campo "name" preenchido e "email" válido.');
            }
        } else {
            console.error('Erro ao importar os dados.');
            toast.error('Erro ao importar os dados.');
        }
    }

    function handleExport() {
        const exportData = clients?.map((client: any) => {
            return {
                nome: client?.name,
                "responsável": client?.responsavel,
                "função do responsável": client?.role,
                email: client?.email,
                contacto: client?.contacto,
                embaixador: client?.embaixador,
                obs: client?.remarks,
                websiteLink: client?.websiteLink,
                ativo: client?.active,
                pastaPartilhada: client?.link,
            };
        });
        exportExcel(exportData, 'clientes');
    }

    function isValidEmail(email: string): boolean {
        const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailPattern.test(email);
    }

    function saveData() {
        let error = '';
        try {
            tableData.forEach((client: any) => {
                if (error) return client;
                if (!client?.name || !client?.email) {
                    error = 'Existem campos obrigatórios que não estão preenchidos.';
                    return client;
                }
                if (!isValidEmail(client?.email)) {
                    error = 'Um dos emails é inválido.';
                    return client;
                }
            });
            if (error) {
                toast.error(error);
                return;
            }
            tableData.forEach((client: any) => {
                if (context?.clients.find((ele: any) => ele.email === client?.email)) {
                    updateData(toast, 'users', client?.email, client, '', () => { });
                    return client;
                }
                else {
                    create(toast, 'users', client?.email, { ...client, type: 'Client', lead: false, embaixador: context?.user?.email, creationDate: new Date().toISOString() }, '', () => { });
                    return {...client, id: client?.email};
                }
            });
            context?.setClients(tableData);
            toast.success('Dados guardados com sucesso');
        } catch (error) {
            toast.error('Erro ao guardar os dados.');
        }
    }

    if (!clients) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full min-h-screen p-8'>
            <Toaster />
            <div className='flex flex-col items-end justify-between w-full gap-4 cinco:items-center cinco:flex-row'>
                <button onClick={() => navigate("/client/create")} className='small-button bg-[var(--primary)]'>+ Novo Cliente</button>
                <button onClick={() => navigate('checklist')} className='small-button bg-[var(--blue)]'>Ver Check List <ImCheckboxChecked /></button>
            </div>
            <div className='flex flex-col gap-4 mt-8'>
                <div className='flex items-center gap-4'>
                    <input
                        type="text"
                        placeholder='Pesquisa'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className='input'
                    />
                    <button className='p-2 border-[1px] border-[var(--black)] rounded-full cursor-pointer' onClick={() => setFilterOn(!filterOn)}>
                        {filterOn ? <LuFilterX /> : <LuFilter />}
                    </button>
                </div>
                {
                    <div className={`flex items-center gap-8 overflow-y-hidden overflow-x-auto transition-all duration-500 ease ${filterOn ? 'max-h-[5rem]' : 'max-h-0'}`}>
                        <div className="flex flex-col gap-2">
                            <p>Consultoria</p>
                            <select value={consulting} onChange={(e) => setConsulting(e.target.value)} className='filter'>
                                <option value="">Selecionar</option>
                                <option value="0">Sem consultoria</option>
                                <option value="1">Com consultoria</option>
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Serviços ativos</p>
                            <select value={service} onChange={(e) => setService(e.target.value)} className='filter'>
                                <option value="">Selecionar</option>
                                {
                                    services && services.length !== 0 ? services.map((ele: string, index: number) => {
                                        return (
                                            <option key={index} className='' value={ele}>
                                                {ele}
                                            </option>
                                        )
                                    }) :
                                        <p className='w-full text-center'>Nenhum resultado</p>
                                }
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Ver Clientes</p>
                            <select value={viewAll} onChange={(e) => setViewAll(e.target.value)} className='filter'>
                                <option value="0">Apenas os meus</option>
                                <option value="1">Todos</option>
                            </select>
                        </div>
                    </div>
                }
            </div>
            <div className='flex flex-col items-center justify-between gap-4 my-6 seis:flex-row'>
                <div className='relative flex items-center w-[16rem] h-8 border-2 border-[var(--black)] rounded-full justify-evenly'>
                    <div className={`absolute top-0 ${view === 'list' ? 'left-0' : 'left-1/2'} h-full w-1/2 bg-[var(--stats)] rounded-full z-[-1] transition-all duration-300`}></div>
                    <p onClick={() => setView('list')} className='w-1/2 text-[.8rem] text-center cursor-pointer'>Lista</p>
                    <p onClick={() => setView('excel')} className='w-1/2 text-[.8rem] text-center cursor-pointer'>Excel</p>
                </div>
                <div className='flex flex-col gap-4 quatro:flex-row'>
                    <button className='relative small-button bg-[var(--primary)] gap-4'>
                        Importar 
                        <BsDatabaseFillAdd/>
                        <input
                            type="file"
                            onChange={handleImport}
                            className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
                        />
                    </button>
                    <button onClick={handleExport} className='small-button bg-[var(--primary)] gap-4'>
                        Download
                        <img src={DownloadIcon} alt="icon" className='w-6' />
                    </button>
                </div>
            </div>
            {
                view === 'list' ?
                    <div className='flex flex-col gap-10 mil:flex-row'>
                        <div className='w-full mil:w-1/2'>
                            <h2 className=' text-[1.5rem]'>Clientes Ativos</h2>
                            <p className='mb-8'>{clients ? `Total: ${clients?.filter((client: any) => !client?.concluido).length}` : ""}</p>
                            <div className='flex flex-col gap-4'>
                                {
                                    (!clients || clients?.length === 0) ? <p>Nenhum resultado</p> :
                                        clients?.filter((client: any) => !client?.concluido)?.map((client: any, index: number) => (
                                            <div
                                                key={index}
                                                className='big-button style1 bg-purple'
                                                onClick={() => (client?.embaixador === context?.user?.email || context?.user?.type === 'Admin') ? navigate(`/client/${client?.id}`) : {}}
                                            >
                                                <p className='text-[1.2rem] seis:max-w-[50%] mil:max-w-full text-ellipsis overflow-hidden'>{client?.name}</p>
                                                <p className='text-[1rem]'>{client?.email}</p>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                        <div className='w-full mil:w-1/2'>
                            <h2 className='text-[1.5rem]'>Clientes Anteriores</h2>
                            <p className='mb-8'>{clients ? `Total: ${clients?.filter((client: any) => client?.concluido).length}` : ""}</p>
                            <div className='flex flex-col gap-4'>
                                {
                                    (!clients || clients?.filter((client: any) => client?.concluido)?.length === 0) ? <p>Nenhum resultado</p> :
                                        clients?.filter((client: any) => client?.concluido)?.map((client: any, index: number) => (
                                            <div
                                                key={index}
                                                className='big-button style1 bg-gray'
                                                onClick={() => (client?.embaixador === context?.user?.email || context?.user?.type === 'Admin') ? navigate(`/client/${client?.id}`) : {}}
                                            >
                                                <p className='text-[1.2rem]'>{client?.name}</p>
                                                <p className='text-[1rem]'>{client?.email}</p>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <ExcelView header={header} tableData={tableData} setTableData={setTableData} data="clients" />
            }
            {view === 'excel' && <button className="small-button bg-[var(--green)]" onClick={saveData}><p className='w-full text-center'>Guardar</p></button>}
        </div>
    );
}

