import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../contexts/Context';

export default function AgentProfile({ user, setUser, lang }: { user: any, setUser: any, lang: string }) {
    const navigate = useNavigate();
    const context = useContext(Context);

    const [projects, setProjects] = useState<any>([]);
    const [tasks, setTasks] = useState<any>([]);
    const [nextMeeting, setNextMeeting] = useState<any>(null);

    useEffect(() => {
        if (context?.projects && context?.internProjects && user?.email) {
            const allProjects = [...context?.projects, ...context?.internProjects];
            let filteredProjects: any = [];
            let filteredTasks: any = [];
            if (!allProjects) return;
            const fetchTasksAndFilterProjects = async () => {
                for (const project of allProjects) {
                    if (project?.agents.includes(user?.email) && project?.faseDoProjeto !== 4) {
                        filteredProjects.push(project);
                        if (!project?.sprints) continue;
                        for (const sprint of project?.sprints) {
                            for (const task of sprint?.tasks) {
                                if (task?.agent === user?.email && task?.state === 'em progresso')
                                    filteredTasks.push({ ...task, project: project?.name, company: project?.company });
                            }
                        }
                    }
                }
                setProjects(filteredProjects.sort((a: any, b: any) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime()));
                setTasks(filteredTasks.sort((a: any, b: any) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime()));
            };

            fetchTasksAndFilterProjects();
        }
    }, [context?.projects, context?.internProjects, user?.email]);

    useEffect(() => {
        if (user?.meetings && user?.meetings.length > 0) {
            const today = new Date();
            const meetings = user?.meetings.filter((m: any) => new Date(m.date) >= today);
            setNextMeeting(getMeeting(meetings)[0]);
        }
    }, [user]);

    function getMeeting(list: any) {
        if (!list) return [];
        return list.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    return (
        <div className='flex flex-col w-full gap-8 pb-8 mt-8'>
            <div className="flex flex-col justify-between gap-4 cinco:items-center cinco:flex-row">
                <p className='font-bold'>{lang === 'pt' ? 'Detalhes do Agente' : 'Agent Details'}</p>
            </div>
            <div className='grid w-full grid-cols-1 gap-8 oitocentos:grid-cols-2'>
                <div className='flex flex-col w-full gap-2'>
                    <p>{lang === 'pt' ? 'Posição' : 'Position'}</p>
                    <p className='input'>{user?.role || (lang === 'pt' ? 'Não definido' : 'Not defined')}</p>
                </div>
                <div className='flex flex-col w-full gap-2'>
                    <p>{lang === 'pt' ? 'Próxima reunião' : 'Next Meeting'}</p>
                    <a href={nextMeeting?.link} className='input'>{nextMeeting ? formateDate(nextMeeting?.date) : (lang === 'pt' ? 'Nenhuma reunião agendada' : 'No scheduled meeting')}</a>
                </div>
            </div>
            <div className='flex flex-col w-full gap-8 oitocentos:flex-row'>
                <div className='flex flex-col w-full gap-8'>
                    <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Projetos' : 'Projects'}</h2>
                    <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                        {
                            (!projects || projects.length === 0) ? <p>{lang === 'pt' ? 'Nenhum projeto atribuido' : 'No assigned projects'}</p> :
                                projects.map((project: any, index: number) => (
                                    <div key={index} className='small-button bg-[var(--primary)]' onClick={() => navigate(`/project/${project?.company === 'VisionD' ? 'intern/' : ''}${project?.company}-${project?.name}`)}>
                                        <div>
                                            <p>{project?.name}</p>
                                            <p>{lang === 'pt' ? 'Empresa' : 'Company'}: {project?.company}</p>
                                        </div>
                                        <p className='text-[1rem]'>{formateDate(project?.deadline)}</p>
                                    </div>
                                ))
                        }
                    </div>
                </div>
                <div className='flex flex-col w-full gap-8'>
                    <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Próximas tarefas' : 'Upcoming Tasks'}</h2>
                    <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                        {
                            (!tasks || tasks.length === 0) ? <p>{lang === 'pt' ? 'Nenhuma tarefa atribuida' : 'No assigned tasks'}</p> :
                                tasks.map((task: any, index: number) => (
                                    <div key={index} onClick={() => navigate(`/project/${task.company === 'VisionD' ? 'intern/' : ''}${task.company}-${task.project}/roadmap`)} className='small-button bg-[var(--primary)] gap-4'>
                                        <div>
                                            <p>{task?.project}</p>
                                            <p>{task?.task}</p>
                                        </div>
                                        <p className='text-[1rem]'>{formateDate(task?.deadline)}</p>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
