import React, { useContext, useEffect, useState } from 'react';

import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import { ImBin } from "react-icons/im";
import { useNavigate } from 'react-router-dom';
import { Context } from '../contexts/Context';
import Loading from './Loading';
import toast, { Toaster } from "react-hot-toast";
import { IoDocumentText } from 'react-icons/io5';
import { updateData } from '../services/server';

export default function Settings() {
    const navigate = useNavigate();
    const context = useContext(Context);
    const [users, setUsers] = useState<any>(null);
    const [services, setServices] = useState<any>(null);
    const [areas, setAreas] = useState<any>(null);
    const [techs, setTechs] = useState<any>(null);
    const [tipos, setTipos] = useState<any>(null);
    const [channels, setChannels] = useState<any>(null);
    const [partnerTypes, setPartnerTypes] = useState<any>(null);

    const [tipoSelected, setTipoSelected] = useState('Inactive');

    const [service, setService] = useState('');
    const [area, setArea] = useState('');
    const [tech, setTech] = useState('');
    const [channel, setChannel] = useState('');
    const [partnerType, setPartnerType] = useState('');
    const [userType, setUserType] = useState('');
    const [user, setUser] = useState<any>(null);

    const toggleList: any = {
        Inactive: 'left-0',
        HR: 'left-[16.6%]',
        Sales: 'left-[33.3%]',
        Agent: 'left-[50%]',
        Client: 'left-[66.6%]',
        Partner: 'left-[83.3%]',
    };

    useEffect(() => {
        if (context) {
            if (tipoSelected === 'Inactive')
                setUsers(context?.inactive);
            else if (tipoSelected === 'HR')
                setUsers(context?.rh);
            else if (tipoSelected === 'Sales')
                setUsers(context?.sales);
            else if (tipoSelected === 'Agent')
                setUsers(context?.agents.filter((ele: any) => !ele?.lead));
            else if (tipoSelected === 'Client')
                setUsers(context?.clients.filter((ele: any) => !ele?.lead));
            else if (tipoSelected === 'Partner')
                setUsers(context?.partners.filter((ele: any) => !ele?.lead));
            setTipos(context?.settings.types);
            setServices(context?.settings.services);
            setAreas(context?.settings.areas);
            setTechs(context?.settings.techs);
            setChannels(context?.settings.channels);
            setPartnerTypes(context?.settings.partnerTypes);
        }
    }, [context, context?.admins, context?.rh, context?.sales, context?.inactive, context?.agents, context?.clients, tipoSelected]);

    async function addUser() {
        try {
            await updateData(toast, 'users', user?.email, {...user, type: userType, lead: false}, 'Agente atualizado com sucesso.', () => { });
            toast.success(`Utilizador atualizado com sucesso.`);
            navigate('/home');
        } catch (err) {
            toast.error('Utilizador já existe.');
            console.error(err);
        }
        // TODO: Enviar email com credenciais
    }

    async function addItem(listName: string, item: any) {
        if (!item) {
            return toast.error('Preencha o campo para adicionar o item.');
        }
        try {
            const docRef = doc(db, 'admin', 'geral');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                let items = docSnap.data()[listName];
                items = [...items, item];
                await updateDoc(docRef, { [listName]: items })
                    .then(() => {
                        toast.success(`Item adicionado a ${listName} com sucesso.`);
                        updateState(listName, items);
                        setChannel('');
                        setPartnerType('');
                        setArea('');
                        setService('');
                        setTech('');
                    });
            }
        } catch (error) {
            console.error("Error updating document: ", error);
            toast.error(`Erro ao adicionar item a ${listName}.`);
        }
    }

    async function deleteItem(listName: string, index: any) {
        if (!window.confirm(`Tem a certeza que pretende apagar este item de ${listName}?`)) {
            return;
        }
        try {
            const docRef = doc(db, 'admin', 'geral');
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let items = docSnap.data()[listName];
                items = [...items.slice(0, index), ...items.slice(index + 1)];
                await updateDoc(docRef, { [listName]: items })
                    .then(() => {
                        toast.success(`Item removido de ${listName} com sucesso.`);
                        updateState(listName, items);
                    });
            }
        } catch (error) {
            console.error("Error updating document: ", error);
            toast.error(`Erro ao remover item de ${listName}.`);
        }
    }

    function updateState(listName: string, items: any) {
        switch (listName) {
            case 'services':
                setServices(items);
                break;
            case 'areas':
                setAreas(items);
                break;
            case 'techs':
                setTechs(items);
                break;
            case 'channels':
                setChannels(items);
                break;
            case 'partnerTypes':
                setPartnerTypes(items);
                break;
        }
    }

    async function openUserPage(user: any) {
        if (user?.type === 'Agent' && user?.lead) {
            navigate(`/agent/lead/${user?.id}`);
        } else if (user?.type === 'Agent' && !user?.lead) {
            navigate(`/agent/${user?.id}`);
        } else if (user?.type === 'Client' && user?.lead) {
            navigate(`/client/lead/${user?.id}`);
        } else if (user?.type === 'Client' && !user?.lead) {
            navigate(`/client/${user?.id}`);
        } else if (user?.type === 'Partner' && !user?.lead) {
            navigate(`/partner/${user?.id}`);
        } else if (user?.type === 'Partner' && user?.lead) {
            navigate(`/partner/lead/${user?.id}`);
        } else if (user?.type === 'inactive') { 
            setUser(user);
        }
    }

    function openMeet() {
        if (!context?.settings?.assembleiaLink) {
            return toast.error('Não há link de reunião definido.');
        }
        window.open(context?.settings?.assembleiaLink, '_blank');
    }

    if (!users || !services || !tipos || !areas || !techs) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <div className='flex flex-col items-center justify-between w-full gap-4 mb-8 sete:flex-row'>
                <h1 className='text-[1.5rem] font-bold'>Definições</h1>
                <div className='flex flex-col items-center gap-4 cinco:flex-row'>
                    <button onClick={openMeet} className='justify-center small-button bg-[var(--stats)] hidden quatro:flex !text-[var(--black)]'><p className='w-full text-center'>Assembleia Geral</p></button>
                    <button onClick={() => navigate('/templates')} className='justify-center small-button bg-[var(--primary)] hidden quatro:flex'><p className='w-full text-center'>Ver templates</p><IoDocumentText /></button>
                </div>
            </div>
            <div className='text-[1.2rem]'>
                <div className='flex flex-col text-[1rem] gap-8'>
                    <div className='w-full pb-8 border-b-2 border-[var(--black)]'>
                        <p className='font-bold text-[1.2rem] mb-8'>Users</p>
                        <div className='relative flex items-center w-full h-8 mb-8 border-2 border-[var(--black)] rounded-full justify-evenly'>
                            <div className={`absolute top-0 ${toggleList[tipoSelected]} h-full w-1/6 bg-[var(--stats)] rounded-full z-[-1] transition-all duration-300`}></div>
                            <p onClick={() => setTipoSelected('Inactive')} className='w-1/3 text-[.8rem] text-center cursor-pointer'>Inactive</p>
                            <p onClick={() => setTipoSelected('HR')} className='w-1/3 text-[.8rem] text-center cursor-pointer'>HR</p>
                            <p onClick={() => setTipoSelected('Sales')} className='w-1/3 text-[.8rem] text-center cursor-pointer'>Sales</p>
                            <p onClick={() => setTipoSelected('Agent')} className='w-1/3 text-[.8rem] text-center cursor-pointer'>Agent</p>
                            <p onClick={() => setTipoSelected('Client')} className='w-1/3 text-[.8rem] text-center cursor-pointer'>Client</p>
                            <p onClick={() => setTipoSelected('Partner')} className='w-1/3 text-[.8rem] text-center cursor-pointer'>Partner</p>
                        </div>
                        <div className="flex seis:flex-row flex-col gap-[8vw]">
                            <div className='flex flex-col gap-4 w-full max-h-[15rem] overflow-y-auto my-scrollbar'>
                                {
                                    users.length !== 0 ? users.map((ele: any, index: number) => {
                                        return (
                                            <div onClick={() => openUserPage(ele)} key={index} className={`small-button bg-[var(--primary)] ${ele?.email === user?.email ? '!border-2 !border-[var(--stats)]' : ''}`}>
                                                <p>{ele?.name}</p>
                                            </div>
                                        )
                                    }) :
                                        <p className='w-full text-center'>Nenhum resultado</p>
                                }
                            </div>
                            <div className='flex flex-col w-full gap-4'>
                                <p>Atualizar utilizador: {tipoSelected}</p>
                                <select
                                    className='input'
                                    value={userType}
                                    onChange={(e) => setUserType(e.target.value)}
                                >
                                    {
                                        tipos && tipos.map((ele: string, index: number) => {
                                            return (
                                                <option key={index} value={ele}>{ele}</option>
                                            )
                                        })
                                    }
                                </select>
                                <div className='flex justify-center w-full'>
                                    <button onClick={addUser} className='small-button bg-green'>+ Atualizar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full pb-8 border-b-2 border-[var(--black)]'>
                        <p className='font-bold text-[1.2rem] mb-8'>Serviços</p>
                        <div className='flex seis:flex-row flex-col gap-[8vw]'>
                            <div className='flex flex-col gap-4 w-full max-h-[15rem] overflow-y-auto my-scrollbar'>
                                {
                                    services.length !== 0 ? services.map((ele: string, index: number) => {
                                        return (
                                            <div key={index} className='small-button bg-[var(--primary)]'>
                                                <p>{ele}</p>
                                                <div onClick={() => deleteItem('services', index)} className='w-[2rem] aspect-square rounded-full bg-white flex justify-center items-center cursor-pointer'><ImBin color='black' /></div>
                                            </div>
                                        )
                                    }) :
                                        <p className='w-full text-center'>Nenhum resultado</p>
                                }
                            </div>
                            <div className='flex flex-col w-full gap-4'>
                                <p>Novo Serviço</p>
                                <div className='flex flex-col gap-4'>
                                    <input
                                        type="text"
                                        placeholder='Serviço'
                                        value={service}
                                        onChange={(e) => setService(e.target.value)}
                                        className='input'
                                    />
                                    <div className='flex justify-center w-full'>
                                        <button onClick={() => addItem('services', service)} className='small-button bg-green'>+ Adicionar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full pb-8 border-b-2 border-[var(--black)]'>
                        <p className='font-bold text-[1.2rem] mb-8'>Areas</p>
                        <div className='flex seis:flex-row flex-col gap-[8vw]'>
                            <div className='flex flex-col gap-4 w-full max-h-[15rem] overflow-y-auto my-scrollbar'>
                                {
                                    areas.length !== 0 ? areas.map((ele: string, index: number) => {
                                        return (
                                            <div key={index} className='small-button bg-[var(--primary)]'>
                                                <p>{ele}</p>
                                                <div onClick={() => deleteItem('areas', index)} className='w-[2rem] aspect-square rounded-full bg-white flex justify-center items-center cursor-pointer'><ImBin color='black' /></div>
                                            </div>
                                        )
                                    }) :
                                        <p className='w-full text-center'>Nenhum resultado</p>
                                }
                            </div>
                            <div className='flex flex-col w-full gap-4'>
                                <p>Nova Area</p>
                                <div className='flex flex-col gap-4'>
                                    <input
                                        type="text"
                                        placeholder='Area'
                                        value={area}
                                        onChange={(e) => setArea(e.target.value)}
                                        className='input'
                                    />
                                    <div className='flex justify-center w-full'>
                                        <button onClick={() => addItem('areas', area)} className='small-button bg-green'>+ Adicionar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full pb-8 border-b-2 border-[var(--black)]'>
                        <p className='font-bold text-[1.2rem] mb-8'>Tecnologias</p>
                        <div className='flex seis:flex-row flex-col gap-[8vw]'>
                            <div className='flex flex-col gap-4 w-full max-h-[15rem] overflow-y-auto my-scrollbar'>
                                {
                                    techs.length !== 0 ? techs.map((ele: string, index: number) => {
                                        return (
                                            <div key={index} className='small-button bg-[var(--primary)]'>
                                                <p>{ele}</p>
                                                <div onClick={() => deleteItem('techs', index)} className='w-[2rem] aspect-square rounded-full bg-white flex justify-center items-center cursor-pointer'><ImBin color='black' /></div>
                                            </div>
                                        )
                                    }) :
                                        <p className='w-full text-center'>Nenhum resultado</p>
                                }
                            </div>
                            <div className='flex flex-col w-full gap-4'>
                                <p>Nova Tecnologia</p>
                                <div className='flex flex-col gap-4'>
                                    <input
                                        type="text"
                                        placeholder='Tech'
                                        value={tech}
                                        onChange={(e) => setTech(e.target.value)}
                                        className='input'
                                    />
                                    <div className='flex justify-center w-full'>
                                        <button onClick={() => addItem('techs', tech)} className='small-button bg-green'>+ Adicionar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full pb-8 border-b-2 border-[var(--black)]'>
                        <p className='font-bold text-[1.2rem] mb-8'>Canais de contacto</p>
                        <div className='flex seis:flex-row flex-col gap-[8vw]'>
                            <div className='flex flex-col gap-4 w-full max-h-[15rem] overflow-y-auto my-scrollbar'>
                                {
                                    channels.length !== 0 ? channels.map((ele: string, index: number) => {
                                        return (
                                            <div key={index} className='small-button bg-[var(--primary)]'>
                                                <p>{ele}</p>
                                                <div onClick={() => deleteItem('channels', index)} className='w-[2rem] aspect-square rounded-full bg-white flex justify-center items-center cursor-pointer'><ImBin color='black' /></div>
                                            </div>
                                        )
                                    }) :
                                        <p className='w-full text-center'>Nenhum resultado</p>
                                }
                            </div>
                            <div className='flex flex-col w-full gap-4'>
                                <p>Novo Canal</p>
                                <div className='flex flex-col gap-4'>
                                    <input
                                        type="text"
                                        placeholder='Channel'
                                        value={channel}
                                        onChange={(e) => setChannel(e.target.value)}
                                        className='input'
                                    />
                                    <div className='flex justify-center w-full'>
                                        <button onClick={() => addItem('channels', channel)} className='small-button bg-green'>+ Adicionar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full pb-8 border-b-2 border-[var(--black)]'>
                        <p className='font-bold text-[1.2rem] mb-8'>Tipos de Parceiro</p>
                        <div className='flex seis:flex-row flex-col gap-[8vw]'>
                            <div className='flex flex-col gap-4 w-full max-h-[15rem] overflow-y-auto my-scrollbar'>
                                {
                                    partnerTypes.length !== 0 ? partnerTypes.map((ele: string, index: number) => {
                                        return (
                                            <div key={index} className='small-button bg-[var(--primary)]'>
                                                <p>{ele}</p>
                                                <div onClick={() => deleteItem('partnerTypes', index)} className='w-[2rem] aspect-square rounded-full bg-white flex justify-center items-center cursor-pointer'><ImBin color='black' /></div>
                                            </div>
                                        )
                                    }) :
                                        <p className='w-full text-center'>Nenhum resultado</p>
                                }
                            </div>
                            <div className='flex flex-col w-full gap-4'>
                                <p>Novo Tipo</p>
                                <div className='flex flex-col gap-4'>
                                    <input
                                        type="text"
                                        placeholder='PartnerType'
                                        value={partnerType}
                                        onChange={(e) => setPartnerType(e.target.value)}
                                        className='input'
                                    />
                                    <div className='flex justify-center w-full'>
                                        <button onClick={() => addItem('partnerTypes', partnerType)} className='small-button bg-green'>+ Adicionar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Easter egg para os meus sócios! Vamos enriquecer ⚡</p>
                </div>
            </div>
        </div>
    );
}

