import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Context } from './contexts/Context';
import CircularProgress from '@mui/material/CircularProgress';
import { auth, db } from './services/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import toast, { Toaster } from "react-hot-toast";

// Main Pages
import Navbar from './components/Navbar';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import InactivePage from './pages/InactivePage';
import Home from './pages/Home';

// Clients
import Clients from './pages/clients/ongoing/Clients';
import Leads from './pages/clients/leads/Leads';
import CreateClient from './pages/clients/ongoing/CreateClient';
import CreateLead from './pages/clients/leads/CreateLead';
import ClientPage from './pages/clients/ongoing/ClientPage';
import LeadPage from './pages/clients/leads/LeadPage';

// Projects
import Projects from './pages/projects/Projects';
import InternProjects from './pages/projects/InternProjects';
import CreateProject from './pages/projects/CreateProject';
import ProjectPage from './pages/projects/ProjectPage';
import ProjectRoadmap from './pages/projects/roadmap/Roadmap';

// Agents
import Agents from './pages/agents/Agents';
import AgentLeads from './pages/agents/Leads';
import CreateAgent from './pages/agents/CreateAgent';
import AgentPage from './pages/agents/AgentPage';


// Partners
import Partners from './pages/partners/Partners';
import PartnerLeads from './pages/partners/Leads';
import CreatePartner from './pages/partners/CreatePartner';
import CreatePartnerLead from './pages/partners/CreateLead';
import PartnerPage from './pages/partners/PartnerPage';
import PartnerRoadmap from './pages/partners/roadmap/Roadmap';
import PartnerSchedule from './pages/partners/schedule/Schedule';

// Finances
import CreateEntry from './pages/finances/CreateEntry';
import EntryPage from './pages/finances/EntryPage';
import Payments from './pages/finances/Payments';
import Receivements from './pages/finances/Receivements';

// Checklist
import ChecklistAll from './pages/checklist/checklistAll';
import Checklist from './pages/checklist/checklistType';

// Templates
import Templates from './pages/emailsTemplate/EmailsTemplate';
import CreateTemplate from './pages/emailsTemplate/CreateTemplate';

// Others
import ScheduleMeeting from './pages/Schedule';
import Contact from './pages/Contact';
import Settings from './pages/Settings';
import NotFoundPage from './pages/NotFound';
import { signOut } from 'firebase/auth';

const App = () => {
	const context = useContext(Context);

	const [isLogged, setIsLogged] = useState(false);
	const [type, setType] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [takingTooLong, setTakingTooLong] = useState(false);

	useEffect(() => {
		let timeoutId:any;
		if (isLoading) {
		  timeoutId = setTimeout(() => {
			setTakingTooLong(true);
		  }, 10000);
		} else {
		  setTakingTooLong(false);
		}
	  
		return () => clearTimeout(timeoutId);
	  }, [isLoading]);
	  

	useEffect(() => {
		if (context?.user) {
			setType(context?.user?.type);
		}
	}, [context?.user]);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async user => {
			setIsLogged(!!user);
			if (user) {
				const userInfo = await fetchUserInfo();
				if (!userInfo) {
					return toast.error('Usuário não encontrado.');
				}
				context?.setUser({ ...userInfo, id: userInfo.id, lang: userInfo?.lang || 'pt' });
				localStorage.setItem('lang', userInfo?.lang || 'pt');
				setIsLoading(false);
			} else {
				setIsLoading(false);
				setIsLogged(false);
			}
		});
		return () => unsubscribe();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchUserInfo = async () => {
		const usersRef = collection(db, "users");
		const q = query(usersRef, where("email", "==", auth?.currentUser?.email));
		const querySnapshot = await getDocs(q);
		if (querySnapshot.empty) return null;
		const userDoc = querySnapshot.docs[0];
		const user = userDoc.data();
		return user;
	}

	const Layout = ({ children }: { children: any }) => {
		return (
			<div className='flex flex-col oitocentos:flex-row'>
				{type !== 'inactive' && <Navbar type={type} />}
				<div className='w-full text-[var(--black)] pt-[5rem] oitocentos:pt-0 oitocentos:pl-[17rem]'>
					{children}
				</div>
			</div>
		);
	};

	async function logOut() {
        try {
            await signOut(auth);
            localStorage.clear();
            window.location.href = '/';
        } catch (error) {
            alert("Log out failed.");
        }
    }

	if (isLoading || context?.isLoading) {
		return (
			<div className='bg-[var(--primary)] text-white flex flex-col gap-10 items-center justify-center w-screen h-screen text-[1.5rem]'>
				<CircularProgress color="inherit" />
                <p className='text-[1rem]'>Loading...</p>
				{takingTooLong && <button onClick={logOut} className='px-4 py-1 text-[1rem] rounded-[10px] bg-[var(--white)] !text-black'>Log Out</button>}
			</div>
		)
	}

	return (
		<>
			<Toaster />
			<BrowserRouter>
				<Routes>
					<Route index element={<Login />} />
					<Route path={'/register'} element={<SignUp />} />
					{
						isLogged &&
						<>
							{
								(type === 'Admin' || type === 'Sales') &&
								<>
									<Route path="/clients" element={<Layout><Clients /></Layout>} />
									<Route path="/client/:id" element={(<Layout><ClientPage /></Layout>)} />
									<Route path="/client/create" element={(<Layout><CreateClient /></Layout>)} />
									<Route path="/client/:id/edit" element={(<Layout><CreateClient /></Layout>)} />
									<Route path="/clients/leads" element={<Layout><Leads /></Layout>} />
									<Route path="/client/lead/:id" element={(<Layout><LeadPage /></Layout>)} />
									<Route path="/client/lead/create" element={(<Layout><CreateLead /></Layout>)} />
									<Route path="/client/lead/:id/edit" element={(<Layout><CreateLead /></Layout>)} />
									<Route path="/:type/checklist" element={(<Layout><ChecklistAll /></Layout>)} />
									<Route path="/:type/leads/checklist" element={(<Layout><ChecklistAll /></Layout>)} />
									<Route path="/:type/:id/checklist" element={(<Layout><Checklist /></Layout>)} />
									<Route path="/:type/lead/:id/checklist" element={(<Layout><Checklist /></Layout>)} />
									<Route path="/:type/intern/:id/checklist" element={(<Layout><Checklist /></Layout>)} />
								</>
							}
							{
								(type === 'Admin' || type === 'Agent') &&
								<>
									<Route path="/projects" element={<Layout><Projects /></Layout>} />
									<Route path="/project/create" element={(<Layout><CreateProject /></Layout>)} />
									<Route path="/project/create/:clientEmail" element={(<Layout><CreateProject /></Layout>)} />
									<Route path="/project/:id/edit" element={(<Layout><CreateProject /></Layout>)} />
									<Route path="/projects/intern" element={<Layout><InternProjects /></Layout>} />
									<Route path="/project/intern/:id" element={(<Layout><ProjectPage /></Layout>)} />
									<Route path="/project/intern/create" element={(<Layout><CreateProject /></Layout>)} />
									<Route path="/project/intern/:id/edit" element={(<Layout><CreateProject /></Layout>)} />
									<Route path="/project/intern/:id/roadmap" element={(<Layout><ProjectRoadmap /></Layout>)} />
									<Route path="/agent/:id" element={(<Layout><AgentPage /></Layout>)} />
								</>
							}
							{
								(type === 'Admin' || type === 'HR') &&
								<>
									<Route path="/agents" element={(<Layout><Agents /></Layout>)} />
									<Route path="/agent/:id" element={(<Layout><AgentPage /></Layout>)} />
									<Route path="/agent/create" element={(<Layout><CreateAgent /></Layout>)} />
									<Route path="/agent/:id/edit" element={(<Layout><CreateAgent /></Layout>)} />
									<Route path="/agents/leads" element={(<Layout><AgentLeads /></Layout>)} />
									<Route path="/agent/lead/:id" element={(<Layout><AgentPage /></Layout>)} />
									<Route path="/agent/lead/create" element={(<Layout><CreateAgent /></Layout>)} />
									<Route path="/agent/lead/:id/edit" element={(<Layout><CreateAgent /></Layout>)} />
									<Route path="/:type/checklist" element={(<Layout><ChecklistAll /></Layout>)} />
									<Route path="/:type/leads/checklist" element={(<Layout><ChecklistAll /></Layout>)} />
									<Route path="/:type/:id/checklist" element={(<Layout><Checklist /></Layout>)} />
									<Route path="/:type/leads/:id/checklist" element={(<Layout><Checklist /></Layout>)} />

									<Route path="/partners" element={(<Layout><Partners /></Layout>)} />
									<Route path="/partner/:id" element={(<Layout><PartnerPage /></Layout>)} />
									<Route path="/partner/create" element={(<Layout><CreatePartner /></Layout>)} />
									<Route path="/partner/:id/edit" element={(<Layout><CreatePartner /></Layout>)} />
									<Route path="/partners/leads" element={(<Layout><PartnerLeads /></Layout>)} />
									<Route path="/partner/lead/:id" element={(<Layout><PartnerPage /></Layout>)} />
									<Route path="/partner/lead/create" element={(<Layout><CreatePartnerLead /></Layout>)} />
									<Route path="/partner/lead/:id/edit" element={(<Layout><CreatePartnerLead /></Layout>)} />
								</>
							}
							{
								(type === 'Partner' || type === 'Admin') &&
								<>
									<Route path="/partner/:id" element={(<Layout><PartnerPage /></Layout>)} />
									<Route path="/partner/:id/roadmap" element={(<Layout><PartnerRoadmap /></Layout>)} />
									<Route path="/partner/:id/roadmap/schedule" element={(<Layout><PartnerSchedule /></Layout>)} />
								</>
							}
							{
								(type === 'Admin' || type === 'Client' || type === 'Agent') &&
								<>
									<Route path="/project/:id" element={(<Layout><ProjectPage /></Layout>)} />
									<Route path="/project/:id/roadmap" element={(<Layout><ProjectRoadmap /></Layout>)} />
								</>
							}
							{type === 'Client' && <Route path="/client/:id" element={<Layout><ClientPage /></Layout>} />}
							{type === 'Admin' && <Route path="/finances" element={<Layout><Receivements /></Layout>} />}
							{type === 'Admin' && <Route path="/finances/payments" element={<Layout><Payments /></Layout>} />}
							{type === 'Admin' && <Route path="/finances/create" element={<Layout><CreateEntry /></Layout>} />}
							{type === 'Admin' && <Route path="/finances/payment/create" element={<Layout><CreateEntry /></Layout>} />}
							{type === 'Admin' && <Route path="/finances/payments/:id" element={<Layout><EntryPage /></Layout>} />}
							{type === 'Admin' && <Route path="/finances/:id" element={<Layout><EntryPage /></Layout>} />}
							{type === 'Admin' && <Route path="/finances/:id/edit" element={<Layout><CreateEntry /></Layout>} />}
							{type === 'Admin' && <Route path="/finances/payments/:id/edit" element={<Layout><CreateEntry /></Layout>} />}
							{type === 'Admin' && <Route path="/settings" element={<Layout><Settings /></Layout>} />}
							{type === 'Admin' && <Route path="/templates" element={<Layout><Templates /></Layout>} />}
							{type === 'Admin' && <Route path="/templates/:id/edit" element={<Layout><CreateTemplate /></Layout>} />}
							{type === 'Admin' && <Route path="/templates/create" element={<Layout><CreateTemplate /></Layout>} />}
							<Route path="/home" element={type === 'inactive' ? <InactivePage /> : <Layout><Home /></Layout>} />
							<Route path="/schedule" element={<Layout><ScheduleMeeting /></Layout>} />
							<Route path="/schedule/teamMeeting" element={<Layout><ScheduleMeeting /></Layout>} />
							<Route path="/schedule/partner" element={<Layout><ScheduleMeeting /></Layout>} />
							<Route path="/contact/:email" element={<Layout><Contact /></Layout>} />
							<Route path="/contact/:email/:tel" element={<Layout><Contact /></Layout>} />
						</>
					}
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default App;
