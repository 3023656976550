import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdEmail } from "@react-icons/all-files/md/MdEmail";

import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";
import { updateData } from '../../services/server';
import { Context } from '../../contexts/Context';
import Loading from '../Loading';
import toast, { Toaster } from "react-hot-toast";
import MultipleLineChart from '../../components/MultipleLineChart';
import { ImCheckboxChecked } from 'react-icons/im';

export default function ProjectPage() {
    const navigate = useNavigate();
    const context = useContext(Context);
    const { id = "" } = useParams();

    const [project, setProject] = useState<any>({});
    const intern = window.location.pathname.includes('intern');

    const [type, setype] = useState('');
    const [client, setClient] = useState<any>(null);
    const [agents, setAgents] = useState<any>(null);
    const [newAmountToPay, setNewAmountToPay] = useState(0);
    const [amountToPay, setAmountToPay] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [lang, setLang] = useState('pt');
    const [datasets, setDatasets] = useState<any>(null);

    const [isProjectManager, setIsProjectManager] = useState(false);
    const [countdown, setCountdown] = useState<any>();

    useEffect(() => {
        const project = (intern ? context?.internProjects : context?.projects)?.find((project: any) => project?.id === id);
        setProject(project);
        setLang(localStorage.getItem('lang') || 'pt');
        let tasks: any = [];
        let doneTasks: any = [];
        for (let i = 0; i < project?.sprints?.length; i++) {
            const allTasks = project?.sprints[i].tasks;
            tasks = [...tasks, ...allTasks];
            doneTasks = [...doneTasks, ...allTasks.filter((task: any) => task.state === 'terminada')];
        }

        // Done tasks per month
        const doneTasksPerMonth: { [key: string]: number } = {};
        doneTasks.forEach((task: any) => {
            const deadlineMonthYear = getMonthAndYear(task?.deadline);
            doneTasksPerMonth[deadlineMonthYear] = (doneTasksPerMonth[deadlineMonthYear] || 0) + 1;
        });
        const data1 = {
            title: "Done Tasks",
            data: doneTasksPerMonth
        };

        // Total tasks per month
        const totalTasksPerMonth: { [key: string]: number } = {};
        tasks.forEach((task: any) => {
            const deadlineMonthYear = getMonthAndYear(task?.deadline);
            totalTasksPerMonth[deadlineMonthYear] = (totalTasksPerMonth[deadlineMonthYear] || 0) + 1;
        });
        const data2 = {
            title: "Total Tasks",
            data: totalTasksPerMonth
        };

        // Tasks done by each agent per month
        const agentTasks: { [agent: string]: { [key: string]: number } } = {};
        doneTasks.forEach((task: any) => {
            const deadlineMonthYear = getMonthAndYear(task?.deadline);
            const agent = context?.agents.find((agent: any) => agent?.email === task?.agent)?.name || "Unknown";
            if (!agentTasks[agent]) {
                agentTasks[agent] = {};
            }
            agentTasks[agent][deadlineMonthYear] = (agentTasks[agent][deadlineMonthYear] || 0) + 1;
        });

        const agentDatasets = Object.keys(agentTasks).map(agent => ({
            title: agent,
            data: agentTasks[agent]
        }));

        // Combine all datasets
        const datasets = [data1, data2, ...agentDatasets];
        setDatasets(datasets);

    }, [context?.projects, id, context?.internProjects, intern, context?.agents]);

    // Count down to deadline
    useEffect(() => {
        if (project?.deadline) {
            const timer = setInterval(() => {
                setCountdown(calculateCountdown(project?.deadline));
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [project?.deadline]);


    useEffect(() => {
        if (project?.projectManager === context?.user.email)
            setIsProjectManager(true);
    }, [project?.projectManager, context?.user.email]);

    useEffect(() => {
        if (context?.user) {
            setype(context?.user?.type);
        }
    }, [context?.user]);

    useEffect(() => {
        if (!project) return;
        setAmountPaid(project?.paid);
        setAmountToPay(project?.toPay);
        if (context?.clients) {
            const client = context?.clients.find((client: any) => client?.name === project?.company);
            setClient(client);
        }
        if (context?.agents && project?.agents) {
            const agent = context?.agents.filter((agent: any) => project?.agents.includes(agent?.email));
            setAgents(agent);
        }
    }, [project, context?.clients, context?.agents]);


    const calculateCountdown = (targetDate: string) => {
        const now = new Date();
        const deadline = new Date(targetDate);

        let countdown = {
            months: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };

        if (deadline > now) {
            const totalSeconds = Math.floor((deadline.getTime() - now.getTime()) / 1000);

            countdown = {
                months: Math.floor(totalSeconds / (30 * 24 * 60 * 60)),
                days: Math.floor((totalSeconds % (30 * 24 * 60 * 60)) / (24 * 60 * 60)),
                hours: Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60)),
                minutes: Math.floor((totalSeconds % (60 * 60)) / 60),
                seconds: totalSeconds % 60
            };
        }

        return countdown;
    };

    function getMonthAndYear(date: string) {
        const d = new Date(date);
        return `${d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}/${d.getFullYear()}`;
    }

    async function pay() {
        if (newAmountToPay > amountToPay) return toast.error(lang === 'pt' ? 'O montante a pagar não pode ser superior ao montante por liquidar.' : 'The amount to pay cannot be greater than the outstanding amount.');
        if (newAmountToPay <= 0) return toast.error(lang === 'pt' ? 'O montante a pagar tem de ser superior a 0.' : 'The amount to pay must be greater than 0.');
        const data = {
            toPay: amountToPay - newAmountToPay,
            paid: amountPaid + newAmountToPay
        }
        await updateData(toast, 'projects', `${project?.company}-${project?.name}`, data, lang === 'pt' ? 'Projeto atualizado com sucesso.' : 'Project updated successfully.', () => {
            const newProjects = [...context?.projects?.filter((c: any) => c.id !== project?.id), { ...project, ...data }];
            context?.setProjects(newProjects);
        });
        setAmountToPay((prev: number) => prev - newAmountToPay);
        setAmountPaid((prev: number) => prev + newAmountToPay);
        setNewAmountToPay(0);
    }

    function getProgress() {
        if (!project?.sprints || project?.sprints.length === 0) return '20%';
        const totalTasks = project?.sprints.reduce((total: number, sprint: any) => total + sprint.tasks.length, 0);
        const completedTasks = project?.sprints.reduce((total: number, sprint: any) => total + sprint.tasks.filter((task: any) => task.state === 'terminada').length, 0);
        const result = Math.round((completedTasks / totalTasks) * 100);
        if (result < 20) return lang === 'pt' ? "20%" : "20%";
        return `${result}%`;
    }

    function formateDate(date: string) {
        if (!date) return lang === 'pt' ? 'Campo vazio' : 'Empty field';
        const d = new Date(date);
        return `${(d.getDate()).toString().padStart(2, "0")}/${(d.getMonth() + 1).toString().padStart(2, "0")}/${(d.getFullYear()).toString().padStart(2, "0")}`;
    }

    function TimeCard({...props}) {
        return (
            <div className='flex flex-col items-center gap-2 py-4 w-20  text-white border-2 border-[var(--primary)] rounded-[10px] '>
                <p className='text-[1.5rem] text-[var(--black)]'>{props?.time}</p>
                <p className='text-[.8rem] text-[var(--black)] quatro:max-w-full tres:max-w-[3rem] max-w-[2rem] overflow-x-hidden text-ellipsis'>{props?.label}</p>
            </div>
        );
    }

    if (!project || !datasets) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <div className='flex flex-col justify-between w-full gap-6 mb-8 cinco:items-center cinco:flex-row'>
                <p onClick={() => navigate(-1)} className='relative items-center text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>{lang === 'pt' ? 'Voltar' : 'Back'}</span>
                </p>
                {
                    <div className='flex gap-4'>
                        {
                            project?.meetingLink &&
                            <button className='gap-4 small-button bg-[var(--stats)] text-black' onClick={() => window.open(project?.meetingLink, '_blank')}>
                                <p className='text-[var(--black)]'>{lang === 'pt' ? 'Abrir reunião de equipa' : 'Open team meeting'}</p>
                                <FaCalendarAlt className='text-[var(--black)]' />
                            </button>
                        }
                        {
                            isProjectManager &&
                            project?.company !== 'VisionD' &&
                            <div onClick={() => navigate(`/client/${context?.clients.find((ele: any) => ele?.name === project?.company)}`)} className='gap-4 small-button bg-purple'>
                                {lang === 'pt' ? 'Ver Cliente' : 'View Client'}
                                <MdEmail />
                            </div>
                        }
                    </div>
                }
            </div>
            <div className='flex flex-col gap-4 oitocentos:justify-between oitocentos:items-center oitocentos:flex-row'>
                <h1 className='text-[1.5rem] font-bold'>{project.name}</h1>
            </div>
            {
                countdown &&
                <div className='flex items-center justify-center w-full gap-4 py-8'>
                    <TimeCard label={lang === "pt" ? "Meses" : "Months"} time={countdown?.months}/>
                    <TimeCard label={lang === "pt" ? "Dias" : "Days"} time={countdown?.days}/>
                    <TimeCard label={lang === "pt" ? "Horas" : "Hours"} time={countdown?.hours}/>
                    <TimeCard label={lang === "pt" ? "Minutos" : "Minutes"} time={countdown?.minutes}/>
                    <TimeCard label={lang === "pt" ? "Segundos" : "Seconds"} time={countdown?.seconds}/>
                </div>
            }
            {
                project?.faseDoProjeto !== 0 &&
                <div className='flex flex-col items-center w-full gap-4 py-10'>
                    <p>{lang === 'pt' ? 'Progresso' : 'Progress'} - {project?.faseDoProjeto === 1 ? '10%' : project?.faseDoProjeto === 2 ? getProgress() : project?.faseDoProjeto === 3 ? '95%' : project?.faseDoProjeto === 4 ? lang === 'pt' ? 'Concluído' : 'Completed' : '0%'}</p>
                    <div className='relative w-[70%] h-[2rem] bg-[var(--primary)] rounded-full border-2 border-[var(--black)]'>
                        <div style={{ width: project?.faseDoProjeto === 1 ? '10%' : project?.faseDoProjeto === 2 ? getProgress() : project?.faseDoProjeto === 3 ? '95%' : project?.faseDoProjeto === 4 ? '100%' : '0%' }} className={`relative bg-[var(--stats)] rounded-full h-full`}></div>
                    </div>
                </div>
            }
            <div className='flex flex-col gap-4'>
                <div className='flex flex-col oitocentos:flex-row oitocentos:gap-8'>
                    <div className='flex flex-col w-full gap-4'>
                        <p>{lang === 'pt' ? 'Empresa' : 'Company'}:</p>
                        <p className='input'>{project?.company || (lang === 'pt' ? 'Campo vazio' : 'Empty field')}</p>
                        <p>{lang === 'pt' ? 'Fase do projeto' : 'Project phase'}:</p>
                        <select
                            name="faseDoProjeto"
                            value={project?.faseDoProjeto}
                            disabled
                            className='appearance-none cursor-pointer input'>
                            <option value="0">{lang === 'pt' ? 'Campo vazio' : 'Empty field'}</option>
                            <option value="1">{lang === 'pt' ? 'Adjudicação/Estrutura' : 'Adjudication/Structure'}</option>
                            <option value="2">{lang === 'pt' ? 'Desenvolvimento' : 'Development'}</option>
                            <option value="3">{lang === 'pt' ? 'Testes/Lançamento' : 'Testing/Launch'}</option>
                            <option value="4">{lang === 'pt' ? 'Completo' : 'Completed'}</option>
                        </select>
                        <p>{lang === 'pt' ? 'Deadline' : 'Deadline'}:</p>
                        <p className='cursor-pointer input'>{formateDate(project?.deadline)}</p>
                        <p>{lang === 'pt' ? 'Descrição do projeto' : 'Project overview'}:</p>
                        <textarea disabled className='resize-none my-scrollbar input h-[14rem]' value={project?.overview}></textarea>
                    </div>
                    <div className='flex flex-col w-full gap-4'>
                        <p>{lang === 'pt' ? 'Reunião de equipa' : 'Team meeting'}:</p>
                        <p className='input'>{project?.meetingDate ? `${project?.meetingDate || ''}, ${project?.meetingTime || (lang === 'pt' ? "Sem hora marcada" : "No scheduled time")}` : lang === 'pt' ? 'Campo vazio' : 'Empty field'}</p>
                        <p>{lang === 'pt' ? 'Próxima entrega' : 'Next delivery'}:</p>
                        <p className='input'>{project?.nextDelivery || (lang === 'pt' ? 'Campo vazio' : 'Empty field')}</p>
                        {
                            type !== 'Client' &&
                            <>
                                <p>{lang === 'pt' ? 'Probabilidade de financiamento' : 'Funding probability'}:</p>
                                <p className='input'>{project?.chanceOfFunds || (lang === 'pt' ? 'Campo vazio' : 'Empty field')}</p>
                                {
                                    project?.chanceOfFunds !== 'Nula' &&
                                    <>
                                        <p>{lang === 'pt' ? 'Financiamento' : 'Funding'}:</p>
                                        <p className='input'>{project?.funds || (lang === 'pt' ? 'Campo vazio' : 'Empty field')}</p>
                                    </>
                                }
                            </>
                        }
                        <div className='flex flex-col gap-4'>
                            <p className=''>{lang === 'pt' ? 'Requisitos Levantados' : 'Requirements Raised'}</p>
                            <div className='flex gap-4'>
                                <button className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${project?.requesitosLevantados ? 'bg-green' : 'bg-[red]'}`}>
                                </button>
                                <p>{project?.requesitosLevantados ? lang === 'pt' ? 'Sim' : 'Yes' : lang === 'pt' ? 'Não' : 'No'}</p>
                            </div>
                        </div>
                        <div className='flex items-end justify-end h-full'>
                            <div className='flex gap-4'>
                                <button className='small-button bg-[var(--primary)]' onClick={() => navigate(`roadmap`, { state: { project: { project } } })}>{lang === 'pt' ? 'Ver planeamento' : 'View roadmap'}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col w-full my-8 oitocentos:flex-row oitocentos:gap-8'>
                    <div className='flex flex-col w-full gap-8'>
                        <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Produção' : 'Production'}</h2>
                        <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                            {
                                (!agents || agents.length === 0) ? <p>{lang === 'pt' ? 'Nenhum agente' : 'No agents'}</p> :
                                    agents.map((agent: any, index: number) => (
                                        <div onClick={() => (type === 'Admin' || type === 'Agent') ? navigate(`/agent/${agent?.email}`) : {}} key={index} className='small-button bg-[var(--primary)]'>
                                            <p className='text-[1.2rem]'>{agent?.name}</p>
                                            {agent?.email === project?.projectManager && <p className='text-[.8rem] text-[var(--black)] bg-[var(--stats)] rounded-full px-4'>PM</p>}
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                    <div className='flex flex-col w-full gap-8'>
                        <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Sprints' : 'Sprints'}</h2>
                        <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                            {
                                (!project?.sprints || project?.sprints.length === 0) ? <p>{lang === 'pt' ? 'Nenhuma tarefa' : 'No tasks'}</p> :
                                    project?.sprints.sort((a: any, b: any) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime()).map((sprint: any, index: number) => (
                                        <div key={index} className='small-button bg-[var(--primary)] justify-between'>
                                            <p className=' max-w-[18rem] overflow-hidden whitespace-nowrap text-ellipsis'>{sprint?.description}</p>
                                            {project?.sprintAtual === (index + 1).toString() && <p className='text-[.8rem] bg-[var(--stats)] text-[var(--black)] flex justify-center items-center rounded-full px-4'>{lang === 'pt' ? 'atual' : 'current'}</p>}
                                            <p className=''>{formateDate(sprint?.deadline)}</p>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-col w-full h-full my-8 oitocentos:flex-row oitocentos:gap-8'>
                    <div className='flex flex-col w-full gap-8'>
                        <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Progresso de Tarefas' : 'Tasks Progress'}</h2>
                        <MultipleLineChart datasets={datasets} />
                    </div>
                    <div className='flex flex-col w-full h-full gap-8'>
                    </div>
                </div>
                {
                    project?.company !== 'VisionD' && type === 'Admin' &&
                    <div className='flex flex-col w-full gap-4 oitocentos:w-1/2'>
                        <p className='font-bold text-[var(--primary)]'>{lang === 'pt' ? 'Pagamentos' : 'Payments'}:</p>
                        <p>{lang === 'pt' ? 'Tipo de orçamento' : 'Budget type'}:</p>
                        <p className='input'>{project?.tipoOrcamento || (lang === 'pt' ? 'Campo vazio' : 'Empty field')}</p>
                        <div className='flex w-full gap-4'>
                            <div className="flex flex-col gap-4">
                                <p>{lang === 'pt' ? 'Orçamento' : 'Budget'}:</p>
                                <div className={`w-[10rem] h-[10rem] flex justify-center items-center ${amountToPay > 0 ? 'bg-[var(--red)]' : 'bg-green'} text-white rounded-[25px] text-[1.5rem]`}>{isNaN(project?.orcamento) ? 0 : project?.orcamento}€</div>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="flex gap-4">
                                    <div className="flex flex-col gap-4">
                                        <p>{lang === 'pt' ? 'Por liquidar' : 'Outstanding'}:</p>
                                        <p className='input'>{isNaN(amountToPay) ? 0 : amountToPay}€</p>
                                    </div>
                                    <div className="flex flex-col gap-4">
                                        <p>{lang === 'pt' ? 'Liquidado' : 'Paid'}:</p>
                                        <p className='input'>{isNaN(amountPaid) ? 0 : amountPaid}€</p>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4">
                                    <p>{lang === 'pt' ? 'Pagar parte' : 'Pay part'}:</p>
                                    <div className='flex gap-4'>
                                        <input
                                            type="number"
                                            placeholder={lang === 'pt' ? 'Montante' : 'Amount'}
                                            value={newAmountToPay}
                                            onChange={(e) => setNewAmountToPay(parseInt(e.target.value))}
                                            className='input'
                                        />
                                        <button onClick={pay} className='small-button bg-green'>{lang === 'pt' ? 'Pagar' : 'Pay'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className='flex flex-col gap-4 mt-8 seis:flex-row'>
                {(isProjectManager || context?.user?.type === 'Admin') && <button onClick={() => navigate(`/project/${project?.type === 'intern' ? 'intern/' : ''}${project?.id}/edit`)} className='small-button bg-[var(--primary)] gap-4'>{lang === 'pt' ? 'Editar' : 'Edit'} <MdOutlineModeEditOutline /></button>}
                {project?.link && <button onClick={() => window.open(project?.link, '_blank')} className='small-button bg-[var(--primary)] gap-4'>{lang === 'pt' ? 'Ficheiros partilhados' : 'Shared files'} <IoDocumentText /></button>}
                {
                    project?.concluido &&
                    <div className='gap-4 small-button bg-[var(--stats)] text-black'>
                        <p className='text-[var(--black)]'><span className='font-bold'>{lang === 'pt' ? 'Terminado no dia:' : 'Finished on:'}&ensp;</span> {new Date(client?.endDate).toLocaleDateString()}</p>
                    </div>
                }
                {(isProjectManager || context?.user?.type === 'Admin') && <button onClick={() => navigate(`checklist`)} className='small-button bg-[var(--blue)] gap-4'>{lang === 'pt' ? 'Ver Check List' : 'View Check List'} <ImCheckboxChecked /></button>}
            </div>
        </div>
    );
}
