import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsCheck } from 'react-icons/bs/';

import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../../contexts/Context';
import { updateData, create } from '../../../services/server';
import { FaUserCheck } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";
import Loading from '../../Loading';
import { auth } from '../../../services/firebase';

export default function EditClient() {
    const context = useContext(Context);
    const navigate = useNavigate();

    const edit = window.location.pathname.includes('edit');
    const {id = ""} = useParams();
    const [client, setClient] = useState<any>(null);

    const selectRef2 = useRef<HTMLDivElement>(null);

    const [nome, setNome] = useState('');
    const [role, setRole] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [contacto, setContacto] = useState('');
    const [email, setEmail] = useState('');
    const [link, setLink] = useState('');
    const [services, setServices] = useState<string[]>([]);
    const [remarks, setRemarks] = useState('');
    const [consulting, setConsulting] = useState(false);
    const [websiteLink, setWebsiteLink] = useState('');

    const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [isDropdownOpen2, setIsDropdownOpen2] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            if (edit && context?.clients) {
                const client = context?.clients?.find((client: any) => client?.id === id);
                setClient(client);
            }
            setServices(context?.settings.services);
        };
        fetchData();
    }, [edit, context?.settings, context?.sales, context?.agents, context?.user?.email, context?.clients, id]);

    useEffect(() => {
        if (client) {
            setNome(client?.name || '');
            setRole(client?.role || '');
            setResponsavel(client?.responsavel || '');
            setContacto(client?.contacto || '');
            setEmail(client?.email || '');
            setLink(client?.link || '');
            setSelectedAgents(client?.agents || []);
            setSelectedServices(client?.services || []);
            setRemarks(client?.remarks || '');
            setConsulting(client?.consulting || false);
            setWebsiteLink(client?.websiteLink || '');
        }
    }, [client]);

    async function completeClient() {
        if (!window.confirm('Tem a certeza que pretende concluir este cliente?')) return;
        await updateData(toast, 'users', client?.email, { concluido: true, endDate: new Date().toISOString() }, 'Cliente concluído!', () => {
            const newClients = [...context?.clients?.filter((c: any) => c.id !== client?.id), { ...client, concluido: true, endDate: new Date().toISOString()}];
            context?.setClients(newClients);
        });
    }

    function isValidEmail(email: string): boolean {
        const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailPattern.test(email);
    }

    const handleSubmit = async (e: any) => {
        if (!responsavel || !email || !nome)
            return toast.error('Preencha todos os campos.');
        if (!isValidEmail(email)) {
            return toast.error('Email inválido.');
        }
        const data = {
            type: 'Client',
            lead: false,
            name: nome.trim(),
            responsavel: responsavel.trim(),
            contacto: contacto.trim(),
            email: email.trim(),
            link: link.trim(),
            embaixador: edit ? (client?.embaixador || '') : auth?.currentUser?.email,
            services: selectedServices,
            agents: selectedAgents,
            remarks: remarks.trim(),
            active: true,
            consulting,
            websiteLink,
            role,
            creationDate: edit ? (client?.creationDate || '') : new Date().toISOString(),
        };
        if (edit)
            await updateData(toast, 'users', email, data, 'Cliente atualizado com sucesso.', () => {
                const newClients = [...context?.clients?.filter((ele: any) => ele.id !== client?.id), { ...data, id: client?.id }]
                context?.setClients(newClients);
                navigate(-1);
            });
        else
            await create(toast, 'users', email, data, 'Cliente criado com sucesso.', () => {
                const newClients = [...context?.clients, { ...data, id: email }]
                context?.setClients(newClients);
                navigate(-1);
            });
    };

    const handleServiceSelection = (agentName: string) => {
        setSelectedServices((prevSelectedAgents: any) => {
            if (prevSelectedAgents.includes(agentName)) {
                return prevSelectedAgents.filter((name: string) => name !== agentName);
            } else {
                return [...prevSelectedAgents, agentName];
            }
        });
    };

    useEffect(() => {
        function handleClickOutside2(event: any) {
            if (selectRef2.current && !selectRef2.current.contains(event.target)) {
                setIsDropdownOpen2(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside2);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside2);
        };
    }, [selectRef2]);

    if (!services) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <p onClick={() => navigate(-1)} className='relative w-full mb-8 text-left cursor-pointer'>
                <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
            </p>
            <div className='flex items-center justify-between w-full mb-8'>
                <h1 className='text-[1.5rem] font-bold'>{edit ? 'Editar' : 'Novo Cliente'}</h1>
            </div>
            <div className='text-[1.2rem]'>
                <div className='flex flex-col mil:flex-row text-[1rem] gap-4'>
                    <div className='w-full space-y-4'>
                        <div className="flex flex-col gap-2">
                            <p className='mandatory'>Nome da empresa</p>
                            <input
                                type='text'
                                placeholder='Nome da empresa'
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className='mandatory'>Nome do responsável</p>
                            <input
                                type='text'
                                placeholder='Nome do responsável'
                                value={responsavel}
                                onChange={(e) => setResponsavel(e.target.value)}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className=''>Posição do responsável</p>
                            <input
                                type='text'
                                placeholder='Posição do responsável'
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className=''>Contacto</p>
                            <input
                                type='tel'
                                placeholder='Contacto'
                                value={contacto}
                                onChange={(e) => setContacto(e.target.value)}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className=''>Observações</p>
                            <textarea
                                placeholder='Observações'
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                                className='resize-none my-scrollbar input h-[13.5rem]'
                            ></textarea>
                        </div>
                    </div>
                    <div className='w-full space-y-4'>
                        <div className="flex flex-col gap-2">
                            <p className='mandatory'>Email</p>
                            <input
                                type='email'
                                placeholder='Email'
                                value={email}
                                disabled={edit}
                                onChange={(e) => setEmail(e.target.value)}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className=''>Serviços</p>
                            <div
                                className="relative cursor-pointer input"
                                onClick={() => setIsDropdownOpen2(!isDropdownOpen2)}
                                ref={selectRef2}
                            >
                                {selectedServices.length === 0
                                    ? 'Selecione serviços'
                                    : `${selectedServices.length} selecionados`}
                                {isDropdownOpen2 && (
                                    <div className="absolute w-full left-0 top-10 bg-white border-2 border-[var(--black)] rounded-md z-10 h-[15rem] overflow-y-auto my-scrollbar">
                                        {services.map((agent, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleServiceSelection(agent);
                                                }}
                                            >
                                                <div className={`w-4 h-4 border-[1px] border-[var(--black)] rounded-[4px] ${selectedServices.includes(agent) ? 'bg-[var(--primary)]' : ''}`}>
                                                    <span className={`${selectedServices.includes(agent) ? 'flex' : 'hidden'} text-white`}><BsCheck /></span>
                                                </div>
                                                <p className="ml-2 text-sm">{agent}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className='ext-gray-700 '>Embaixador</p>
                            <p className='input'>{context?.user?.name}</p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className='ext-gray-700 '>Link da pasta partilhada</p>
                            <input
                                type='link'
                                placeholder='Link'
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className='ext-gray-700 '>Website Link</p>
                            <input
                                type='text'
                                placeholder='Website Link'
                                value={websiteLink}
                                onChange={(e) => setWebsiteLink(e.target.value)}
                                className='input'
                            />
                        </div>
                        <div className="flex flex-col gap-4">
                            <p className='mandatory'>Quer consultoria</p>
                            <div className='flex gap-4'>
                                <p>Sim</p>
                                <button onClick={() => setConsulting(true)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${consulting ? 'bg-green' : ''} cursor-pointer`}>
                                </button>
                                <p>Não</p>
                                <button onClick={() => setConsulting(false)} className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${!consulting ? 'bg-[red]' : ''} cursor-pointer`}>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex w-full gap-4 mt-8'>
                    <button onClick={handleSubmit} className={`small-button ${edit ? 'bg-[var(--primary)]' : 'bg-green'}`}>{edit ? 'Atualizar' : 'Criar Cliente'}</button>
                    {
                        edit &&
                        <div onClick={completeClient} className='gap-4 small-button bg-[var(--green)]'>
                            Concluir cliente
                            <FaUserCheck />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

