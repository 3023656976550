import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
// import { FaCalendarAlt } from "react-icons/fa";
import { ImBin } from "react-icons/im";
import AddTask from './addTask';
import AddSprint from './addSprint';
import { doc, writeBatch } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { updateData } from '../../../services/server';
import { Context } from '../../../contexts/Context';
import Loading from '../../Loading';
import toast, { Toaster } from "react-hot-toast";
import ProjectCalendar from './schedule/Schedule';
import { FaCalendarAlt } from 'react-icons/fa';

export default function ProjectPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const context = useContext(Context);
    const { id = "" } = useParams();

    const intern = window.location.pathname.includes('intern');

    const [project, setProject] = useState<any>(null);
    const [sprints, setSprints] = useState<any>(null);

    const [taskSelected, setTaskSelected] = useState<any>(null);
    const [sprintSelected, setSprintSelected] = useState<any>(null);

    const [sprintAtual, setSprintAtual] = useState('');
    const [lang, setLang] = useState('pt');

    const [taskForm, setTaskForm] = useState(false);
    const [sprintForm, setSprintForm] = useState(false);
    const [isProjectManager, setIsProjectManager] = useState(false);
    const [view, setView] = useState<string>("sprints");

    useEffect(() => {
        if (intern) {
            const project = context?.internProjects?.find((c: any) => c.id === id);
            setProject(project);
        } else {
            const project = context?.projects?.find((c: any) => c.id === id);
            setProject(project);
        }
        setLang(localStorage.getItem('lang') || 'pt');
    }, [context?.projects, location.pathname, intern, context?.internProjects, id]);

    useEffect(() => {
        if (project?.projectManager === context?.user.email)
            setIsProjectManager(true);
    }, [project?.projectManager, context?.user.email]);

    useEffect(() => {
        if (project?.sprints) {
            const newSprints = project.sprints.sort((a: any, b: any) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime());
            setSprints(newSprints);
            setSprintAtual(project?.sprintAtual)
        }
    }, [project?.sprints, project?.sprintAtual]);

    useEffect(() => {
        if (!sprintForm)
            setSprintSelected(null);
    }, [sprintForm]);

    useEffect(() => {
        if (!taskForm) {
            setSprintSelected(null);
            setTaskSelected(null);
        }
    }, [taskForm]);

    async function save() {
        try {
            const batch = writeBatch(db);
            sprints.forEach((sprint: any, index: number) => {
                const sprintRef = doc(db, `projects/${project.id}/sprints`, sprint?.id || `sprint${index + 1}`);
                batch.set(sprintRef, sprint);
            });
            await batch.commit();
            await updateData(toast, 'projects', `${project?.company}-${project?.name}`, { sprintAtual: sprintAtual || 0 }, 'Planeamento atualizado com sucesso!', () => {
                const newProjects = [...(intern ? context?.internProjects : context?.projects)?.filter((c: any) => c.id !== project?.id), { ...project, sprintAtual: sprintAtual }];
                if (!intern) context?.setProjects(newProjects);
                else context?.setInternProjects(newProjects);
            });
        } catch (err) {
            console.log(err);
            toast.error('Erro ao salvar alterações.');
        }
    }

    function deleteSprint(index: number) {
        if (!window.confirm('Tem a certeza que deseja excluir este sprint?')) return;
        sprints.splice(index, 1);
    }

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    function getState(state: string) {
        if (lang === 'pt') return state;
        switch (state) {
            case 'não começou':
                return "not started"
            case 'em progresso':
                return 'on going';
            case 'em revisão':
                return 'in review';
            case 'terminada':
                return 'done';
            default:
                return state;
        }
    }

    if (!project) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full h-full p-4 quatrocentos:p-8'>
            <Toaster />
            <div className='flex flex-col justify-between w-full gap-6 mb-8 seis:items-center seis:flex-row '>
                <p onClick={() => navigate(-1)} className='relative items-center text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>{lang === 'pt' ? 'Voltar' : 'Back'}</span>
                </p>
                <div className='flex flex-col gap-4 cinco:items-center cinco:flex-row'>
                    {
                        view === "sprints" ?
                        <button onClick={() => setView("schedule")} className='gap-4 small-button bg-[var(--stats)] text-black'>
                            <p className='text-[var(--black)]'>{lang === 'pt' ? 'Ver calendário' : 'Calendar View'}</p>
                            <FaCalendarAlt className='text-[var(--black)]' />
                        </button> 
                        :
                        <button onClick={() => setView("sprints")} className='gap-4 small-button bg-[var(--stats)] text-black'>
                            <p className='text-[var(--black)]'>{lang === 'pt' ? 'Ver backlog' : 'Backlog View'}</p>
                            <FaCalendarAlt className='text-[var(--black)]' />
                        </button>
                    }
                    {
                        (isProjectManager || context?.user?.type === 'Admin') &&
                        <div className='flex gap-4'>
                            <button onClick={() => { setSprintSelected(null); setSprintForm(true) }} className='small-button bg-[var(--primary)]'>{lang === 'pt' ? '+ Novo Sprint' : '+ New Sprint'}</button>
                        </div>
                    }
                </div>
            </div>
            <div className='flex flex-col gap-4 oitocentos:justify-between oitocentos:items-center oitocentos:flex-row'>
                <h1 className='text-[1.5rem] font-bold'>{project.name} - {lang === 'pt' ? 'Plano de Desenvolvimento' : 'Development Plan'}</h1>
            </div>
            {
                view === "sprints" ?
                <>
                    <div className='flex flex-col justify-between gap-8 oitocentos:flex-row'>
                        <div className='flex h-full gap-4 pb-8 mt-8 overflow-x-auto my-scrollbar'>
                            {
                                sprints && sprints.length !== 0 ? sprints.map((sprint: any, sprintIndex: number) => {
                                    return (
                                        <div key={sprintIndex} className='h-[28rem] text-white bg-[var(--sprintBg)] p-6 rounded-[15px] flex flex-col gap-4 justify-between'>
                                            <div className='flex flex-col gap-4 overflow-x-hidden overflow-y-auto my-scrollbar'>
                                                <div className='flex justify-between gap-8 pb-4 border-b-2 border-white shrink-0'>
                                                    <p className='max-w-[18rem] overflow-hidden whitespace-nowrap text-ellipsis'>{sprint?.description}</p>
                                                    <p className='font-bold text-center uppercase'>{formateDate(sprint?.deadline)}</p>
                                                </div>
                                                <div className='flex flex-col gap-4 overflow-x-hidden overflow-y-auto my-scrollbar'>
                                                    {
                                                        sprint?.tasks.length !== 0 ? sprint?.tasks.map((task: any, taskIndex: number) => {
                                                            return (
                                                                <div onClick={() => { setSprintSelected({ ...sprint, sprintIndex }); setTaskSelected({ ...task, taskIndex }); setTaskForm(true) }} key={taskIndex} className='flex justify-between p-2 gap-8 cursor-pointer border-[transparent] border-2 hover:border-gray rounded-[10px]'>
                                                                    <p className='whitespace-nowrap'>{task?.task}</p>
                                                                    <p className='text-[.8rem] bg-[var(--stats)] text-black flex justify-center items-center rounded-full px-4 whitespace-nowrap'>{getState(task?.state)}</p>
                                                                </div>
                                                            )
                                                        }) :
                                                            <p className='w-full text-center'>{lang === 'pt' ? 'Nenhuma tarefa' : 'No tasks'}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className='flex justify-end w-full gap-2'>
                                                {isProjectManager && <button onClick={() => { deleteSprint(sprintIndex) }} className='p-1 rounded-full bg-[var(--red)]'><ImBin /></button>}
                                                {isProjectManager && <button onClick={() => { setSprintSelected({ ...sprint, sprintIndex }); setSprintForm(true); }} className='p-1 rounded-full bg-[var(--primary)]'><MdOutlineModeEditOutline className='text-[var(--stats)]' /></button>}
                                                <button onClick={() => { setTaskForm(false); setTaskSelected(null); setSprintSelected({ ...sprint, sprintIndex }); setTaskForm(true) }} className='p-1 rounded-full bg-green'><IoMdAdd /></button>
                                            </div>
                                        </div>
                                    )
                                }) :
                                    <p className='w-full text-center'>{lang === 'pt' ? 'Nenhum Sprint criado' : 'No sprints created'}</p>
                            }
                        </div>
                        {
                            sprintSelected && (taskSelected || taskForm) &&
                            <AddTask project={project} sprints={sprints} setSprints={setSprints} edit={!isProjectManager} setTaskForm={setTaskForm} task={taskSelected} sprint={sprintSelected} lang={lang}/>
                        }
                    </div>
                    <div className='flex flex-col-reverse gap-4 mt-4 cinco:flex-row'>
                        <button className='small-button bg-green' onClick={save}>{lang === 'pt' ? 'Salvar alterações' : 'Save changes'}</button>
                        {
                            isProjectManager &&
                            <select value={sprintAtual} onChange={(e) => setSprintAtual(e.target.value)} className='input !w-[10rem]'>
                                <option className='' value="">{lang === 'pt' ? 'Sprint Atual' : 'Current Sprint'}</option>
                                {
                                    sprints && sprints.length !== 0 ? sprints.map((_: any, index: number) => {
                                        return (
                                            <option key={index} className='' value={index + 1}>{lang === 'pt' ? `Sprint ${index + 1}` : `Sprint ${index + 1}`}</option>
                                        )
                                    }) :
                                        <p className='w-full text-center'>{lang === 'pt' ? 'Nenhum resultado' : 'No results'}</p>
                                }
                            </select>
                        }
                    </div>
                    {sprintForm && <AddSprint sprints={sprints} setSprints={setSprints} setSprintForm={setSprintForm} sprint={sprintSelected} lang={lang} />}
                </>
                :
                <ProjectCalendar sprints={sprints} />
            }
        </div>
    );
}
