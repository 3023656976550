import React, { useEffect, useState } from 'react';

export default function PartnerProfile({ user }: { user: any, setUser: any }) {
    const [nextMeeting, setNextMeeting] = useState<any>(null);

    useEffect(() => {
        if (user?.meetings && user?.meetings.length > 0) {
            const today = new Date();
            const meetings = user?.meetings.filter((m: any) => new Date(m.date) >= today);
            const next = meetings.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime())[0];
            setNextMeeting(next);
        } else {
            setNextMeeting(null);
        }
    }, [user?.meetings]);

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    return (
        <div className='flex flex-col w-full gap-8 pb-8 mt-8'>
            <div className="flex flex-col justify-between gap-4 cinco:items-center cinco:flex-row">
                <p className='font-bold'>Detalhes do Parceiro</p>
            </div>
            <div className='grid w-full grid-cols-1 gap-8 oitocentos:grid-cols-2'>
                <div className='flex flex-col w-full gap-2'>
                    <p>Tipo de Parceiro</p>
                    <p className='input'>{user?.partnerType || 'Não definido'}</p>
                </div>
                <div className='flex flex-col w-full gap-2'>
                    <p>Próxima reunião</p>
                    <a href={nextMeeting?.link} className='input'>{formateDate(nextMeeting?.date) || 'Nenhuma reunião agendada'}</a>
                </div>
            </div>
        </div>
    );
}

