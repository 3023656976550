import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdEmail } from "@react-icons/all-files/md/MdEmail";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";
import { FaCalendarAlt, FaUserCheck } from "react-icons/fa";
import { Context } from '../../../contexts/Context';
import Loading from '../../Loading';
import { updateData } from '../../../services/server';
import toast, { Toaster } from "react-hot-toast";
import { ImCheckboxChecked } from 'react-icons/im';

export default function ClientPage() {
    const navigate = useNavigate();
    const context = useContext(Context);
    const { id = "" } = useParams();

    const [client, setClient] = useState<any>({});
    const [projects, setProjects] = useState<any>([]);
    const [nextMeeting, setNextMeeting] = useState('');
    const [lang, setLang] = useState('pt');

    useEffect(() => {
        const client = context?.clients?.find((client: any) => client?.id === id);
        setClient(client);
        setLang(localStorage.getItem('lang') || 'pt');
    }, [context?.clients, id]);

    useEffect(() => {
        if (context?.projects && client?.name) {
            const allProjects = context?.projects;
            if (!allProjects) return;
            const clientsProjects = allProjects.filter((ele: any) => ele?.company === client?.name);
            setProjects(clientsProjects);
        }
    }, [context?.projects, client?.name, projects.length]);

    useEffect(() => {
        if (client?.meetings && client?.meetings.length > 0) {
            const meetings = client?.meetings;
            const next = meetings.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime())[0];
            setNextMeeting(next?.date);
        } else {
            setNextMeeting('');
        }
    }, [client?.meetings]);

    async function reactivate() {
        if (!window.confirm('Tem a certeza que pretende ativar este cliente?')) return;
        await updateData(toast, 'users', client?.email, { concluido: false }, 'Cliente reactivado!', () => {
            const newClients = [...context?.clients?.filter((c: any) => c.id !== client?.id), { ...client, concluido: false }];
            context?.setClients(newClients);
        });
    }

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    if (!client) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <div className='flex flex-col justify-between w-full gap-6 mb-8 seis:items-center seis:flex-row'>
                <p onClick={() => navigate(-1)} className='relative text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>{lang === 'pt' ? 'Voltar' : 'Back'}</span>
                </p>
                {
                    false && client?.embaixador &&
                    <div className='flex flex-col items-center justify-center gap-2'>
                        <p className='text-[.8rem]'>{lang === 'pt' ? 'Embaixador' : 'Ambassador'}</p>
                        <p className='input'>{client?.embaixador || '-'}</p>
                    </div>
                }
                <div className='flex flex-col gap-4 cinco:flex-row'>
                    {
                        !nextMeeting ?
                            <button className='gap-4 small-button bg-[var(--stats)] text-black' onClick={() => navigate('/schedule', { state: { user: { user: client } } })}>
                                <p className='text-[var(--black)]'>{lang === 'pt' ? 'Agendar reunião' : 'Schedule meeting'}</p>
                                <FaCalendarAlt className='text-[var(--black)]' />
                            </button> :
                            <button className='gap-4 small-button bg-[var(--stats)] text-black' onClick={() => nextMeeting ? toast(`${lang === 'pt' ? 'Reunião agendada para o dia' : 'Meeting scheduled for'} ${formateDate(nextMeeting)}`) : {}}>
                                <p className='text-[var(--black)]'>{nextMeeting ? formateDate(nextMeeting) : lang === 'pt' ? 'Nenhum meeting' : 'No meeting'}</p>
                                <FaCalendarAlt className='text-[var(--black)]' />
                            </button>
                    }
                    <div onClick={() => navigate(`/contact/${client?.email}/${client?.contacto}`)} className='gap-4 small-button bg-purple'>
                        {lang === 'pt' ? 'Contactar' : 'Contact'}
                        <MdEmail />
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 mb-8 oitocentos:justify-between oitocentos:items-center oitocentos:flex-row'>
                <h1 className='text-[1.5rem] font-bold'>{client.name}</h1>
            </div>
            <div className='flex flex-col gap-4'>
                <div className='flex flex-col oitocentos:flex-row oitocentos:gap-8'>
                    <div className='flex flex-col w-full gap-4'>
                        <p>{lang === 'pt' ? 'Responsável' : 'Responsible'}:</p>
                        <p className='input'>{client?.responsavel || '-'}</p>
                        <p>{lang === 'pt' ? 'Posição' : 'Position'}:</p>
                        <p className='input'>{client?.role || '-'}</p>
                        <p>Email:</p>
                        <p onClick={() => navigate(`/contact/${client?.email}`)} className='cursor-pointer input'>{client?.email || '-'}</p>
                        <p>{lang === 'pt' ? 'Contacto' : 'Contact'}:</p>
                        <a href={`tel:${client?.contacto}`} className='input'>{client?.contacto || '-'}</a>
                        <p>Website:</p>
                        <p onClick={() => window.open(client?.websiteLink, '_blank')} className='cursor-pointer input'>{client?.websiteLink || '-'}</p>
                    </div>
                    <div className='flex flex-col w-full gap-4'>
                        <p>{lang === 'pt' ? 'Próxima reunião' : 'Next meeting'}:</p>
                        <p className='input'>{nextMeeting ? formateDate(nextMeeting) : '-'}</p>
                        <p>{lang === 'pt' ? 'Observações' : 'Remarks'}:</p>
                        <textarea disabled className='resize-none my-scrollbar input h-[10rem]' value={client?.remarks}></textarea>
                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-col gap-4'>
                                <p className=''>{lang === 'pt' ? 'Com consultoria' : 'With consulting'}</p>
                                <div className='flex gap-4'>
                                    <button className={`w-5 h-5 border-[1px] border-[var(--black)] rounded-[4px] ${client?.consulting ? 'bg-green' : 'bg-[red]'}`}>
                                    </button>
                                    <p>{client?.consulting ? 'Yes' : 'No'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col w-full mt-4 oitocentos:flex-row oitocentos:gap-8'>
                    <div className='flex flex-col w-full gap-8'>
                        <div className='flex items-center justify-between pr-4'>
                            <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Projetos' : 'Projects'}</h2>
                            {context?.user?.type === 'Admin' && <button className='small-button bg-[var(--primary)]' onClick={() => navigate(`/project/create/${client?.email}`)}>{lang === 'pt' ? 'Novo projeto' : 'New project'}</button>}
                        </div>
                        <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                            {
                                (!projects || projects.length === 0) ? <p>{lang === 'pt' ? 'Nenhum projeto' : 'No projects'}</p> :
                                    projects.map((project: any, index: number) => (
                                        <div onClick={() => (context?.user?.type === 'Admin' || context?.user?.type === 'Client') ? navigate(`/project/${client?.name}-${project?.name}`) : {}} key={index} className='small-button bg-[var(--primary)]'>
                                            <p className='text-[1.2rem]'>{project?.name}</p>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                    <div className='flex flex-col w-full gap-8'>
                        <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Serviços ativos' : 'Active services'}</h2>
                        <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                            {
                                (!client?.services || client?.services.length === 0) ? <p>{lang === 'pt' ? 'Nenhum serviço' : 'No services'}</p> :
                                    client?.services.map((services: any, index: number) => (
                                        <div key={index} className='small-button bg-[var(--primary)]'>
                                            <p className='text-[1.2rem]'>{services}</p>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 mt-8 seis:flex-row'>
                {context?.user?.type === 'Admin' && <button onClick={() => navigate(`/client/${client?.id}/edit`)} className='small-button bg-[var(--primary)] gap-4'>{lang === 'pt' ? 'Editar' : 'Edit'} <MdOutlineModeEditOutline /></button>}
                {client?.link && <button onClick={() => window.open(client?.link, '_blank')} className='small-button bg-[var(--primary)] gap-4'>{lang === 'pt' ? 'Ficheiros partilhados' : 'Shared files'} <IoDocumentText /></button>}
                {
                    client?.concluido &&
                    <>
                        <button onClick={() => reactivate()} className='small-button bg-[var(--green)] gap-4'>{lang === 'pt' ? 'Ativar cliente' : 'Activate client'} <FaUserCheck /></button>
                        <div className='gap-4 small-button bg-[var(--stats)] text-black'>
                            <p className='text-[var(--black)]'><span className='font-bold'>{lang === 'pt' ? 'Terminado no dia:' : 'Finished on:'}&ensp;</span> {new Date(client?.endDate).toLocaleDateString()}</p>
                        </div>
                    </>
                }
                {(context?.user?.type === 'Admin' || context?.user?.type === 'Sales') && <button onClick={() => navigate(`checklist`)} className='small-button bg-[var(--blue)] gap-4'>{lang === 'pt' ? 'Ver Check List' : 'View Check List'} <ImCheckboxChecked /></button>}
            </div>
        </div>
    );
}
