import React, { useContext, useEffect, useState } from 'react';
import { FaPhone } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import { Context } from '../contexts/Context';
import { IoDocumentText } from 'react-icons/io5';
import ChoseTemplate from './emailsTemplate/ChoseTemplate';
import emailjs from 'emailjs-com';

export default function Contact() {
    const { email = "", tel = "" } = useParams();
    const support = window.location.href.includes('support');
    const context = useContext(Context);
    const navigate = useNavigate();

    const [sendTo, setSendTo] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [lang, setLang] = useState('pt');
    const [choseTemplate, setChoseTemplate] = useState(false);
    const [template, setTemplate] = useState<any>(null);

    useEffect(() => {
        if (support)
            setSendTo('geral@visiond.pt');
        else
            setSendTo(email);
        setLang(localStorage.getItem('lang') || 'pt');
    }, [support, email]);

    useEffect(() => {
        if (template) {
            if (template) {
                setSubject(template.subject);
                setMessage(template.message);
            }
        }
    }, [template]);

    const sendEmail = () => {
        if (!sendTo || subject === '' || message === '')
            return toast.error('Preencha todos os campos.');
        const emailParams = {
            name: context?.user?.name,
            subject: subject,
            message: message.replace(/\n/g, '<br>'),
            email: sendTo,
        }
        emailjs.send('service_t3qxaik', 'template_5gefxo5', emailParams, 'jeStFGZ-400kFvT_-');
        toast.success('Email enviado com sucesso!');
        navigate(-1);
    };

    const call = () => {
        if (tel === '')
            return toast.error('Telefone não disponível.');
        window.location.href = `tel:${tel}`;
    }

    return (
        <div className='relative w-full min-h-screen p-8'>
            <div className='relative flex flex-col gap-8 z-1'>
                <Toaster />
                <div className="flex items-center justify-between">
                    <p onClick={() => navigate(-1)} className='relative w-full text-left cursor-pointer'>
                        <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
                    </p>
                    <button onClick={call} className='w-[8rem] flex justify-center gap-4 small-button bg-green'>Ligar<FaPhone /></button>
                </div>
                <div className='flex flex-col w-full h-full '>
                    <h1 className='text-[1.5rem] mb-8'>
                        {lang === 'pt' ? 'Contactar' : 'Contact'}
                    </h1>
                    <div className='flex flex-col w-full gap-4'>
                        <p>
                            {lang === 'pt' ? 'Enviar para:' : 'Send to:'}
                        </p>
                        <input type="email" disabled value={sendTo} className='input' placeholder='| Email' />
                        <p>
                            {lang === 'pt' ? 'Assunto:' : 'Subject:'}
                        </p>
                        <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className='input' placeholder='| Assunto' />
                        <p>
                            {lang === 'pt' ? 'Mensagem:' : 'Message:'}
                        </p>
                        <textarea value={message} onChange={(e) => setMessage(e.target.value)} className='input h-[13rem] resize-none my-scrollbar' placeholder='| Mensagem' />
                        <div className='flex flex-col gap-4 quatro:flex-row'>
                            <button onClick={sendEmail} className='flex justify-center small-button bg-[var(--primary)]'>
                                <p className='w-full text-center'>
                                    {lang === 'pt' ? 'Enviar' : 'Send'}
                                </p>
                            </button>
                            {context?.user?.type === 'Admin' && (
                                <button onClick={() => setChoseTemplate(true)} className='flex justify-center small-button bg-[var(--primary)]'>
                                    <p className='w-full text-center'>
                                        {lang === 'pt' ? 'Escolher template' : 'Choose template'}
                                    </p>
                                    <IoDocumentText />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {choseTemplate && <ChoseTemplate setTemplate={setTemplate} setChoseTemplate={setChoseTemplate} />}
        </div>
    );
}
