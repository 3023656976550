import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdEmail } from "@react-icons/all-files/md/MdEmail";

import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";
import { FaUserCheck } from "react-icons/fa";
import { FaPersonCircleMinus } from "react-icons/fa6";
import { Context } from '../../contexts/Context';
import { updateData } from '../../services/server';
import Loading from '../Loading';
import toast, { Toaster } from "react-hot-toast";
import { ImCheckboxChecked } from 'react-icons/im';

export default function PartnerPage() {
    const navigate = useNavigate();
    const context = useContext(Context);
    const { id = "" } = useParams();

    const [partner, setPartner] = useState<any>({});

    const [nextMeeting, setNextMeeting] = useState('');

    useEffect(() => {
        const partner = context?.partners?.find((partner: any) => partner?.id === id);
        setPartner(partner);
    }, [context?.partners, id]);

    useEffect(() => {
        if (partner?.meetings && partner?.meetings.length > 0) {
            const meetings = partner?.meetings;
            const today = new Date();
            const next = meetings
                .filter((meeting: any) => new Date(meeting.date) >= today)
                .sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime())[0];
            setNextMeeting(next?.date);
        } else {
            setNextMeeting('');
        }
    }, [partner?.meetings]);

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    async function changeToPartner() {
        if (!window.confirm('Tem a certeza que esta parceria está fechada?')) return;
        await updateData(toast, 'partners', partner?.email, { active: true, lead: false }, 'Nova parceria criada!', () => {
            context?.setPartners((prev: any) => [...prev, { ...partner, active: true, lead: false }]);
        });
    }

    async function deactivate() {
        await updateData(toast, 'partners', partner?.email, { active: false }, `${partner?.lead ? 'Lead' : 'Parceria'} desativada com sucesso.`, () => {
            context?.setPartners((prev: any) => [...prev, { ...partner, active: false }]);
        });
    }

    async function activate() {
        await updateData(toast, 'partners', partner?.email, { active: true }, `${partner?.lead ? 'Lead' : 'Parceria'} ativada com sucesso.`, () => {
            context?.setPartners((prev: any) => [...prev, { ...partner, active: true }]);
        });
    }


    if (!partner) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <div className='flex flex-col justify-between w-full gap-6 mb-8 seis:items-center seis:flex-row'>
                <p onClick={() => navigate(-1)} className='relative text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
                </p>
                <div className='flex flex-col gap-4 cinco:flex-row'>
                    {
                        partner?.email !== context?.user?.email &&
                        <>
                            {
                                !nextMeeting ?
                                    <button className='gap-4 small-button bg-[var(--stats)] text-black' onClick={() => navigate('/schedule/partner', { state: { user: { user: partner } } })}>
                                        <p className='text-[var(--black)]'>Agendar reunião</p>
                                        <FaCalendarAlt className='text-[var(--black)]' />
                                    </button> :
                                    <button className='gap-4 small-button bg-[var(--stats)] text-black' onClick={() => nextMeeting ? toast(`Reunião agendada para o dia ${formateDate(nextMeeting)}`) : {}}>
                                        <p className='text-[var(--black)]'>{nextMeeting ? formateDate(nextMeeting) : 'Nenhum meeting'}</p>
                                        <FaCalendarAlt className='text-[var(--black)]' />
                                    </button>
                            }
                            <div onClick={() => navigate(`/contact/${partner?.email}/${partner?.contacto}`)} className='gap-4 small-button bg-purple'>
                                Contactar
                                <MdEmail />
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className='flex flex-col gap-4 mb-8 oitocentos:justify-between oitocentos:items-center oitocentos:flex-row'>
                <h1 className='text-[1.5rem] font-bold'>{partner.name}</h1>
            </div>
            <div className='flex flex-col gap-4'>
                <div className='flex flex-col oitocentos:flex-row oitocentos:gap-8'>
                    <div className='flex flex-col w-full gap-4'>
                        <p>Responsável:</p>
                        <p className='input'>{partner?.responsavel || 'Campo vazio'}</p>
                        <p>Email:</p>
                        <p onClick={() => navigate(`/contact/${partner?.email}`)} className='cursor-pointer input'>{partner?.email || 'Campo vazio'}</p>
                        <p>Contacto:</p>
                        <a href={`tel:${partner?.contacto}`} className='input'>{partner?.contacto || 'Campo vazio'}</a>
                        {
                            partner?.lead &&
                            <>
                                <p>Website:</p>
                                <p onClick={() => window.open(partner?.websiteLink, '_blank')} className='cursor-pointer input'>{partner?.websiteLink || 'Campo vazio'}</p>
                            </>
                        }
                        <p>Observações:</p>
                        <textarea disabled value={partner?.remarks} className='resize-none my-scrollbar input h-[10rem]' >{partner?.remarks}</textarea>
                    </div>
                    <div className='flex flex-col w-full gap-4'>
                        <p>Próxima reunião:</p>
                        <p className='input'>{nextMeeting ? formateDate(nextMeeting) : 'Campo vazio'}</p>
                        <p>Posição:</p>
                        <p className='input'>{partner?.role || 'Campo vazio'}</p>
                        <p>Tipo de parceiro:</p>
                        <p className='input'>{partner?.partnerType || 'Campo vazio'}</p>
                        <p>Canais de contacto:</p>
                        <p className='input'>{
                            partner?.channels && partner?.channels.length ? partner?.channels?.map((channel: string, index: number) => (
                                <span key={index}>{channel}{index !== partner?.channels.length - 1 && ', '}</span>
                            )) : 'Campo vazio'
                        }</p>
                        {
                            !partner?.lead &&
                            <>
                                <p>Website:</p>
                                <p onClick={() => window.open(partner?.websiteLink, '_blank')} className='cursor-pointer input'>{partner?.websiteLink || 'Campo vazio'}</p>
                            </>
                        }
                        {
                            partner?.lead && partner?.contactado &&
                            <>
                                <p>Vezes que foi contactado:</p>
                                <p className='input'>{partner?.timesSpoken || 'Campo vazio'}</p>
                                <p>Nível de interesse:</p>
                                <p className='input'>{partner?.levelOfInteress || 'Campo vazio'}</p>
                                <p>Tempo de resposta:</p>
                                <p className='input'>{partner?.answerTime || 'Campo vazio'}</p>
                            </>
                        }
                    </div>
                </div>
                <div className='flex flex-col min-h-[15rem] pt-4 mt-4 border-t-2 border-gray gap-4'>
                    <p className='text-[1.2rem]'>Conteúdo</p>
                    <div className='flex flex-col gap-4 seis:flex-row'>
                        {!partner?.lead && <button className='small-button bg-[var(--primary)]' onClick={() => navigate(`roadmap`, { state: { partner: { partner } } })}>Ver planeamento</button>}
                        <button className='small-button bg-[var(--primary)]' onClick={() => window.open(`https://visiond.pt/partner?partnerName=${partner?.name}`, '_blank')}>Sobre a parceria</button>
                    </div>
                    <div>
                        {partner?.link && <button onClick={() => window.open(partner?.link, '_blank')} className='small-button bg-[var(--primary)] gap-4'>Ficheiros partilhados <IoDocumentText /></button>}
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 mt-8 seis:flex-row'>
                {
                    partner?.email !== context?.user?.email &&
                    <>
                        <button onClick={() => navigate(`/partner/${partner?.lead ? 'lead/' : ''}${partner?.id}/edit`)} className='small-button bg-[var(--primary)] gap-4'>Editar <MdOutlineModeEditOutline /></button>
                        {
                            partner?.lead &&
                            <div onClick={changeToPartner} className='gap-4 small-button bg-[var(--green)]'>
                                Parceria fechada
                                <FaUserCheck />
                            </div>

                        }
                        {
                            partner?.active ?
                                <button onClick={deactivate} className='small-button bg-[var(--red)] gap-4'>Desativar {partner?.lead ? 'lead' : 'parceria'} <FaPersonCircleMinus /></button> :
                                <>
                                    {
                                        (!partner?.lead || partner?.contactado) &&
                                        <button onClick={activate} className='small-button bg-[var(--green)] gap-4'>Ativar {partner?.lead ? 'lead' : 'parceria'} <FaUserCheck /></button>
                                    }
                                </>
                        }
                        {(context?.user?.type === 'Admin' || context?.user?.type === 'RH') && <button onClick={() => navigate(`checklist`)} className='small-button bg-[var(--blue)] gap-4'>Ver Check List <ImCheckboxChecked /></button>}
                    </>
                }
            </div>
        </div>
    );
}

