export const fullMonthWidth = () => "100%";

export const widthFromStartToEndOfMonth = (startDate: Date, month: string) => {
  const [monthNumber, year] = month.split("/").map(Number);
  const daysInMonth = new Date(year, monthNumber, 0).getDate();
  const startDay = startDate.getDate();
  const percent = ((daysInMonth - startDay + 1) / daysInMonth) * 100;
  return `${percent}%`;
};

export const widthFromBeginningOfMonthToDeadline = (endDate: Date, month: string) => {
  const daysInMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();
  const percent = (endDate.getDate() / daysInMonth) * 100;
  return `${percent}%`;
};

export const widthFromStartToDeadline = (startDate: Date, endDate: Date, month: string) => {
  const daysInMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate();
  const startDay = startDate.getDate();
  const endDay = endDate.getDate();
  const percent = ((endDay - startDay + 1) / daysInMonth) * 100;
  console.log(percent)
  return `${percent}%`;
};
