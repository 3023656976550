import React, { useEffect, useState } from 'react';
import { ImBin } from 'react-icons/im';
import { IoMdClose } from 'react-icons/io';
import toast, { Toaster } from "react-hot-toast";

export default function AddTask({ ...props }: any) {

    const [taskName, setTaskName] = useState('');
    const [agent, setAgent] = useState('');
    const [state, setState] = useState('não começou');
    const [startDate, setStartDate] = useState('');
    const [deadline, setDeadline] = useState('');
    const [points, setPoints] = useState(3);
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (props?.task) {
            setTaskName(props?.task.task);
            setAgent(props?.task.agent);
            setStartDate(props?.task.startDate);
            setDeadline(props?.task.deadline);
            setPoints(props?.task.points);
            setState(props?.task.state);
            setDescription(props?.task.description);
        }
    }, [props?.task]);

    async function addTask() {
        if (!taskName || points === 0 || !state)
            return toast.error('Preencha todos os campos.');
        const task = {
            task: taskName,
            agent: agent,
            state: state,
            startDate: startDate || (state === 'em progresso' ? new Date().toISOString() : ""),
            deadline: deadline || props.sprints[props?.sprint.sprintIndex]?.deadline,
            points: points,
            description: description,
        };
        props?.setSprints((prevSprints: any) => {
            let newSprints = [...prevSprints];
            newSprints[props?.sprint?.sprintIndex].tasks.push(task);
            return newSprints;
        });
        props?.setTaskForm(false);
    }

    function editTask() {
        if (!taskName || points === 0 || !deadline || !state)
            return toast.error('Preencha todos os campos.');
        const task = {
            task: taskName,
            agent: agent,
            state: state,
            points: points,
            startDate: startDate || (state === 'em progresso' ? new Date().toISOString() : ""),
            deadline: deadline,
            description: description,
        };
        props.sprints[props?.sprint.sprintIndex].tasks[props?.task.taskIndex] = task;
        props?.setTaskForm(false);
    }

    function deleteTask() {
        if (!window.confirm('Tem a certeza que deseja excluir esta tarefa?')) return;
        const newTasks = props.sprints[props?.sprint.sprintIndex].tasks;
        newTasks.splice(props?.task.taskIndex, 1);
        const newSprints = props?.sprints;
        newSprints[props?.sprint.sprintIndex].tasks = newTasks;
        props?.setSprints(newSprints);
        props?.setTaskForm(false);
    }

    return (
        <div className='w-full oitocentos:w-[30rem] oitocentos:pl-8 mt-4 oitocentos:border-l-2 border-[var(--black)] my-scrollbar overflow-y-auto my-scrollbar'>
            <Toaster />
            <div className='flex flex-col gap-4 oitocentos:pr-4 h-full oitocentos:h-[30rem]'>
                <div className='flex justify-end w-full gap-4'>
                    {props?.task && <button onClick={() => { editTask() }} className='px-4 rounded-full bg-[var(--primary)] text-white'>{props?.lang === 'portuguese' ? 'atualizar' : 'update'}</button>}
                    <button onClick={() => { props?.setTaskForm(false); }} className='p-1 text-white rounded-full bg-[var(--primary)]'><IoMdClose /></button>
                </div>
                {
                    props?.task ?
                        <h1 className='text-[1.2rem] font-bold'>{props?.lang === 'portuguese' ? 'Tarefa' : 'Task'}</h1>
                        :
                        <h1 className='text-[1.2rem] font-bold'>{props?.lang === 'portuguese' ? 'Nova Tarefa' : 'New Task'}</h1>
                }
                <div className='flex flex-col gap-2'>
                    <p className='mandatory'>{props?.lang === 'portuguese' ? 'Tarefa:' : 'Task:'}</p>
                    <input className='input' type='text' value={taskName} onChange={(e) => setTaskName(e.target.value)} placeholder={props?.lang === 'portuguese' ? 'Tarefa' : 'Task'} />
                    <p className=''>{props?.lang === 'portuguese' ? 'Encarregado:' : 'Assignee:'}</p>
                    <select value={agent} onChange={(e) => setAgent(e.target.value)} className='input'>
                        <option className='' value="">{props?.lang === 'portuguese' ? 'Selecionar' : 'Select'}</option>
                        {
                            props?.project?.agents.length !== 0 ? props?.project?.agents.map((ele: string, index: number) => {
                                return (
                                    <option key={index} className='' value={ele}>{ele}</option>
                                )
                            }) :
                                <p className='w-full text-center'>{props?.lang === 'portuguese' ? 'Nenhum resultado' : 'No results'}</p>
                        }
                    </select>
                    <p className='mandatory'>{props?.lang === 'portuguese' ? 'Estado:' : 'Status:'}</p>
                    <select value={state} onChange={(e) => setState(e.target.value)} className='input'>
                        <option value="">{props?.lang === 'portuguese' ? 'Selecionar' : 'Select'}</option>
                        <option value="não começou">{props?.lang === 'portuguese' ? 'Não começou' : 'Not started'}</option>
                        <option value="em progresso">{props?.lang === 'portuguese' ? 'Em Progresso' : 'In Progress'}</option>
                        <option value="revisão">{props?.lang === 'portuguese' ? 'Revisão' : 'Review'}</option>
                        <option value="em teste">{props?.lang === 'portuguese' ? 'Em Teste' : 'In Testing'}</option>
                        <option value="terminada">{props?.lang === 'portuguese' ? 'Terminada' : 'Completed'}</option>
                    </select>
                    <p className='mandatory'>{props?.lang === 'portuguese' ? 'Pontos:' : 'Points:'}</p>
                    <select disabled={props?.edit} value={points} onChange={(e) => setPoints(parseInt(e.target.value))} className='input'>
                        <option value="">{props?.lang === 'portuguese' ? 'Selecionar' : 'Select'}</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                    <p className=''>{props?.lang === 'portuguese' ? 'Start Date:' : 'Start Date:'}</p>
                    <input className='input' type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)} placeholder={props?.lang === 'portuguese' ? 'Data de início' : 'Start date'} />
                    <p className='mandatory'>{props?.lang === 'portuguese' ? 'Deadline:' : 'Deadline:'}</p>
                    <input className='input' type='date' value={deadline} onChange={(e) => setDeadline(e.target.value)} placeholder={props?.lang === 'portuguese' ? 'Prazo' : 'Deadline'} />
                    <p className=''>{props?.lang === 'portuguese' ? 'Descrição:' : 'Description:'}</p>
                    <textarea className='input h-[14rem] resize-none my-scrollbar' value={description} onChange={(e) => setDescription(e.target.value)} placeholder={props?.lang === 'portuguese' ? 'Descrição' : 'Description'} />
                </div>
                {
                    <div className='flex justify-end w-full gap-2'>
                        {
                            props?.task ?
                                <>
                                    {!props?.edit && <button onClick={() => { deleteTask() }} className='p-1 rounded-full bg-[var(--red)]'><ImBin className='text-white' /></button>}
                                </>
                                :
                                <button onClick={() => { addTask() }} className='px-4 text-white rounded-full bg-green'>{props?.lang === 'portuguese' ? 'Adicionar' : 'Add'}</button>
                        }
                    </div>
                }
            </div>
        </div>
    );
}
