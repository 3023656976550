import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Context } from '../../contexts/Context';
import Loading from '../Loading';
import { LuFilter, LuFilterX } from 'react-icons/lu';
import ExcelView from './ExcelView';
import { exportExcel } from '../../components/export';
import DownloadIcon from '../../content/images/icons/download.png';
import { BsDatabaseFillAdd } from "react-icons/bs";

import { create, updateData } from '../../services/server';
import toast, { Toaster } from "react-hot-toast";
import { ImCheckboxChecked } from 'react-icons/im';
import { importExcel } from '../../components/import';

export default function Home() {
    const navigate = useNavigate();
    const context = useContext(Context);

    const [view, setView] = useState('list');
    const [tableData, setTableData] = useState<any>(null);

    const [allPartners, setAllPartners] = useState<any>(null);
    const [partners, setPartners] = useState<any>(null);

    const [filterOn, setFilterOn] = useState(false);

    // Filters
    const [search, setSearch] = useState('');
    const [partnerTypes, setPartnerTypes] = useState<any>(null);
    const [partnerType, setPartnerType] = useState('');
    const [viewAll, setViewAll] = useState('1');

    useEffect(() => {
        if (allPartners) {
            let result = allPartners;
            // Filter by search
            if (search) {
                result = result.filter((ele: any) =>
                    (ele?.email).toLowerCase().includes(search.toLowerCase()) ||
                    (ele?.name).toLowerCase().includes(search.toLowerCase()));
            }
            // Filter by other filters
            result = result.filter((ele: any) =>
                (viewAll === "0" ? ele?.createdBy === context?.user?.email : true) &&
                (partnerType ? ele?.partnerType === partnerType : true)
            );
            setPartners(result);
            setTableData(result);
        }
        if (context?.settings) {
            setPartnerTypes(context?.settings?.partnerTypes);
        }
    }, [search, partnerType, filterOn, allPartners, context?.settings, viewAll, context?.user?.email]);

    useEffect(() => {
        if (context?.partners)
            setAllPartners((context?.partners).filter((ele: any) => !ele?.lead));
    }, [context?.partners]);

    const header = [
        "name",
        "responsavel",
        "role",
        "email",
        "contacto",
        "partnerType",
        "active",
        "remarks",
        "link",
        "websiteLink",
        "createdBy",
    ];

    async function handleImport(e:any) {
        const file = e.target?.files[0];
        const newData:any = await importExcel(toast, file);
        if (newData !== '' && newData !== undefined && newData !== null) {
            const allEntriesValid = newData.every((item: any) => {
                return 'name' in item && 'email' in item && isValidEmail(item?.email);
            });
            if (allEntriesValid) {
                if (tableData) 
                    setTableData([...tableData, ...newData]);
                else
                    setTableData(newData);
                toast.success('Dados importados com sucesso.');
            } else {
                toast.error('Todos os dados devem ter o campo "name" preenchido e "email" válido.');
            }
        } else {
            console.error('Erro ao importar os dados.');
            toast.error('Erro ao importar os dados.');
        }
    }

    function handleExport() {
        const exportData = partners?.map((partner: any) => {
            return {
                Nome: partner?.name,
                "Responsável": partner?.responsavel,
                "Função do responsável": partner?.role,
                Email: partner?.email,
                Contacto: partner?.contacto,
                "Tipo de parceiro": partner?.partnerType,
                "Criado por": partner?.createdBy,
                Ativo: partner?.active,
                Obs: partner?.remarks,
                "Website Link": partner?.websiteLink,
                "Pasta Partilhada": partner?.link,

            };
        });
        exportExcel(exportData, 'parcerias');
    }

    function isValidEmail(email: string): boolean {
        const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailPattern.test(email);
    }

    function saveData() {
        let error = '';
        try {
            tableData.forEach((partner: any) => {
                if (error) return partner;
                if (!partner?.name || !partner?.responsavel || !partner?.contacto || !partner?.email || !partner?.role) {
                    error = 'Existem campos obrigatórios que não estão preenchidos.';
                    return partner;
                }
                if (!isValidEmail(partner?.email)) {
                    error = 'Um dos emails é inválido.';
                    return partner;
                }
            });
            if (error) {
                toast.error(error);
                return;
            }
            tableData.forEach((partner: any) => {
                if (context?.partners.find((ele: any) => ele.email === partner?.email))
                    updateData(toast, 'users', partner?.email, partner, '', () => {
                        context?.setPartners([...tableData, ...context?.partners.filter((ele: any) => ele.lead)])
                    });
                else
                    create(toast, 'users', partner?.email, { ...partner, type: 'Partner', lead: false, createdBy: context?.user?.email, creationDate: new Date().toISOString() }, '', () => {
                        context?.setPartners([...tableData, ...context?.partners.filter((ele: any) => ele.lead)])
                    });
                return partner;
            });
            toast.success('Dados guardados com sucesso');
            context?.setPartners((prev: any) => [...prev.filter((ele: any) => !ele.lead), ...tableData]);
        } catch (error) {
            toast.error('Erro ao guardar os dados.');
        }
    }

    if (!partners || !allPartners) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full min-h-screen p-8'>
            <Toaster />
            <div className='flex flex-col items-end justify-between w-full gap-4 cinco:items-center cinco:flex-row'>
                <button onClick={() => navigate("/partner/create")} className='small-button bg-[var(--primary)]'>+ Nova Parceria</button>
                <button onClick={() => navigate('checklist')} className='small-button bg-[var(--blue)]'>Ver Check List <ImCheckboxChecked /></button>
            </div>
            <div className='flex flex-col gap-4 mt-8'>
                <div className='flex items-center gap-4'>
                    <input
                        type="text"
                        placeholder='Pesquisa'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className='input'
                    />
                    <button className='p-2 border-[1px] border-[var(--black)] rounded-full cursor-pointer' onClick={() => setFilterOn(!filterOn)}>
                        {filterOn ? <LuFilterX /> : <LuFilter />}
                    </button>
                </div>
                {
                    <div className={`flex items-center gap-8 overflow-y-hidden overflow-x-auto transition-all duration-500 ease ${filterOn ? 'max-h-[5rem]' : 'max-h-0'}`}>
                        <div className="flex flex-col gap-2">
                            <p>Tipo de Parceiro</p>
                            <select value={partnerType} onChange={(e) => setPartnerType(e.target.value)} className='filter'>
                                <option value="">Selecionar</option>
                                {
                                    partnerTypes && partnerTypes.length !== 0 ? partnerTypes.map((ele: string, index: number) => {
                                        return (
                                            <option key={index} className='' value={ele}>
                                                {ele}
                                            </option>
                                        )
                                    }) :
                                        <p className='w-full text-center'>Nenhum resultado</p>
                                }
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Ver Parceiros</p>
                            <select value={viewAll} onChange={(e) => setViewAll(e.target.value)} className='filter'>
                                <option value="0">Apenas os meus</option>
                                <option value="1">Todos</option>
                            </select>
                        </div>
                    </div>
                }
            </div>
            <div className='flex flex-col items-center justify-between gap-4 my-6 seis:flex-row'>
                <div className='relative flex items-center w-[16rem] h-8 border-2 border-[var(--black)] rounded-full justify-evenly'>
                    <div className={`absolute top-0 ${view === 'list' ? 'left-0' : 'left-1/2'} h-full w-1/2 bg-[var(--stats)] rounded-full z-[-1] transition-all duration-300`}></div>
                    <p onClick={() => setView('list')} className='w-1/2 text-[.8rem] text-center cursor-pointer'>Lista</p>
                    <p onClick={() => setView('excel')} className='w-1/2 text-[.8rem] text-center cursor-pointer'>Excel</p>
                </div>
                <div className='flex flex-col gap-4 quatro:flex-row'>
                    <button className='relative small-button bg-[var(--primary)] gap-4'>
                        Importar
                        <BsDatabaseFillAdd />
                        <input
                            type="file"
                            onChange={handleImport}
                            className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
                        />
                    </button>
                    <button onClick={handleExport} className='small-button bg-[var(--primary)] gap-4'>
                        Download
                        <img src={DownloadIcon} alt="icon" className='w-6' />
                    </button>
                </div>
            </div>
            {
                view === 'list' ?
                    <div className='flex flex-col gap-10 mt-10 mil:flex-row'>
                        <div className='w-full'>
                            <h2 className='  text-[1.5rem]'>Parcerias Ativas</h2>
                            <p className='mb-8'>{partners ? `Total: ${partners?.filter((partner: any) => partner?.active).length}` : ""}</p>
                            <div className='flex flex-col gap-4'>
                                {
                                    (!partners || partners?.filter((partner: any) => partner.active)?.length === 0) ? <p>Nenhum resultado</p> :
                                        partners?.filter((partner: any) => partner.active)?.map((partner: any) => (
                                            <div
                                                key={partner?.id}
                                                className='big-button style1 bg-purple'
                                                onClick={() => (partner?.createdBy === context?.user?.email || context?.user?.type === 'Admin') ? navigate(`/partner/${partner?.id}`) : {}}
                                            >
                                                <p className='text-[1.2rem]'>{partner?.name}</p>
                                                <p className=''>{partner?.email}</p>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                        <div className='w-full'>
                            <h2 className='  text-[1.5rem]'>Parcerias Inativas</h2>
                            <p className='mb-8'>{partners ? `Total: ${partners?.filter((partner: any) => !partner?.active).length}` : ""}</p>
                            <div className='flex flex-col gap-4'>
                                {
                                    (!partners || partners?.filter((partner: any) => !partner.active)?.length === 0) ? <p>Nenhum resultado</p> :
                                        partners?.filter((partner: any) => !partner.active)?.map((partner: any) => (
                                            <div
                                                key={partner?.id}
                                                className='big-button style1 bg-gray'
                                                onClick={() => (partner?.createdBy === context?.user?.email || context?.user?.type === 'Admin') ? navigate(`/partner/${partner?.id}`) : {}}
                                            >
                                                <p className='text-[1.2rem]'>{partner?.name}</p>
                                                <p className=''>{partner?.email}</p>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <ExcelView header={header} tableData={tableData} setTableData={setTableData} data="partners" />
            }
            {view === 'excel' && <button className="small-button bg-[var(--green)]" onClick={saveData}><p className='w-full text-center'>Guardar</p></button>}
        </div>
    );
}

