import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MdEmail, MdOutlineModeEditOutline } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import { FaPersonCircleMinus } from "react-icons/fa6";
import { FaCalendarAlt } from "react-icons/fa";
import { updateData } from '../../../services/server';
import Loading from '../../Loading';
import toast, { Toaster } from "react-hot-toast";
import { Context } from '../../../contexts/Context';
import { ImCheckboxChecked } from 'react-icons/im';

export default function ClientPage() {
    const navigate = useNavigate();
    const context = useContext(Context);
    const { id = "" } = useParams();

    const [client, setClient] = useState<any>({});

    const [nextMeeting, setNextMeeting] = useState('');

    useEffect(() => {
        const client = context?.leads?.find((client: any) => client?.id === id);
        setClient(client);
    }, [context?.leads, id]);

    useEffect(() => {
        if (client?.meetings && client?.meetings.length > 0) {
            const meetings = client?.meetings;
            const next = meetings.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime())[0];
            setNextMeeting(next?.date);
        } else {
            setNextMeeting('');
        }
    }, [client?.meetings]);

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    async function changeToClient() {
        if (!window.confirm('Tem a certeza que pretende passar esta lead para cliente?')) return;
        await updateData(toast, 'users', client?.email, { active: true, lead: false }, 'Novo cliente criado!', () => {
            context?.setLeads((prev: any) => prev.filter((lead: any) => lead.id !== client?.id));
            context?.setClients((prev: any) => [...prev, client]);
        });
    }

    async function deactivate() {
        await updateData(toast, 'users', client?.email, { active: false }, 'Lead desativada com sucesso.', () => {
            const newLeads = [...context?.leads?.filter((lead: any) => lead.id !== client?.id), { ...client, active: false }]
            context?.setLeads(newLeads);
        });
    }

    async function activate() {
        await updateData(toast, 'users', client?.email, { active: true }, 'Lead ativada com sucesso.', () => {
            const newLeads = [...context?.leads?.filter((lead: any) => lead.id !== client?.id), { ...client, active: true }]
            context?.setLeads(newLeads);
        });
    }

    if (!client) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <div className='flex flex-col justify-between w-full gap-6 mb-8 seis:items-center seis:flex-row'>
                <p onClick={() => navigate(-1)} className='relative text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
                </p>
                <div className='flex flex-col gap-4 cinco:flex-row'>
                    {
                        !nextMeeting ?
                            <button className='gap-4 small-button bg-[var(--stats)] text-black' onClick={() => navigate('/schedule', { state: { user: { user: client } } })}>
                                <p className='text-[var(--black)]'>Agendar reunião</p>
                                <FaCalendarAlt className='text-[var(--black)]' />
                            </button> :
                            <button className='gap-4 small-button bg-[var(--stats)] text-black' onClick={() => nextMeeting ? toast(`Reunião agendada para o dia ${formateDate(nextMeeting)}`) : {}}>
                                <p className='text-[var(--black)]'>{nextMeeting ? formateDate(nextMeeting) : 'Nenhum meeting'}</p>
                                <FaCalendarAlt className='text-[var(--black)]' />
                            </button>
                    }
                    <div onClick={() => navigate(`/contact/${client?.email}/${client?.contacto}`)} className='gap-4 small-button bg-purple'>
                        Contactar
                        <MdEmail />
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 mb-8 oitocentos:justify-between oitocentos:items-center oitocentos:flex-row'>
                <h1 className='text-[1.5rem] font-bold'>{client?.name}</h1>
            </div>
            <div className='flex flex-col gap-4'>
                <div className='flex flex-col oitocentos:flex-row oitocentos:gap-8'>
                    <div className='flex flex-col w-full gap-4'>
                        <p>Responsável:</p>
                        <p className='input'>{client?.responsavel || 'Campo vazio'}</p>
                        <p>Posição:</p>
                        <p className='input'>{client?.role || 'Campo vazio'}</p>
                        <p>Email:</p>
                        <p onClick={() => navigate(`/contact/${client?.email}`)} className='cursor-pointer input'>{client?.email || 'Campo vazio'}</p>
                        <p>Contacto:</p>
                        <a href={`tel:${client?.contacto}`} className='input'>{client?.contacto || 'Campo vazio'}</a>
                        <p>Observações:</p>
                        <textarea disabled className='resize-none my-scrollbar input h-[14rem]' value={client?.remarks}></textarea>
                    </div>
                    <div className='flex flex-col w-full gap-4'>
                        <p>Próxima reunião:</p>
                        <p className='input'>{nextMeeting ? formateDate(nextMeeting) : 'Campo vazio'}</p>
                        <p>Canais de contacto:</p>
                        <p className='input'>{
                            client?.channels && client?.channels.length > 0 ?
                                client?.channels?.map((channel: string, index: number) => (
                                    <span key={index}>{channel}{index !== client?.channels.length - 1 && ', '}</span>
                                )) : <span>Campo vazio</span>
                        }</p>
                        <p>Estado:</p>
                        <p className='input'>{client?.state || 'Campo vazio'}</p>
                        <p>Nível de necessidade:</p>
                        <p className='input'>{client?.levelOfNeed || 'Campo vazio'}</p>
                        {
                            client?.contactado &&
                            <>
                                <p>Vezes que foi contactado:</p>
                                <p className='cursor-pointer input'>{client?.timesSpoken || 'Campo vazio'}</p>
                            </>
                        }
                        <p>Tempo de resposta:</p>
                        <p className='cursor-pointer input'>{client?.answerTime || 'Campo vazio'}</p>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 mt-8 seis:flex-row'>
                <button onClick={() => navigate(`/client/lead/${client?.id}/edit`)} className='small-button bg-[var(--primary)] gap-4'>Editar <MdOutlineModeEditOutline /></button>
                <div onClick={changeToClient} className='gap-4 small-button bg-[var(--green)]'>
                    Passar a cliente
                    <FaUserCheck />
                </div>
                {
                    client?.active ?
                        <button onClick={deactivate} className='small-button bg-[var(--red)] gap-4'>Desativar lead <FaPersonCircleMinus /></button> :
                        <>
                            {
                                client?.contactado &&
                                <button onClick={activate} className='small-button bg-[var(--green)] gap-4'>Ativar lead <FaUserCheck /></button>
                            }
                        </>
                }
                {(context?.user?.type === 'Admin' || context?.user?.type === 'Sales') && <button onClick={() => navigate(`checklist`)} className='small-button bg-[var(--blue)] gap-4'>Ver Check List <ImCheckboxChecked /></button>}
            </div>
        </div>
    );
}

