import React, { useContext, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { IoDocumentText } from 'react-icons/io5';
import { LuFilter, LuFilterX } from 'react-icons/lu';
import { Context } from '../../contexts/Context';

export default function ChoseTemplate({setChoseTemplate, setTemplate}: any) {
    const context = useContext(Context);

    const [search, setSearch] = useState('');
    const [filterOn, setFilterOn] = useState(false);
    const [type, setType] = useState('');
    const [viewAll, setViewAll] = useState('1');
    const [docs, setDocs] = useState<any[]>([]);

    const types = ['RH', 'Comercial', 'Partnership'];

    useEffect(() => {
        let result = context?.templates || [];
        // Filter by search
        if (search) {
            result = result.filter((ele: any) =>
                (ele?.name)?.toLowerCase()?.includes(search.toLowerCase()) ||
                (ele?.subject)?.toLowerCase()?.includes(search.toLowerCase()));
        }
        // Filter by other filters
        result = result.filter((ele: any) =>
            (viewAll === "0" ? ele?.createdBy === context?.user?.email : true) &&
            (type ? ele?.type === type : true)
        );
        setDocs(result);
    }, [search, type, filterOn, viewAll, context?.user?.email, context?.templates]);

    const getBackground = (type: string) => {
        switch (type) {
            case 'RH':
                return 'text-[#4986e7]';
            case 'Comercial':
                return 'text-[#16a765]';
            case 'Partnership':
                return 'text-[#f83a22]';
            default:
                return 'text-[var(--primary)]';
        }
    }

    return (
        <div className='absolute top-0 left-0 flex flex-col w-full min-h-screen gap-8 p-8 z-[2] bg-[#eeeeee]'>
            <Toaster />
            <div className="flex items-center justify-between">
                <p onClick={() => setChoseTemplate(false)} className='relative w-full text-left cursor-pointer'>
                    <span className='text-purple'>←</span> &ensp; <span>Voltar</span>
                </p>
            </div>
            <div className='flex flex-col gap-4'>
                <div className='flex items-center gap-4'>
                    <input
                        type="text"
                        placeholder='Pesquisa'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className='input'
                    />
                    <button className='p-2 border-[1px] border-[var(--black)] rounded-full cursor-pointer' onClick={() => setFilterOn(!filterOn)}>
                        {filterOn ? <LuFilterX /> : <LuFilter />}
                    </button>
                </div>
                {
                    <div className={`flex items-center gap-8 overflow-y-hidden overflow-x-auto transition-all duration-500 ease ${filterOn ? 'max-h-[5rem]' : 'max-h-0'}`}>
                        <div className="flex flex-col gap-2">
                            <p>Tipo de email</p>
                            <select value={type} onChange={(e) => setType(e.target.value)} className='filter'>
                                <option value="">Selecionar</option>
                                {
                                    types && types.length !== 0 ? types.map((ele: string, index: number) => {
                                        return (
                                            <option key={index} className='' value={ele}>
                                                {ele}
                                            </option>
                                        )
                                    }) :
                                        <p className='w-full text-center'>Nenhum resultado</p>
                                }
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Ver Templates</p>
                            <select value={viewAll} onChange={(e) => setViewAll(e.target.value)} className='filter'>
                                <option value="0">Apenas os meus</option>
                                <option value="1">Todos</option>
                            </select>
                        </div>
                    </div>
                }
            </div>
            <div className='grid gap-4 grid-cols-auto-fill'>
                {
                    docs && docs.length !== 0 ? docs.map((ele: any, index: number) => {
                        return (
                            <div key={index} onClick={() => {setTemplate(ele); setChoseTemplate(false)}} className={`${getBackground(ele?.type)} flex flex-col cursor-pointer justify-center items-center max-w-28 max-h-28 rounded-[15px] p-2 border-2 border-[transparent] hover:border-[var(--black)]`}>
                                <IoDocumentText className='text-[5rem]' />
                                <p className='max-w-full overflow-hidden text-[var(--black)] whitespace-nowrap text-ellipsis text-[.9rem]'>{ele.name}</p>
                            </div>
                        )
                    }) :
                        <p className='w-full text-center'>Nenhum resultado</p>
                }
            </div>
        </div>
    );
}

