import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../contexts/Context';

export default function ClientProfile({ user, setUser, lang }: { user: any, setUser: any, lang:string }) {
    const navigate = useNavigate();
    const context = useContext(Context);
    const [projects, setProjects] = useState<any>([]);
    const [nextMeeting, setNextMeeting] = useState<any>(null);

    useEffect(() => {
        if (context?.projects && user?.name) {
            const allProjects = context?.projects;
            if (!allProjects) return;
            const clientsProjects = allProjects.filter((ele: any) => ele?.company === user?.name);
            setProjects(clientsProjects);
        }
    }, [context?.projects, user?.name]);

    useEffect(() => {
        if (user?.meetings && user?.meetings.length > 0) {
            const today = new Date();
            const meetings = user?.meetings.filter((m: any) => new Date(m.date) >= today);
            const next = meetings.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime())[0];
            setNextMeeting(next);
        } else {
            setNextMeeting(null);
        }
    }, [user?.meetings]);

    function formateDate(date: string) {
        if (!date) return (lang === 'pt' ? 'Nenhuma reunião agendada' : 'No scheduled meeting');
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    const projectsText = lang === 'pt' ? 'Projetos' : 'Projects';
    const nextMeetingText = lang === 'pt' ? 'Próxima reunião' : 'Next meeting';
    const noActiveProjectsText = lang === 'pt' ? 'Nenhum projeto ativo' : 'No active projects';
    const noActiveServicesText = lang === 'pt' ? 'Nenhum serviço ativo' : 'No active services';

    return (
        <div className='flex flex-col w-full gap-8 pb-8 mt-8'>
            <p className='font-bold'>{projectsText}</p>
            <div className='grid w-full grid-cols-1 gap-8 oitocentos:grid-cols-2'>
                <div className='flex flex-col w-full gap-2'>
                    <p>Embaixador</p>
                    <p className='input'>{user?.embaixador || 'Vision D'}</p>
                </div>
                <div className='flex flex-col w-full gap-2'>
                    <p>{nextMeetingText}</p>
                    <a href={nextMeeting?.link} className='input'>{formateDate(nextMeeting?.date)}</a>
                </div>
            </div>
            <div className='flex flex-col w-full gap-8 oitocentos:flex-row'>
                <div className='flex flex-col w-full gap-8'>
                    <h2 className='text-[1.2rem]'>{projectsText}</h2>
                    <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                        {
                            (!projects || projects.length === 0) ? <p>{noActiveProjectsText}</p> :
                                projects.map((project: any, index: number) => (
                                    <div key={index} className='big-button style1 bg-[var(--primary)]' onClick={() => navigate(`/project/${user?.name}-${project?.name}`)}>
                                        <p>{project?.name}</p>
                                        <p>{formateDate(project?.deadline)}</p>
                                    </div>
                                ))
                        }
                    </div>
                </div>
                <div className='flex flex-col w-full gap-8'>
                    <h2 className='text-[1.2rem]'>{lang === 'pt' ? 'Serviços ativos' : 'Active Services'}</h2>
                    <div className='w-full h-[15rem] pr-4 overflow-y-auto my-scrollbar flex flex-col gap-4'>
                        {
                            (!user?.services || user?.services.length === 0) ? <p>{noActiveServicesText}</p> :
                                user?.services.map((service: any, index: number) => (
                                    <div key={index} className='px-8 py-2 bg-purple rounded-[10px] flex justify-between items-center text-white'>
                                        <p>{service}</p>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
