import React, { useEffect, useState } from 'react';
import toast, { Toaster } from "react-hot-toast";

export default function AddObjetivo({ ...props }: any) {
    const [id, setId] = useState<any>(null);
    const [newObjetivoDescription, setNewObjetivoDescription] = useState<any>(null);
    const [newObjetivoDeadline, setNewObjetivoDeadline] = useState<any>(null);
    const [newObjetivoStartDate, setNewObjetivoStartDate] = useState<any>(null);
    const [tasks, setTasks] = useState<any>(null);

    useEffect(() => {
        if (props?.objetivo && props?.objetivos) {
            setId(props?.objetivo?.id);
            setNewObjetivoDescription(props?.objetivo?.description);
            setNewObjetivoDeadline(props?.objetivo?.deadline);
            setTasks(props?.objetivo?.tasks);
        } else {
            setId(props?.objetivos?.length + 1);
            setTasks([]);
        }
    }, [props?.objetivo, props?.objetivos]);

    function addObjetivo() {
        if (!newObjetivoDeadline || !newObjetivoDescription)
            return toast.error('Preencha todos os campos.');
        const objetivo = {
            id: `objetivo${id}`,
            deadline: newObjetivoDeadline,
            description: newObjetivoDescription,
            tasks: tasks,
            startDate: newObjetivoStartDate,
        };
        if (props?.objetivos) {
            if (props?.objetivo)
                props.objetivos[props?.objetivo.objetivoIndex] = objetivo;
            else 
                props?.objetivos.push(objetivo);
        } else {
            props.objetivos = [objetivo];
        }
        props.objetivo = null;
        props?.setObjetivoForm(false);
    }


    return (
        <div className='fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50'>
            <Toaster />
            <div className='bg-white p-8 rounded-[15px] gap-4 flex flex-col'>
                <h1 className='font-bold text-center'>{props?.objetivo ? props?.objetivo.id : 'Novo Objetivo'}</h1>
                <div className='flex flex-col gap-4'>
                    <p className='mandatory'>Descrição:</p>
                    <input className='input' type='text' value={newObjetivoDescription} onChange={(e) => setNewObjetivoDescription(e.target.value)} placeholder='Descrição' />
                    <p className='mandatory'>Deadline:</p>
                    <input className='input' type='date' value={newObjetivoDeadline} onChange={(e) => setNewObjetivoDeadline(e.target.value)} placeholder='Deadline' />
                    <p>Start Date:</p>
                    <input className='input' type='date' value={newObjetivoStartDate} onChange={(e) => setNewObjetivoStartDate(e.target.value)} placeholder='Deadline' />
                    <button onClick={addObjetivo} className='bg-[var(--primary)] small-button'><p className='w-full text-center'>Adicionar</p></button>
                </div>
                <button onClick={() => { props.objetivo = null; props?.setObjetivoForm(false); }} className='bg-[var(--red)] small-button'><p className='w-full text-center'>Cancelar</p></button>
            </div>
        </div>
    );
}

